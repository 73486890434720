import {
    forwardRef,
    useRef,
    useState,
    useEffect,
    useCallback,
    useImperativeHandle
} from 'react'

import {
    useSearchParams,
    useTransition
} from '@remix-run/react'

import { AvailableApps } from '~/config/apps'
import countiesConfig from '~/config/counties.json'
import branchesConfig from '~/config/branches.json'
import categoriesConfig from '~/config/categories.json'

import sortAlphabetically from '~/utils/sortAlphabetically'
import buildIdFromString from '~/utils/buildIdFromString'

import useApp from '~/hooks/useApp'
import useScope from '~/hooks/useScope'
import useViewSize from '~/hooks/useViewSize'

import HiddenFormInput from '~/components/HiddenFormInput'

import * as Styled from './style'
import type * as Types from './types'
import type { SelectItem } from '@gotamedia/fluffy/Select'

const PLACEMENTS: SelectItem[] = countiesConfig.map(region => {
    return {
        id: buildIdFromString(region.id),
        text: region.name,
        label: region.name,
        selected: false,
        nested: region.municipalities.map(municipality => {
            return {
                id: buildIdFromString(municipality.id),
                parentId: buildIdFromString(region.id),
                text: municipality.name,
                label: municipality.name,
                selected: false
            }
        }).sort(sortAlphabetically('text'))
    }
}).sort(sortAlphabetically('text'))

const BRANCHES: SelectItem[] = branchesConfig.map(branch => {
    return {
        id: buildIdFromString(branch.name),
        text: branch.name,
        label: branch.name,
        selected: false
    }
}).sort(sortAlphabetically('text'))

const CATEGORIES: SelectItem[] = categoriesConfig.map(category => {
    return {
        id: buildIdFromString(category.id),
        text: category.name,
        label: category.name,
        selected: false,
        nested: category.children.map(subCategory => {
            return {
                id: buildIdFromString(subCategory.id),
                parentId: buildIdFromString(category.id),
                text: subCategory.name,
                label: subCategory.name,
                selected: false
            }
        }).sort(sortAlphabetically('text'))
    }
}).sort(sortAlphabetically('text'))

const getQueryFromSelectItems = (items: SelectItem[]) => {
    const query: string[] = []

    items.forEach(mainItem => {
        if (mainItem.selected && !mainItem.indeterminate) {
            query.push(mainItem.text)
        }

        if (mainItem.indeterminate && mainItem.nested?.length) {
            mainItem.nested.forEach(nestedItem => {
                if (nestedItem.selected) {
                    query.push(nestedItem.text)
                }
            })
        }
    })

    return query.join(',')
}

const getSelectItemsFromQuery = (query: string, items: SelectItem[], defaultQuery?: string) => {
    if (query?.length || defaultQuery?.length) {
        const queryItems: string[] = query?.length ? (
            query.split(',')
        ) : (
            defaultQuery?.length ? (
                defaultQuery.split(',')
            ) : (
                []
            )
        )

        const updatedItems =  items.map(mainItem => {
            const updatedMainItem = { ...mainItem }
    
            if (queryItems.includes(mainItem.text)) {
                updatedMainItem.selected = true
    
                updatedMainItem.nested = updatedMainItem?.nested?.map(nestedItem => {
                    return {
                        ...nestedItem,
                        selected: true
                    }
                })
            } else if (updatedMainItem.nested?.length) {
                updatedMainItem.nested = updatedMainItem.nested.map(nestedItem => {
                    const updatedNestedItem = { ...nestedItem }
    
                    if (queryItems.includes(nestedItem.text)) {
                        updatedNestedItem.selected = true
                        updatedMainItem.indeterminate = true
                    }
    
                    return updatedNestedItem
                })
            }
    
            return updatedMainItem
        })

        return updatedItems
    } else {
        return items
    }
}

const Search: Types.SearchComponent = forwardRef((props, ref) => {
    const {
        onSearchChange
    } = props

    const mountedRef = useRef(false)
    const submitButtonRef = useRef<HTMLButtonElement>(null)

    useImperativeHandle(ref, () => {
        return {
            submit: () => submitButtonRef.current?.click()
        }
    }, [])

    const app = useApp()
    const scope = useScope()

    const [searchParams] = useSearchParams()
    const transition = useTransition()

    const submitting = transition.state === 'submitting'

    const { isLarge } = useViewSize()

    const queryParam = searchParams.get('query') || ''
    const placementsParam = searchParams.get('placements') === null ? scope.defaultCounty : searchParams.get('placements') || ''
    const branchesParam = searchParams.get('branches') || ''
    const categoriesParam = searchParams.get('categories') || ''
    const includeDistansJobsParam = searchParams.get('includeDistansJobs') || 'true'

    const [search, setSearch] = useState(queryParam)
    const [searchQuery, setSearchQuery] = useState(queryParam)

    const [placements, setPlacements] = useState(getSelectItemsFromQuery(placementsParam, PLACEMENTS, scope.defaultCounty))
    const [placementsQuery, setPlacementsQuery] = useState(placementsParam)

    const [branches, setBranches] = useState(getSelectItemsFromQuery(branchesParam, BRANCHES))
    const [branchesQuery, setBranchesQuery] = useState(branchesParam)

    const [categories, seCategories] = useState(getSelectItemsFromQuery(categoriesParam, CATEGORIES))
    const [categoriesQuery, seCategoriesQuery] = useState(categoriesParam)

    const [includeDistansJobs, setIncludeDistansJobs] = useState(includeDistansJobsParam === 'true' ? true : false)
    const [includeDistansJobsQuery, setIncludeDistansJobsQuery] = useState(includeDistansJobsParam)

    useEffect(() => {
        setSearchQuery(queryParam)
        setSearch(queryParam)
    }, [queryParam])

    useEffect(() => {
        setPlacementsQuery(placementsParam)
        setPlacements(getSelectItemsFromQuery(placementsParam, PLACEMENTS))

        if (!mountedRef.current) {
            mountedRef.current = true
        }
    }, [placementsParam, scope.defaultCounty])

    useEffect(() => {
        setBranchesQuery(branchesParam)
        setBranches(getSelectItemsFromQuery(branchesParam, BRANCHES))
    }, [branchesParam])

    useEffect(() => {
        seCategoriesQuery(categoriesParam)
        seCategories(getSelectItemsFromQuery(categoriesParam, CATEGORIES))
    }, [categoriesParam])

    useEffect(() => {
        setIncludeDistansJobsQuery(includeDistansJobsParam)
        setIncludeDistansJobs(includeDistansJobsParam === 'true' ? true : false)
    }, [includeDistansJobsParam])

    useEffect(() => {
        setSearchQuery(search)
    }, [search])

    useEffect(() => {
        setPlacementsQuery(getQueryFromSelectItems(placements))
    }, [placements])

    useEffect(() => {
        setBranchesQuery(getQueryFromSelectItems(branches))
    }, [branches])

    useEffect(() => {
        seCategoriesQuery(getQueryFromSelectItems(categories))
    }, [categories])

    useEffect(() => {
        setIncludeDistansJobsQuery(String(includeDistansJobs))
    }, [includeDistansJobs])

    useEffect(() => {
        onSearchChange()
    }, [
        onSearchChange,
        searchQuery,
        placementsQuery,
        branchesQuery,
        categoriesQuery,
        includeDistansJobsQuery
    ])
    
    const handleOnInputValueChange = useCallback((value: string) => {
        setSearch(value)
    }, [])

    const handleOnPlacementsChange = useCallback((items: SelectItem[]) => {
        setPlacements(items)
    }, [])

    const handleOnPlacementsReset = useCallback(() => {
        setPlacements(current => {
            return current.map(mainItem => {
                return {
                    ...mainItem,
                    selected: false,
                    nested: mainItem.nested?.map(nestedItem => {
                        return {
                            ...nestedItem,
                            selected: false
                        }
                    })
                }
            })
        })
    }, [])
    
    const handleOnBranchesChange = useCallback((items: SelectItem[]) => {
        setBranches(items)
    }, [])

    const handleOnBranchesReset = useCallback(() => {
        setBranches(current => {
            return current.map(item => {
                return {
                    ...item,
                    selected: false
                }
            })
        })
    }, [])

    const handleOnCategoriesChange = useCallback((items: SelectItem[]) => {
        seCategories(items)
    }, [])

    const handleOnCategoriesReset = useCallback(() => {
        seCategories(current => {
            return current.map(mainItem => {
                return {
                    ...mainItem,
                    selected: false,
                    nested: mainItem.nested?.map(nestedItem => {
                        return {
                            ...nestedItem,
                            selected: false
                        }
                    })
                }
            })
        })
    }, [])

    const handleOnApply = useCallback(() => {
        submitButtonRef.current?.click()
    }, [])

    const handleOnDistansCheckChange = useCallback((checked: boolean) => {
        setIncludeDistansJobs(checked)
    }, [])

    const isLargeDevice = isLarge()

    return (
        <Styled.Wrapper>
            <Styled.SearchInputGroup>
                <Styled.SearchIcon />

                <Styled.SearchInput
                    placeholder={`Sök...`}
                    value={search}
                    onValueChange={handleOnInputValueChange}
                />

                <HiddenFormInput
                    name={'query'}
                    value={searchQuery}
                />
            </Styled.SearchInputGroup>

            <Styled.SelectsWrapper>
                {
                    app.id === AvailableApps.Jobs || app.id === AvailableApps.MarketGuide ? (
                        <Styled.SelectWrapper>
                            <Styled.Label>
                                {'Plats'}
                            </Styled.Label>

                            <Styled.Select
                                items={placements}
                                placeholder={'Alla platser'}
                                width={isLargeDevice ? 267 : '100%'}
                                onChange={handleOnPlacementsChange}
                                onReset={handleOnPlacementsReset}
                                onApply={handleOnApply}
                                showFilter={isLargeDevice}
                            />

                            <HiddenFormInput
                                name={'placements'}
                                value={placementsQuery || 'all'}
                            />
                        </Styled.SelectWrapper>
                    ) : (
                        null
                    )
                }

                {
                    app.id === AvailableApps.Jobs ? (
                        <Styled.SelectWrapper>
                            <Styled.Label>
                                {'Bransch'}
                            </Styled.Label>
                            
                            <Styled.Select
                                items={branches}
                                placeholder={'Alla branscher'}
                                width={isLargeDevice ? 267 : '100%'}
                                onChange={handleOnBranchesChange}
                                onReset={handleOnBranchesReset}
                                onApply={handleOnApply}
                                showFilter={isLargeDevice}
                            />

                            <HiddenFormInput
                                name={'branches'}
                                value={branchesQuery}
                            />
                        </Styled.SelectWrapper>
                    ) : (
                        null
                    )
                }

                {
                    app.id === AvailableApps.MarketGuide ? (
                        <Styled.SelectWrapper>
                            <Styled.Label>
                                {'Kategori'}
                            </Styled.Label>
                            
                            <Styled.Select
                                items={categories}
                                placeholder={'Alla kategorier'}
                                width={isLargeDevice ? 267 : '100%'}
                                onChange={handleOnCategoriesChange}
                                onReset={handleOnCategoriesReset}
                                onApply={handleOnApply}
                                showFilter={isLargeDevice}
                            />

                            <HiddenFormInput
                                name={'categories'}
                                value={categoriesQuery}
                            />
                        </Styled.SelectWrapper>
                    ) : (
                        null
                    )
                }
            </Styled.SelectsWrapper>

            {
                app.id === AvailableApps.Jobs ? (
                    <>
                        <Styled.Checkbox
                            label={'Inkludera distansjobb'}
                            checked={includeDistansJobs}
                            onValueChange={handleOnDistansCheckChange}
                        />

                        <HiddenFormInput
                            name={'includeDistansJobs'}
                            value={includeDistansJobsQuery}
                        />
                    </>
                ) : (
                    null
                )
            }

            <Styled.SearchButton ref={submitButtonRef}>
                {
                    submitting ? (
                        <Styled.Spinner />
                    ) : (
                        'Hitta annonser'
                    )
                }
            </Styled.SearchButton>
        </Styled.Wrapper>
    )
})

Search.displayName = 'Search'

export default Search