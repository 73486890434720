import { useCallback } from 'react'

import {
    Icons,
    IconSizes,
    IconVariants
} from '@gotamedia/fluffy/Icon'
import DateUtility from "@gotamedia/fluffy/date"

import * as Styled from './style'
import type * as Types from './types'
import type { SyntheticEvent } from 'react'

const ChurchEventCard: Types.ChurchEventCardComponent = (props) => {
    const {
        path,
        image,
        description,
        title,
        subTitle,
        location,
        placement,
        endTime,
        startTime,
    } = props

    const BrokenImage = "/images/Church.svg"
    const brokenImageLinks = [
        "https://cargodisplayads.com/gota_event/api/v1_1/adImage/default/120",
        "https://cargodisplayads.com/gota_event_test/api/v2/image/default/120",
        "https://cargodisplayads.com/gota_event/api/v2/image/default/120"
    ]

    const replaceImage = useCallback((error: SyntheticEvent<HTMLImageElement>) => {
        (error.target as HTMLImageElement).src = "/images/Church.svg"
    }, [])

    const getTime = (startTime: string, endTime?: string) => {
        let end = ""

        if (endTime) {
            end = DateUtility.format(new Date(endTime), "HH:mm")
        }

        return `${DateUtility.format(new Date(startTime), "HH:mm")} ${end ? `- ${end}` : ""}`
    }

    return (
        <Styled.StyledLink
            to={path}
            state={{ intirnalNavigation: true }}
        >
            <Styled.Card>
                <Styled.Card.Body>
                    <Styled.Card.Headline>
                        {title}
                    </Styled.Card.Headline>

                    {
                        subTitle ? (
                            <Styled.SubHeadline>
                                {subTitle}
                            </Styled.SubHeadline>
                        ) : (
                            null
                        )
                    }

                    <Styled.Flex>
                        <Styled.Flex>
                            <Styled.DetailContainer>
                                <Styled.DetailInfoContainer>
                                    <Styled.StyledIcon
                                        color={"#8A8A8D"}
                                        icon={Icons.Clock}
                                        size={IconSizes.Small}
                                        variant={IconVariants.Solid}
                                    />

                                    <span>
                                        {getTime(startTime, endTime)}
                                    </span>
                                </Styled.DetailInfoContainer>

                                <Styled.DetailInfoContainer>
                                    <Styled.StyledIcon
                                        color={"#8A8A8D"}
                                        icon={Icons.MapPin}
                                        size={IconSizes.Small}
                                        variant={IconVariants.Solid}
                                    />

                                    <Styled.Text>
                                        {`${placement} - ${location}`}
                                    </Styled.Text>
                                </Styled.DetailInfoContainer>

                                {
                                    endTime ? (
                                        <Styled.DetailInfoContainer>
                                            <Styled.Text>
                                                {
                                                    [
                                                        'Evenemanget pågår fram till den',
                                                        DateUtility.format(
                                                            new Date(endTime),
                                                            "do MMM",
                                                            {
                                                                locale: DateUtility.locales.sv
                                                            }
                                                        )
                                                    ].join(' ')
                                                }
                                            </Styled.Text>
                                        </Styled.DetailInfoContainer>
                                    ) : (
                                        null
                                    )
                                }

                                <Styled.DetailInfoContainer>
                                    <Styled.TextLink>
                                        {'Läs mer'}
                                    </Styled.TextLink>
                                </Styled.DetailInfoContainer>
                            </Styled.DetailContainer>
                        </Styled.Flex>

                        <Styled.ImageContainer>
                            {
                                image && !brokenImageLinks.includes(image) ? (
                                    <Styled.Card.Image
                                        onError={replaceImage}
                                        src={image}
                                        alt={description?.replace(/<[^>]*>?/gm, '')}
                                    />
                                ) : (
                                    <Styled.BrokenCardImage
                                        src={BrokenImage}
                                        alt={description?.replace(/<[^>]*>?/gm, '')}
                                    />
                                )
                            }
                        </Styled.ImageContainer>
                    </Styled.Flex>
                </Styled.Card.Body>
            </Styled.Card>
        </Styled.StyledLink>
    )
}

export default ChurchEventCard
