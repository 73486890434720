import { useMemo } from 'react'

import { AppSubType } from '~/services/resolvers/types'

import getHeaderBanner from '~/utils/getHeaderBanner'

import useScope from '~/hooks/useScope'
import useViewSize from '~/hooks/useViewSize'
import useRouteData from '~/hooks/useRouteData'
import useHasMatch from '~/hooks/useHasMatch'

import type * as Types from './types'
import type { RootLoaderData } from '~/types'

const Banner: Types.Banner = (props) => {
    const {
        width,
        height,
        className
    } = props

    const scope = useScope()
    const { isSmall } = useViewSize()
    const { app } = useRouteData<RootLoaderData>('root')

    const isMatchingFiraRoute = useHasMatch('routes/fira/index')
    const isMatchingMinnasRoute = useHasMatch('routes/minnas/index')

    const fillColor = scope.brandColor || '#2e2a25'

    const size = isSmall() ? 'small' : 'large'

    const familyType = isMatchingMinnasRoute ? (
        AppSubType.Minnas
    ) : (
        isMatchingFiraRoute ? (
            AppSubType.Fira
        ) : (
            AppSubType.Unknown
        )
    )

    const BannerSvg = useMemo(() => {
        return getHeaderBanner(app.id, size, familyType)
    }, [app.id, familyType, size])

    if (BannerSvg) {
        return (
            <BannerSvg
                width={width}
                height={height}
                fillColor={fillColor}
                className={className}
            />
        )
    } else {
        return (
            null
        )
    }
}

export default Banner