import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    min-height: 44px;
    align-items: center;
`

const InnerWrapper = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: auto;
    display: flex;
    position: relative;
    align-items: center;
`

const Anchor = styled.a`
    font-family: 'Sanomat Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    margin-left: 2px;
    cursor: pointer;
`

const Headline = styled.span`
    font-family: 'Sanomat Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`

const SubHeadline = styled.span`
    font-family: 'Sanomat Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`

const Image = styled.img`
    max-width:209px;
    margin-top: 10px;
    margin-bottom: 10px;
`

export {
    Image,
    SubHeadline,
    Headline,
    Anchor,
    InnerWrapper,
    Wrapper
}
