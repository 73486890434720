import Icon from "@gotamedia/fluffy/Icon"
import styled, { css } from "styled-components"
import Image from "@gotamedia/fluffy/Image"
import Card from "@gotamedia/fluffy/Card"
import themeHelpers from "@gotamedia/fluffy/utils/theme/helpers"

const CardsSection = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
    background: #F5F5F5;
    padding: 16px;    
    ${themeHelpers.isLargeDevice(css`
        padding: 40px;
    `)}
    ${themeHelpers.isSmallDevice(css`
        padding: 8px;
    `)}
`

const CardsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 48px;
    flex: 1 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1489px;
    width: 100%;
    max-width: 1600px;
    flex-direction: row;
    @media screen and (max-width: 1300px) {
        gap: 16px;
    }
    ${themeHelpers.isMediumDevice(css`
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        @media screen and (orientation: landscape) {
            flex-direction: row;
            flex-wrap: nowrap;
           gap: 16px;
        }
    `)}
    ${themeHelpers.isSmallDevice(css`
        flex-direction: column;
        gap: 16px;
    `)}
`

const AdsCard = styled(Card)`
    display: flex;
    flex-wrap: wrap;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 auto;
    align-self: stretch;
    width: 30%;
    border-radius: 8px;
    box-shadow: 0px 3px 7px 0px rgba(46, 42, 37, 0.12);
    ${themeHelpers.isMediumDevice(css`
        overflow: visible;
        padding: 16px;
        gap: 16px;
        width: 100%;
        @media screen and (orientation: landscape) {
            width: 30%;
            gap: 16px;
         }
    `)}
    ${themeHelpers.isSmallDevice(css`
        overflow: visible;
        padding: 16px;
        gap: 16px;
        width: 100%;

    `)}
`
const TextWrapper = styled.div`
         display: flex;
         flex-direction: column;
         gap: 16px;
         min-height: auto;
    ${themeHelpers.isMediumDevice(css`
        @media screen and (orientation: landscape) {
            min-height: 175px;
         }
    `)}
`

const CardHeadline = styled.h2`
    font-family: Sanomat Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`
const CardText = styled.p`
    font-family: Sanomat Sans;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 90%;
    ${themeHelpers.isLargeDevice(css`
        min-height: 75px;
    `)}
    ${themeHelpers.isMediumDevice(css`
        max-width: 80%;
        @media screen and (orientation:landscape) {
            max-width: 100%;
        }
    `)}
`
const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    @media screen and (max-width: 1350px) {
        flex-direction: column;
        align-items: flex-start;
    }
    ${themeHelpers.isMediumDevice(css`
        flex-direction: row;
        @media screen and (orientation:landscape) {
            flex-direction: column;
        }
    `)}
    ${themeHelpers.isSmallDevice(css`
        flex-direction: column;
        align-items: flex-start;
        @media screen and (orientation:landscape) {
            flex-direction: row;
        }
    `)}
`
const CardButton = styled.a`
    display: flex;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    border: 1px solid #55565A;
    background: #FFF;
    color: black;
    white-space: nowrap; 
    min-width: auto;
    &:hover {
        background: rgba(0, 0, 0, 0.08);        
    }
`

const ButtonIcon = styled(Icon)`
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
`

const LinkIconWrapper = styled.span`
    display: flex;
    align-items: center;
    gap: 26px;
    width: 100%;
    margin-top: auto;
    @media screen and (max-width: 1300px) and (orientation: landscape) {
        justify-content: flex-end;
    }
    ${themeHelpers.isMediumDevice(css`
        @media screen and (orientation: landscape) {
            justify-content: flex-end;
        }
    `)}
`

const CardLink = styled.a`
    font-family: Sanomat Sans Text;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.link};
    &:hover {
        text-decoration: none;
    }
    ${themeHelpers.isSmallDevice(css`
        width: 263px;
`)}
`
const CardIcon = styled(Image)`
    margin-left: auto;
    height: 64px;
    width: 64px;
`

export {
    CardsSection,
    CardsWrapper,
    AdsCard,
    TextWrapper,
    CardHeadline,
    CardText,
    ButtonWrapper,
    CardButton,
    ButtonIcon,
    LinkIconWrapper,
    CardLink,
    CardIcon
}