import { AvailableApps } from '~/config/apps'

import throwAppError from '~/utils/throwAppError'

import useRouteData from '~/hooks/useRouteData'

import ChurchEventStartPage from '~/components/ChurchEvent/pages/ChurchEventStartPage'
import FamilyStartPage from '~/components/Family/pages/FamilyStartPage'
import JobsStartPage from '~/components/Jobs/pages/JobsStartPage'
import MarketGuideStartPage from '~/components/MarketGuide/pages/MarketGuideStartPage'

import AdPortalStartPage from '~/components/AdPortal/pages'
import type { RootLoaderData } from '~/types'
import type * as Types from './types'

const StartPage: Types.StartPageComponent = () => {
    const { app } = useRouteData<RootLoaderData>('root')

    if (!app || !app.id) {
        throwAppError()
    }
    
    switch(app.id) {
        case AvailableApps.MarketGuide:
            return (
                <MarketGuideStartPage />
            )

        case AvailableApps.Jobs:
            return (
                <JobsStartPage />
            )

        case AvailableApps.Family:
            return (
                <FamilyStartPage />
            )

        case AvailableApps.ChurchEvent:
            return (
                <ChurchEventStartPage />
            )
        case AvailableApps.AdPortal:
            return (
                <AdPortalStartPage />
            )

        default:
            throwAppError()
            
            return (
                null
            )
    }
}

export default StartPage