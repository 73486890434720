const _sort = (a: string, b: string) => {
    if (a > b) {
        return 1
    } else {
        return -1
    }
}

const sortAlphabetically = <T = string>(target?: string) => {
    return (a: T, b: T) => {
        if (target) {
            return _sort((a as unknown as Record<string, string>)[target], (b as unknown as Record<string, string>)[target])
        } else {
            return _sort(a as unknown as string, b as unknown as string)
        }
    }
}

export default sortAlphabetically