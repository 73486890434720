import { AvailableApps } from '~/config/apps'

import useApp from '~/hooks/useApp'
import useScope from '~/hooks/useScope'

import getNoResultLogo from '~/utils/getNoResultLogo'

import * as Styled from './style'
import type * as Types from './types'

const NoResults: Types.NoResultsComponent = () => {
    const {
        id: appId,
    } = useApp()

    const scope = useScope()

    const getLink = () => {
        switch (appId) {
            case AvailableApps.MarketGuide:
                return `https://medieportalen.${scope.id}.se/`

            case AvailableApps.Jobs:
                return `https://medieportalen.${scope.id}.se/rekrytering/`

            case AvailableApps.Family:
                return `https://www.${scope.id}.se/privatannons`

            default:
                return 'UNKNOWN'
        }
    }

    return (
        <Styled.Wrapper>
            <Styled.Image
                src={getNoResultLogo(appId)}
                alt={'Inga resultat'}
            />

            <Styled.Headline>
                {'Inga annonser att visa.'}
            </Styled.Headline>

            <Styled.SubHeadline>
                {'Vill du att din annons ska synas här? '}

                <Styled.Anchor href={getLink()}>
                    {'Boka annons'}
                </Styled.Anchor>
            </Styled.SubHeadline>
        </Styled.Wrapper>
    )
}

export default NoResults