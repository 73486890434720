import {
    json,
    redirect
} from '@remix-run/node'

import getApp from '~/utils/getApp'
import getScope from '~/utils/scope'
import serverErrorHandler from '~/utils/serverErrorHandler'

import searchResolver from '~/services/resolvers/search'

import { AvailableApps } from '~/config/apps'

import type { LoaderFunction } from '@remix-run/node'

export const loader: LoaderFunction = async (props) => {
    const { request } = props

    const app = getApp(request.url)

    if (app instanceof Response) {
        return app
    }

    if (app.id === AvailableApps.Family) {
        return redirect(`/fira`)
    }

    if (app.id === AvailableApps.AdPortal) {
        return json({})
    }

    return searchHandler(props)
}

export const searchHandler: LoaderFunction = async ({ request, params }) => {
    const app = getApp(request.url)

    if (app instanceof Response) {
        return app
    }

    const scope = getScope(app, request.url)

    if (scope instanceof Response) {
        return scope
    }

    try {
        const results = await searchResolver({
            app: app,
            scope: scope,
            url: request.url
        })

        return json({
            ...params,
            ...results
        })
    } catch (result: any) {
        await serverErrorHandler(request, result, scope.id)

        return json({
            ...params,
            payload: {}
        })
    }
}

export { default } from '~/pages/StartPage'