[
    {
        "id": "01",
        "name": "Stockholms län",
        "municipalities": [
            {
                "id": "0114",
                "name": "Upplands Väsby"
            },
            {
                "id": "0115",
                "name": "Vallentuna"
            },
            {
                "id": "0117",
                "name": "Österåker"
            },
            {
                "id": "0120",
                "name": "Värmdö"
            },
            {
                "id": "0123",
                "name": "Järfälla"
            },
            {
                "id": "0125",
                "name": "Ekerö"
            },
            {
                "id": "0126",
                "name": "Huddinge"
            },
            {
                "id": "0127",
                "name": "Botkyrka"
            },
            {
                "id": "0128",
                "name": "Salem"
            },
            {
                "id": "0136",
                "name": "Haninge"
            },
            {
                "id": "0138",
                "name": "Tyresö"
            },
            {
                "id": "0139",
                "name": "Upplands-Bro"
            },
            {
                "id": "0140",
                "name": "Nykvarn"
            },
            {
                "id": "0160",
                "name": "Täby"
            },
            {
                "id": "0162",
                "name": "Danderyd"
            },
            {
                "id": "0163",
                "name": "Sollentuna"
            },
            {
                "id": "0180",
                "name": "Stockholm"
            },
            {
                "id": "0181",
                "name": "Södertälje"
            },
            {
                "id": "0182",
                "name": "Nacka"
            },
            {
                "id": "0183",
                "name": "Sundbyberg"
            },
            {
                "id": "0184",
                "name": "Solna"
            },
            {
                "id": "0186",
                "name": "Lidingö"
            },
            {
                "id": "0187",
                "name": "Vaxholm"
            },
            {
                "id": "0188",
                "name": "Norrtälje"
            },
            {
                "id": "0191",
                "name": "Sigtuna"
            },
            {
                "id": "0192",
                "name": "Nynäshamn"
            }
        ]
    },
    {
        "id": "03",
        "name": "Uppsala län",
        "municipalities": [
            {
                "id": "0305",
                "name": "Håbo"
            },
            {
                "id": "0319",
                "name": "Älvkarleby"
            },
            {
                "id": "0330",
                "name": "Knivsta"
            },
            {
                "id": "0331",
                "name": "Heby"
            },
            {
                "id": "0360",
                "name": "Tierp"
            },
            {
                "id": "0380",
                "name": "Uppsala"
            },
            {
                "id": "0381",
                "name": "Enköping"
            },
            {
                "id": "0382",
                "name": "Östhammar"
            }
        ]
    },
    {
        "id": "04",
        "name": "Södermanlands län",
        "municipalities": [
            {
                "id": "0428",
                "name": "Vingåker"
            },
            {
                "id": "0461",
                "name": "Gnesta"
            },
            {
                "id": "0480",
                "name": "Nyköping"
            },
            {
                "id": "0481",
                "name": "Oxelösund"
            },
            {
                "id": "0482",
                "name": "Flen"
            },
            {
                "id": "0483",
                "name": "Katrineholm"
            },
            {
                "id": "0484",
                "name": "Eskilstuna"
            },
            {
                "id": "0486",
                "name": "Strängnäs"
            },
            {
                "id": "0488",
                "name": "Trosa"
            }
        ]
    },
    {
        "id": "05",
        "name": "Östergötlands län",
        "municipalities": [
            {
                "id": "0509",
                "name": "Ödeshög"
            },
            {
                "id": "0512",
                "name": "Ydre"
            },
            {
                "id": "0513",
                "name": "Kinda"
            },
            {
                "id": "0560",
                "name": "Boxholm"
            },
            {
                "id": "0561",
                "name": "Åtvidaberg"
            },
            {
                "id": "0562",
                "name": "Finspång"
            },
            {
                "id": "0563",
                "name": "Valdemarsvik"
            },
            {
                "id": "0580",
                "name": "Linköping"
            },
            {
                "id": "0581",
                "name": "Norrköping"
            },
            {
                "id": "0582",
                "name": "Söderköping"
            },
            {
                "id": "0583",
                "name": "Motala"
            },
            {
                "id": "0584",
                "name": "Vadstena"
            },
            {
                "id": "0586",
                "name": "Mjölby"
            }
        ]
    },
    {
        "id": "06",
        "name": "Jönköpings län",
        "municipalities": [
            {
                "id": "0604",
                "name": "Aneby"
            },
            {
                "id": "0617",
                "name": "Gnosjö"
            },
            {
                "id": "0642",
                "name": "Mullsjö"
            },
            {
                "id": "0643",
                "name": "Habo"
            },
            {
                "id": "0662",
                "name": "Gislaved"
            },
            {
                "id": "0665",
                "name": "Vaggeryd"
            },
            {
                "id": "0680",
                "name": "Jönköping"
            },
            {
                "id": "0682",
                "name": "Nässjö"
            },
            {
                "id": "0683",
                "name": "Värnamo"
            },
            {
                "id": "0684",
                "name": "Sävsjö"
            },
            {
                "id": "0685",
                "name": "Vetlanda"
            },
            {
                "id": "0686",
                "name": "Eksjö"
            },
            {
                "id": "0687",
                "name": "Tranås"
            }
        ]
    },
    {
        "id": "07",
        "name": "Kronobergs län",
        "municipalities": [
            {
                "id": "0760",
                "name": "Uppvidinge"
            },
            {
                "id": "0761",
                "name": "Lessebo"
            },
            {
                "id": "0763",
                "name": "Tingsryd"
            },
            {
                "id": "0764",
                "name": "Alvesta"
            },
            {
                "id": "0765",
                "name": "Älmhult"
            },
            {
                "id": "0767",
                "name": "Markaryd"
            },
            {
                "id": "0780",
                "name": "Växjö"
            },
            {
                "id": "0781",
                "name": "Ljungby"
            }
        ]
    },
    {
        "id": "08",
        "name": "Kalmar län",
        "municipalities": [
            {
                "id": "0821",
                "name": "Högsby"
            },
            {
                "id": "0834",
                "name": "Torsås"
            },
            {
                "id": "0840",
                "name": "Mörbylånga"
            },
            {
                "id": "0860",
                "name": "Hultsfred"
            },
            {
                "id": "0861",
                "name": "Mönsterås"
            },
            {
                "id": "0862",
                "name": "Emmaboda"
            },
            {
                "id": "0880",
                "name": "Kalmar"
            },
            {
                "id": "0881",
                "name": "Nybro"
            },
            {
                "id": "0882",
                "name": "Oskarshamn"
            },
            {
                "id": "0883",
                "name": "Västervik"
            },
            {
                "id": "0884",
                "name": "Vimmerby"
            },
            {
                "id": "0885",
                "name": "Borgholm"
            }
        ]
    },
    {
        "id": "09",
        "name": "Gotlands län",
        "municipalities": [
            {
                "id": "0980",
                "name": "Gotland"
            }
        ]
    },
    {
        "id": "10",
        "name": "Blekinge län",
        "municipalities": [
            {
                "id": "1060",
                "name": "Olofström"
            },
            {
                "id": "1080",
                "name": "Karlskrona"
            },
            {
                "id": "1081",
                "name": "Ronneby"
            },
            {
                "id": "1082",
                "name": "Karlshamn"
            },
            {
                "id": "1083",
                "name": "Sölvesborg"
            }
        ]
    },
    {
        "id": "12",
        "name": "Skåne län",
        "municipalities": [
            {
                "id": "1214",
                "name": "Svalöv"
            },
            {
                "id": "1230",
                "name": "Staffanstorp"
            },
            {
                "id": "1231",
                "name": "Burlöv"
            },
            {
                "id": "1233",
                "name": "Vellinge"
            },
            {
                "id": "1256",
                "name": "Östra Göinge"
            },
            {
                "id": "1257",
                "name": "Örkelljunga"
            },
            {
                "id": "1260",
                "name": "Bjuv"
            },
            {
                "id": "1261",
                "name": "Kävlinge"
            },
            {
                "id": "1262",
                "name": "Lomma"
            },
            {
                "id": "1263",
                "name": "Svedala"
            },
            {
                "id": "1264",
                "name": "Skurup"
            },
            {
                "id": "1265",
                "name": "Sjöbo"
            },
            {
                "id": "1266",
                "name": "Hörby"
            },
            {
                "id": "1267",
                "name": "Höör"
            },
            {
                "id": "1270",
                "name": "Tomelilla"
            },
            {
                "id": "1272",
                "name": "Bromölla"
            },
            {
                "id": "1273",
                "name": "Osby"
            },
            {
                "id": "1275",
                "name": "Perstorp"
            },
            {
                "id": "1276",
                "name": "Klippan"
            },
            {
                "id": "1277",
                "name": "Åstorp"
            },
            {
                "id": "1278",
                "name": "Båstad"
            },
            {
                "id": "1280",
                "name": "Malmö"
            },
            {
                "id": "1281",
                "name": "Lund"
            },
            {
                "id": "1282",
                "name": "Landskrona"
            },
            {
                "id": "1283",
                "name": "Helsingborg"
            },
            {
                "id": "1284",
                "name": "Höganäs"
            },
            {
                "id": "1285",
                "name": "Eslöv"
            },
            {
                "id": "1286",
                "name": "Ystad"
            },
            {
                "id": "1287",
                "name": "Trelleborg"
            },
            {
                "id": "1290",
                "name": "Kristianstad"
            },
            {
                "id": "1291",
                "name": "Simrishamn"
            },
            {
                "id": "1292",
                "name": "Ängelholm"
            },
            {
                "id": "1293",
                "name": "Hässleholm"
            }
        ]
    },
    {
        "id": "13",
        "name": "Hallands län",
        "municipalities": [
            {
                "id": "1315",
                "name": "Hylte"
            },
            {
                "id": "1380",
                "name": "Halmstad"
            },
            {
                "id": "1381",
                "name": "Laholm"
            },
            {
                "id": "1382",
                "name": "Falkenberg"
            },
            {
                "id": "1383",
                "name": "Varberg"
            },
            {
                "id": "1384",
                "name": "Kungsbacka"
            }
        ]
    },
    {
        "id": "14",
        "name": "Västra Götalands län",
        "municipalities": [
            {
                "id": "1401",
                "name": "Härryda"
            },
            {
                "id": "1402",
                "name": "Partille"
            },
            {
                "id": "1407",
                "name": "Öckerö"
            },
            {
                "id": "1415",
                "name": "Stenungsund"
            },
            {
                "id": "1419",
                "name": "Tjörn"
            },
            {
                "id": "1421",
                "name": "Orust"
            },
            {
                "id": "1427",
                "name": "Sotenäs"
            },
            {
                "id": "1430",
                "name": "Munkedal"
            },
            {
                "id": "1435",
                "name": "Tanum"
            },
            {
                "id": "1438",
                "name": "Dals-Ed"
            },
            {
                "id": "1439",
                "name": "Färgelanda"
            },
            {
                "id": "1440",
                "name": "Ale"
            },
            {
                "id": "1441",
                "name": "Lerum"
            },
            {
                "id": "1442",
                "name": "Vårgårda"
            },
            {
                "id": "1443",
                "name": "Bollebygd"
            },
            {
                "id": "1444",
                "name": "Grästorp"
            },
            {
                "id": "1445",
                "name": "Essunga"
            },
            {
                "id": "1446",
                "name": "Karlsborg"
            },
            {
                "id": "1447",
                "name": "Gullspång"
            },
            {
                "id": "1452",
                "name": "Tranemo"
            },
            {
                "id": "1460",
                "name": "Bengtsfors"
            },
            {
                "id": "1461",
                "name": "Mellerud"
            },
            {
                "id": "1462",
                "name": "Lilla Edet"
            },
            {
                "id": "1463",
                "name": "Mark"
            },
            {
                "id": "1465",
                "name": "Svenljunga"
            },
            {
                "id": "1466",
                "name": "Herrljunga"
            },
            {
                "id": "1470",
                "name": "Vara"
            },
            {
                "id": "1471",
                "name": "Götene"
            },
            {
                "id": "1472",
                "name": "Tibro"
            },
            {
                "id": "1473",
                "name": "Töreboda"
            },
            {
                "id": "1480",
                "name": "Göteborg"
            },
            {
                "id": "1481",
                "name": "Mölndal"
            },
            {
                "id": "1482",
                "name": "Kungälv"
            },
            {
                "id": "1484",
                "name": "Lysekil"
            },
            {
                "id": "1485",
                "name": "Uddevalla"
            },
            {
                "id": "1486",
                "name": "Strömstad"
            },
            {
                "id": "1487",
                "name": "Vänersborg"
            },
            {
                "id": "1488",
                "name": "Trollhättan"
            },
            {
                "id": "1489",
                "name": "Alingsås"
            },
            {
                "id": "1490",
                "name": "Borås"
            },
            {
                "id": "1491",
                "name": "Ulricehamn"
            },
            {
                "id": "1492",
                "name": "Åmål"
            },
            {
                "id": "1493",
                "name": "Mariestad"
            },
            {
                "id": "1494",
                "name": "Lidköping"
            },
            {
                "id": "1495",
                "name": "Skara"
            },
            {
                "id": "1496",
                "name": "Skövde"
            },
            {
                "id": "1497",
                "name": "Hjo"
            },
            {
                "id": "1498",
                "name": "Tidaholm"
            },
            {
                "id": "1499",
                "name": "Falköping"
            }
        ]
    },
    {
        "id": "17",
        "name": "Värmlands län",
        "municipalities": [
            {
                "id": "1715",
                "name": "Kil"
            },
            {
                "id": "1730",
                "name": "Eda"
            },
            {
                "id": "1737",
                "name": "Torsby"
            },
            {
                "id": "1760",
                "name": "Storfors"
            },
            {
                "id": "1761",
                "name": "Hammarö"
            },
            {
                "id": "1762",
                "name": "Munkfors"
            },
            {
                "id": "1763",
                "name": "Forshaga"
            },
            {
                "id": "1764",
                "name": "Grums"
            },
            {
                "id": "1765",
                "name": "Årjäng"
            },
            {
                "id": "1766",
                "name": "Sunne"
            },
            {
                "id": "1780",
                "name": "Karlstad"
            },
            {
                "id": "1781",
                "name": "Kristinehamn"
            },
            {
                "id": "1782",
                "name": "Filipstad"
            },
            {
                "id": "1783",
                "name": "Hagfors"
            },
            {
                "id": "1784",
                "name": "Arvika"
            },
            {
                "id": "1785",
                "name": "Säffle"
            }
        ]
    },
    {
        "id": "18",
        "name": "Örebro län",
        "municipalities": [
            {
                "id": "1814",
                "name": "Lekeberg"
            },
            {
                "id": "1860",
                "name": "Laxå"
            },
            {
                "id": "1861",
                "name": "Hallsberg"
            },
            {
                "id": "1862",
                "name": "Degerfors"
            },
            {
                "id": "1863",
                "name": "Hällefors"
            },
            {
                "id": "1864",
                "name": "Ljusnarsberg"
            },
            {
                "id": "1880",
                "name": "Örebro"
            },
            {
                "id": "1881",
                "name": "Kumla"
            },
            {
                "id": "1882",
                "name": "Askersund"
            },
            {
                "id": "1883",
                "name": "Karlskoga"
            },
            {
                "id": "1884",
                "name": "Nora"
            },
            {
                "id": "1885",
                "name": "Lindesberg"
            }
        ]
    },
    {
        "id": "19",
        "name": "Västmanlands län",
        "municipalities": [
            {
                "id": "1904",
                "name": "Skinnskatteberg"
            },
            {
                "id": "1907",
                "name": "Surahammar"
            },
            {
                "id": "1960",
                "name": "Kungsör"
            },
            {
                "id": "1961",
                "name": "Hallstahammar"
            },
            {
                "id": "1962",
                "name": "Norberg"
            },
            {
                "id": "1980",
                "name": "Västerås"
            },
            {
                "id": "1981",
                "name": "Sala"
            },
            {
                "id": "1982",
                "name": "Fagersta"
            },
            {
                "id": "1983",
                "name": "Köping"
            },
            {
                "id": "1984",
                "name": "Arboga"
            }
        ]
    },
    {
        "id": "20",
        "name": "Dalarnas län",
        "municipalities": [
            {
                "id": "2021",
                "name": "Vansbro"
            },
            {
                "id": "2023",
                "name": "Malung-Sälen"
            },
            {
                "id": "2026",
                "name": "Gagnef"
            },
            {
                "id": "2029",
                "name": "Leksand"
            },
            {
                "id": "2031",
                "name": "Rättvik"
            },
            {
                "id": "2034",
                "name": "Orsa"
            },
            {
                "id": "2039",
                "name": "Älvdalen"
            },
            {
                "id": "2061",
                "name": "Smedjebacken"
            },
            {
                "id": "2062",
                "name": "Mora"
            },
            {
                "id": "2080",
                "name": "Falun"
            },
            {
                "id": "2081",
                "name": "Borlänge"
            },
            {
                "id": "2082",
                "name": "Säter"
            },
            {
                "id": "2083",
                "name": "Hedemora"
            },
            {
                "id": "2084",
                "name": "Avesta"
            },
            {
                "id": "2085",
                "name": "Ludvika"
            }
        ]
    },
    {
        "id": "21",
        "name": "Gävleborgs län",
        "municipalities": [
            {
                "id": "2101",
                "name": "Ockelbo"
            },
            {
                "id": "2104",
                "name": "Hofors"
            },
            {
                "id": "2121",
                "name": "Ovanåker"
            },
            {
                "id": "2132",
                "name": "Nordanstig"
            },
            {
                "id": "2161",
                "name": "Ljusdal"
            },
            {
                "id": "2180",
                "name": "Gävle"
            },
            {
                "id": "2181",
                "name": "Sandviken"
            },
            {
                "id": "2182",
                "name": "Söderhamn"
            },
            {
                "id": "2183",
                "name": "Bollnäs"
            },
            {
                "id": "2184",
                "name": "Hudiksvall"
            }
        ]
    },
    {
        "id": "22",
        "name": "Västernorrlands län",
        "municipalities": [
            {
                "id": "2260",
                "name": "Ånge"
            },
            {
                "id": "2262",
                "name": "Timrå"
            },
            {
                "id": "2280",
                "name": "Härnösand"
            },
            {
                "id": "2281",
                "name": "Sundsvall"
            },
            {
                "id": "2282",
                "name": "Kramfors"
            },
            {
                "id": "2283",
                "name": "Sollefteå"
            },
            {
                "id": "2284",
                "name": "Örnsköldsvik"
            }
        ]
    },
    {
        "id": "23",
        "name": "Jämtlands län",
        "municipalities": [
            {
                "id": "2303",
                "name": "Ragunda"
            },
            {
                "id": "2305",
                "name": "Bräcke"
            },
            {
                "id": "2309",
                "name": "Krokom"
            },
            {
                "id": "2313",
                "name": "Strömsund"
            },
            {
                "id": "2321",
                "name": "Åre"
            },
            {
                "id": "2326",
                "name": "Berg"
            },
            {
                "id": "2361",
                "name": "Härjedalen"
            },
            {
                "id": "2380",
                "name": "Östersund"
            }
        ]
    },
    {
        "id": "24",
        "name": "Västerbottens län",
        "municipalities": [
            {
                "id": "2401",
                "name": "Nordmaling"
            },
            {
                "id": "2403",
                "name": "Bjurholm"
            },
            {
                "id": "2404",
                "name": "Vindeln"
            },
            {
                "id": "2409",
                "name": "Robertsfors"
            },
            {
                "id": "2417",
                "name": "Norsjö"
            },
            {
                "id": "2418",
                "name": "Malå"
            },
            {
                "id": "2421",
                "name": "Storuman"
            },
            {
                "id": "2422",
                "name": "Sorsele"
            },
            {
                "id": "2425",
                "name": "Dorotea"
            },
            {
                "id": "2460",
                "name": "Vännäs"
            },
            {
                "id": "2462",
                "name": "Vilhelmina"
            },
            {
                "id": "2463",
                "name": "Åsele"
            },
            {
                "id": "2480",
                "name": "Umeå"
            },
            {
                "id": "2481",
                "name": "Lycksele"
            },
            {
                "id": "2482",
                "name": "Skellefteå"
            }
        ]
    },
    {
        "id": "25",
        "name": "Norrbottens län",
        "municipalities": [
            {
                "id": "2505",
                "name": "Arvidsjaur"
            },
            {
                "id": "2506",
                "name": "Arjeplog"
            },
            {
                "id": "2510",
                "name": "Jokkmokk"
            },
            {
                "id": "2513",
                "name": "Överkalix"
            },
            {
                "id": "2514",
                "name": "Kalix"
            },
            {
                "id": "2518",
                "name": "Övertorneå"
            },
            {
                "id": "2521",
                "name": "Pajala"
            },
            {
                "id": "2523",
                "name": "Gällivare"
            },
            {
                "id": "2560",
                "name": "Älvsbyn"
            },
            {
                "id": "2580",
                "name": "Luleå"
            },
            {
                "id": "2581",
                "name": "Piteå"
            },
            {
                "id": "2582",
                "name": "Boden"
            },
            {
                "id": "2583",
                "name": "Haparanda"
            },
            {
                "id": "2584",
                "name": "Kiruna"
            }
        ]
    }
]