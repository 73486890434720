import {
    useRef,
    useState,
    useMemo,
    Fragment,
    useCallback
} from 'react'

import { useLoaderData } from '@remix-run/react'

import {
    Icons,
    IconSizes,
    IconVariants
} from '@gotamedia/fluffy/Icon'
import DateUtility from "@gotamedia/fluffy/date"
import useMounted from "@gotamedia/fluffy/useMounted"

import ChurchEventCard from '../ChurchEventCard'
import * as Styled from './style'
import type * as Types from './types'
import type {
    ChurchEventAdItem,
    ChurchEventAdsPayload
} from '~/modules/churchevent/types'

type GroupedChurchEvent = {
    [key: string]: ChurchEventAdItem[]
}

const ChurchEventView: Types.ChurchEventViewComponent = () => {
    const isMounted = useMounted()

    const { payload } = useLoaderData<Types.SearchResponse>()

    const [items] = useState<ChurchEventAdsPayload['data_list']>(payload.data_list || [])

    const today = useRef(new Date())

    const filterItems = useMemo(() => {
        return items.filter((item) => {
            return (
                (
                    DateUtility.isAfter(new Date(item.event_start_time), today.current) &&
                    DateUtility.isBefore(today.current, new Date(item.event_end_time))
                ) || (
                    !item.event_end_time &&
                    DateUtility.isBefore(today.current, new Date(item.event_start_time))
                )
            )
        })
    }, [items])

    const sortedItems = useMemo(() => {
        return filterItems.sort((p1, p2) => {
            return (
                new Date(p1.event_start_time) > new Date(p2.event_start_time) ? (
                    1
                ) : (
                    new Date(p1.event_start_time) < new Date(p2.event_start_time) ? (
                        -1
                    ) : (
                        0
                    )
                )
            )
        })
    }, [filterItems])

    const groupByDate = useCallback((items: ChurchEventAdItem[]) => {
        const group = items.reduce((prev: GroupedChurchEvent, curr) => {
            const eventStartDate = DateUtility.format(new Date(curr.event_start_time), 'P')

            if (eventStartDate) {
                const previousValue = prev?.[eventStartDate] || []
                return {
                    ...prev,
                    [eventStartDate]: [...previousValue, curr]
                }
            }
            return prev
        }, {} as GroupedChurchEvent)

        return group
    }, [])

    return (
        <Styled.Wrapper>
            <Styled.InfoContainer>
                <Styled.Container>
                    <Styled.Title>
                        {'Kyrkoevenemang'}
                    </Styled.Title>

                    <Styled.Text>
                        <Styled.StyledIcon
                            icon={Icons.CalendarDays}
                            size={IconSizes.Big}
                            variant={IconVariants.Outline}
                        />

                        {'Det händer i kyrkan'}
                    </Styled.Text>
                </Styled.Container>
            </Styled.InfoContainer>

            <Styled.Content>
                <Styled.Container>
                    <Styled.CardsWrapper>
                        {
                            Object.keys(groupByDate(sortedItems)).map((group: string) => {
                                return (
                                    <Fragment key={group}>
                                        <Styled.DateStamp key={group}>
                                            {
                                                isMounted && DateUtility.isValid(new Date(group)) ? (
                                                    DateUtility.format(new Date(group), "do MMM", {
                                                        locale: DateUtility.locales.sv
                                                    })
                                                ) : (
                                                    '-'
                                                )
                                            }
                                        </Styled.DateStamp>

                                        <Styled.CardContent>
                                            {
                                                groupByDate(sortedItems)[group].map((event: ChurchEventAdItem) => {
                                                    return (
                                                        <ChurchEventCard
                                                            path={`/event/${event.uid}`}
                                                            key={event.uid}
                                                            image={event.main_image}
                                                            startTime={event.event_start_time}
                                                            endTime={event.event_end_time}
                                                            location={event.subject_location}
                                                            placement={event.placement}
                                                            title={event.title}
                                                            subTitle={event?.subtitle}
                                                            description={event.body}
                                                            loading={false}
                                                        />
                                                    )
                                                })
                                            }
                                        </Styled.CardContent>
                                    </Fragment>
                                )
                            })
                        }
                    </Styled.CardsWrapper>
                </Styled.Container>
            </Styled.Content>
        </Styled.Wrapper>
    )
}

export default ChurchEventView
