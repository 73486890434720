import {
    useRef,
    useState,
    useEffect,
    useCallback
} from 'react'

import Header from '~/components/Header'
import HiddenFormInput from '~/components/HiddenFormInput'
import CardsSearchPage from '~/pages/search/CardsSearchPage'

import * as Styled from './style'
import type { SearchRef } from '~/components/Search/types'

const DEFAULT_PAGE = 1
const DEFAULT_HITS_PER_PAGE = 24

const MarketGuideStartPage = () => {
    const mounted = useRef(false)
    const headerRef = useRef<SearchRef>(null)

    const [page, setPage] = useState(DEFAULT_PAGE)
    const [hitsPerPage] = useState(DEFAULT_HITS_PER_PAGE)

    useEffect(() => {
        if (mounted.current) {
            headerRef.current?.submit()
        }

        mounted.current = true
    }, [page])

    const handleOnSearchChange = useCallback(() => {
        setPage(DEFAULT_PAGE)
    }, [])

    const handleOnPaginationChange = useCallback((value: number) => {
        setPage(value)
    }, [])
    
    return (
        <Styled.Form
            method={'get'}
            action={'/'}
        >
            <HiddenFormInput
                name={'page'}
                value={`${page}`}
            />

            <HiddenFormInput
                name={'hitsPerPage'}
                value={`${hitsPerPage}`}
            />

            <Styled.Wrapper>
                <Header
                    ref={headerRef}
                    onSearchChange={handleOnSearchChange}
                />

                <CardsSearchPage
                    page={page}
                    hitsPerPage={hitsPerPage}
                    onPaginationChange={handleOnPaginationChange}
                />
            </Styled.Wrapper>
        </Styled.Form>
    )
}

export default MarketGuideStartPage