import styled, { css } from 'styled-components'

import FluffyCard from '@gotamedia/fluffy/Card'
import FluffyIcon, {
    IconSizes,
    IconVariants
} from '@gotamedia/fluffy/Icon'

const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 15px 0 0;
`

const DescriptionName = styled(FluffyCard.Paragraph)`
    font-weight: 700;
    margin-bottom: 0;
    color: #2E2A25;
`

const DescriptionValue = styled(FluffyCard.Paragraph)`

`
const LinkText = styled(FluffyCard.Paragraph)<{ $loading: boolean }>`
    display: flex;
    margin: 0 auto 8px 0;
    color: #0182C9;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }

    &:hover {
        text-decoration: underline;
    }

    ${({ $loading }) => $loading && css`
        width: 50%;
        margin-bottom: 20px;
    `}
`

const Icon = styled(FluffyIcon).attrs(() => {
    return {
        size: IconSizes.Small,
        variant: IconVariants.Solid
    }
})`
    color: black;
    margin: auto 8px auto 0;
`

export {
    DescriptionWrapper,
    DescriptionName,
    DescriptionValue,
    LinkText,
    Icon
}