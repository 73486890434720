import { useCallback } from 'react'
import slugify from 'slugify'
import { Icons } from '@gotamedia/fluffy/Icon'

import getValidUrl from '~/utils/getValidUrl'

import useViewSize from '~/hooks/useViewSize'

import Card from '~/components/Card'

import * as Styled from './style'
import type * as Types from './types'
import type { MouseEvent } from 'react'

const MarketGuideSearchCards: Types.MarketGuideSearchCardsComponent = props => {
    const {
        loading,
        items
    } = props

    const { isSmall } = useViewSize()

    const callPhone = useCallback((event: MouseEvent<HTMLParagraphElement>, phone: string) => {
        event.preventDefault()
        event.stopPropagation()
        
        window.open(`tel:${phone}`, '_blank')
    }, [])
    
    const openWebsite = useCallback((event: MouseEvent<HTMLParagraphElement>, website: string) => {
        event.preventDefault()
        event.stopPropagation()
        
        window.open(getValidUrl(website), '_blank')
    }, [])

    return (
        <>
            {
                Array.isArray(items) && items?.map((item, idx) => {
                    const companyName = slugify(item.name, {
                        locale: 'sv'
                    })

                    return (
                        <Card
                            key={`${item.uuid}-${idx}`}
                            path={`/${companyName}/${item.uuid}`}
                            image={item.logoUrl}
                            headline={item.name}
                            subHeadline={item.address}
                            loading={loading}
                            placeImageWitHeadline={true}
                        >
                            <Styled.DescriptionWrapper>
                                {
                                    item.phone ? (
                                        <Styled.LinkText
                                            $loading={loading}
                                            onClick={event => callPhone(event, item.phone)}
                                        >
                                            {
                                                !isSmall() ? (
                                                    <Styled.Icon icon={Icons.Phone} />
                                                ) : (
                                                    null
                                                )
                                            }

                                            {item.phone}
                                        </Styled.LinkText>
                                    ) : (
                                        null
                                    )
                                }

                                {
                                    item.websiteUrl ? (
                                        <Styled.LinkText
                                            $loading={loading}
                                            onClick={event => openWebsite(event, item.websiteUrl)}
                                        >
                                            {
                                                !isSmall() ? (
                                                    <Styled.Icon icon={Icons.Link} />
                                                ) : (
                                                    null
                                                )
                                            }
                                            
                                            {'Hemsida'}
                                        </Styled.LinkText>
                                    ) : (
                                        null
                                    )
                                }
                            </Styled.DescriptionWrapper>
                        </Card>
                    )
                })
            }
        </>
    )
}

export default MarketGuideSearchCards