const HiddenFormInput = ({ name, value }: { name: string, value?: string }) => {

    return (
        value ? (
            <input
                type={'hidden'}
                name={name}
                value={value}
            />
        ) : (
            null
        )
    )
}

export default HiddenFormInput