import ChurchEventCard from '~/components/ChurchEvent/components/ChurchEventCard'

import type { ChurchEventAdItem } from '~/modules/churchevent/types'
import * as Styled from './style'
import type * as Types from './types'

type GroupedChurchEvent = {
    [key: string]: ChurchEventAdItem[];
}


const ChurchEventSearchCards: Types.ChurchEventSearchCardsComponent = props => {
    const {
        items
    } = props

    const groupDate = ((items: ChurchEventAdItem[]) => {
        const test = items.reduce((prev: GroupedChurchEvent, curr) => {
            const eventStartDate: string = new Date(curr.event_start_time).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric'})
            if (eventStartDate) {
                const previousValue = prev?.[eventStartDate] || []
                return {
                    ...prev,
                    [eventStartDate]: [...previousValue, curr]
                }
            }
            return prev
        }, {} as GroupedChurchEvent)

        return test
    })
    
    return (
        <>
            {

                items && Object.keys(groupDate(items)).map((group: string) => {
                    return (
                        <>
                            <Styled.DateStamp key={group}>{new Date(group).toLocaleString(undefined, {
                                month: "short", day: "numeric" 
                            })}</Styled.DateStamp>
                            <Styled.CardContent>
                                {groupDate(items)[group].map((event: ChurchEventAdItem) => {
                                    return (
                                        <ChurchEventCard
                                            path={`/event/${event.uuid}`}
                                            key={event.uuid} 
                                            image={event.main_image}
                                            startTime={event.event_start_time}
                                            endTime={event.event_end_time}
                                            location={event.location}
                                            placement={event.placement}
                                            title={event.title}
                                            description={event.body}
                                            loading={false}
                                        />
                                    )
                                })}
                                
                            </Styled.CardContent>
                        </>
                    )})
            }
        </>
    )
}

export default ChurchEventSearchCards