import styled, { css } from 'styled-components'

import FluffyCard from '@gotamedia/fluffy/Card'
import { tint } from 'polished'
import { Link } from '@remix-run/react'
import { themeHelpers } from '@gotamedia/fluffy/theme'
import Icon from '@gotamedia/fluffy/Icon'

const Card = styled(FluffyCard)`
    border-radius: 0;
    min-height: 220px;
    
    &:hover {
        cursor: pointer;
        background: #f2f2f2;
    }
`

const BrokenCardImage = styled.img`
    width: 154px;
    height: 77px;
    margin: auto;
`

const Flex = styled.div`
    flex: 1;
    display: flex; 
    flex-direction: row;
`

const ImageWrapper = styled.div<{ $overflow?: boolean }>`
    padding: 12px;
    position: relative;

    ${({ $overflow }) => $overflow && css`        
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background: linear-gradient(rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 80%,rgb(255,255,255) 100%);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    `}
`

const StyledLink = styled(Link)`
    width:  calc(50% - 10px);

    ${themeHelpers.isSmallDevice(css`
        width: 100%;
    `)}
`

const TextLink = styled.p`
    color: ${({ theme }) => theme.colors.link};
    &:hover {
        text-decoration: underline;
    }
`

const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px;
    padding: 5px;
    max-width: 1250px;
    width: 100%;

    &:hover {
        background-color: ${({ theme }) => tint(0.85, theme.colors.brand)};
    }
`
const ImageContainer = styled.div`
    display: flex;
    max-width: 104px;
    height: 77px;
    width: 100%;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.grey[4]};
`

const Image = styled.img`
    height: 146px;
    object-fit: contain;
    max-width: 220px;
    margin-right: 20px;

    ${themeHelpers.isSmallDevice(css`
        max-width: 200px;
        margin-right: 10px;
    `)}
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${themeHelpers.isSmallDevice(css`
        flex-direction: column;
    `)}
`

const DetailInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0;
`

const Text = styled.span`
`


const StyledIcon = styled(Icon)`
    margin-right: 8px;
`

const Headline = styled.div`
    display: flex;
    flex-direction: column;
`

const SubHeadline = styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: "Sanomat Sans";
    `

const CardImage = styled(Card.Image) <{ $overflow?: boolean }>`
    width: 100%;
    height: 100%;
    aspect-ratio: 2/3;
    position: relative;

    ${({ $overflow }) => $overflow && css`
        object-fit: cover;
        object-position: top;
    `}
`

export {
    StyledLink,
    Headline,
    SubHeadline,
    BrokenCardImage,
    Text,
    CardContainer,
    InfoContainer,
    ImageContainer,
    DetailContainer,
    DetailInfoContainer,
    Card,
    Image,
    Flex,
    ImageWrapper,
    StyledIcon,
    CardImage,
    TextLink
}
