import {
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react'

import {
    useLoaderData,
    useTransition
} from '@remix-run/react'

import Pagination, { PaginationSizes } from '@gotamedia/fluffy/Pagination'
import useScroll from '@gotamedia/fluffy/useScroll'

import useViewSize from '~/hooks/useViewSize'

import NoResults from '~/components/NoResults'
import SearchCards from '~/components/SearchCards'

import type { MouseEventHandler } from 'react'
import type { SearchResponse } from '~/modules/marketGuide/types'
import * as Styled from './style'
import type * as Types from './types'

const CardsSearchPage: Types.CardsSearchPageComponent = (props) => {
    const {
        page,
        onPaginationChange
    } = props

    const mounted = useRef(false)

    const scroll = useScroll()

    const { isSmall, isMedium } = useViewSize()

    const transition = useTransition()
    const submitting = transition.state === 'submitting'

    const {
        currentPage: searchCurrentPage,
        payload: searchPayload,
        totalHits
    } = useLoaderData<SearchResponse>()

    const [items, setItems] = useState<SearchResponse['payload']>(Array.isArray(searchPayload) ? searchPayload : [])

    useEffect(() => {
        if (searchPayload) {
            setItems(searchPayload)
        }
    }, [
        searchPayload,
        searchCurrentPage
    ])

    useEffect(() => {
        if (mounted.current) {
            scroll({
                position: {
                    top: 1
                }
            })
        }

        mounted.current = true
    }, [
        items,
        scroll
    ])

    const handleOnPaginationClick = useCallback<MouseEventHandler>((event) => {
        event.stopPropagation()
        event.preventDefault()
    }, [])

    const totalPages = Math.ceil(totalHits / 24) || 0

    return (
        <Styled.Wrapper>
            <Styled.InnerWrapper>
                {
                    totalHits > 0 ? (
                        <Styled.TotalHitsLabel>
                            {`${totalHits} ${totalHits > 1 ? 'annonser' : 'annons'}`}
                        </Styled.TotalHitsLabel>
                    ) : (
                        null
                    )
                }

                {
                    items.length > 0 ? (
                        <Styled.Content>
                            <SearchCards
                                items={items}
                                loading={submitting}
                            />
                        </Styled.Content>
                    ) : (
                        <NoResults />
                    )
                }

                {
                    totalPages > 1 ? (
                        <Styled.PaginationWrapper onClick={handleOnPaginationClick}>
                            <Pagination
                                disabled={!totalHits || totalPages <= 1}
                                activePage={page}
                                onChange={onPaginationChange}
                                size={PaginationSizes.Small}
                                totalPages={totalPages}
                                visiblePages={isSmall() ? 5 : (isMedium() ? 10 : 15)}
                            />
                        </Styled.PaginationWrapper>
                
                    ) : (
                        null
                    )
                }
            </Styled.InnerWrapper>
        </Styled.Wrapper>
    )
}

export default CardsSearchPage