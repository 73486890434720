import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy'

import FluffyIcon, {
    Icons,
    IconVariants
} from '@gotamedia/fluffy/Icon'
import FluffyInput from '@gotamedia/fluffy/Input'
import FluffyInputGroup from '@gotamedia/fluffy/InputGroup'
import FluffySelect, { SelectTypes } from '@gotamedia/fluffy/Select'
import FluffyCheckbox from '@gotamedia/fluffy/Checkbox'
import FluffyButton from '@gotamedia/fluffy/Button'

const Wrapper = styled.div`
    margin-top: 32px;

    ${themeHelpers.isSmallDevice(css`
        margin-top: 16px;
    `)}
`

const SearchInputGroup = styled(FluffyInputGroup)`
    width: 100%;
`

const SearchIcon = styled(FluffyIcon).attrs(() => {
    return {
        icon: Icons.MagnifyingGlass,
        variant: IconVariants.Solid
    }
})`

`

const SearchInput = styled(FluffyInput)`
    font-size: 16px;
`

const SelectsWrapper = styled.div`
    width: 100%;
    display: flex;
    margin-top: 12px;

    ${themeHelpers.isNotLargeDevice(css`
        flex-direction: column;
    `)}
`

const SelectWrapper = styled.div`
    flex: auto;
    display: flex;
    flex-direction: column;

    &:last-child {
        ${themeHelpers.isLargeDevice(css`
            margin-left: 12px;
        `)}

        ${themeHelpers.isNotLargeDevice(css`
            margin-top: 12px;
        `)}
    }

`

const Label = styled.p`
    font-size: 14px;
    line-height: 18px;
    color: #8A8A8D;
    margin-bottom: 4px;
`

const Select = styled(FluffySelect).attrs(() => {
    return {
        isMultiSelect: true,
        showResetButton: true,
        showApplyButton: true,
        portalWhenMounted: true,
        closeOnScrollOutside: true,
        type: SelectTypes.Checkbox
    }
})`
    filter: none;
`

const Checkbox = styled(FluffyCheckbox)`
    margin-top: 12px;
`

const SearchButton = styled(FluffyButton)`
    width: 100%;
    margin-top: 12px;
`

const Spinner = styled(FluffyIcon).attrs(() => {
    return {
        spin: true,
        icon: Icons.ArrowPath
    }
})`

`

export {
    Wrapper,
    SearchInputGroup,
    SearchIcon,
    SearchInput,
    SelectsWrapper,
    SelectWrapper,
    Label,
    Select,
    Checkbox,
    SearchButton,
    Spinner
}