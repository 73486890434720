import useLazyRef from '@gotamedia/fluffy/useLazyRef'

import * as Styled from './style'
import type * as Types from './types'

const Card: Types.CardComponent = (props) => {
    const {
        path,
        loading,
        image,
        headline,
        subHeadline,
        children,
        placeImageWitHeadline,
        onClick
    } = props

    const imageContent = (
        <Styled.ImageWrapper
            style={{
                minWidth: 154,
                minHeight: 77
            }}
        >
            <Styled.CardImage
                src={image ? image : '/images/placeholder.png'}
                alt={headline}
            />
        </Styled.ImageWrapper>
    )

    return (
        <Styled.Link
            to={path}
            state={{ intirnalNavigation: true }}
            onClick={onClick}
        >
            <Styled.Card loading={loading}>
                <Styled.CardBody>
                    <Styled.Wrapper>
                        <Styled.HeaderWrapper>
                            {
                                subHeadline ? (
                                    <Styled.CardSubHeadline>
                                        {subHeadline}
                                    </Styled.CardSubHeadline>
                                ) : (
                                    null
                                )
                            }

                            {
                                headline ? (
                                    <Styled.CardHeadline>
                                        {headline}
                                    </Styled.CardHeadline>
                                ) : (
                                    null
                                )
                            }
                        </Styled.HeaderWrapper>

                        {
                            placeImageWitHeadline ? (
                                imageContent
                            ) : (
                                null
                            )
                        }
                    </Styled.Wrapper>

                    <Styled.Content>
                        {children}

                        {
                            !placeImageWitHeadline ? (
                                imageContent
                            ) : (
                                null
                            )
                        }
                    </Styled.Content>
                </Styled.CardBody>
            </Styled.Card>
        </Styled.Link>
    )
}

const SkeletonCards = ({ amount = 5, placeImageWitHeadline = false }) => {
    const fakeCards = useLazyRef(() => new Array(amount).fill(0))

    return (
        <>
            {
                fakeCards.current!.map((i, idx) => (
                    <Card
                        key={idx}
                        path={''}
                        headline={'x'}
                        subHeadline={'x'}
                        loading={true}
                        placeImageWitHeadline={placeImageWitHeadline}
                    />
        
                ))
            }
        </>
    )
}

export {
    SkeletonCards
}

export default Card