import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy/theme'

const Wrapper = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    background-color: #F5F5F5;
`

const InnerWrapper = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: auto;
    display: flex;
    flex: 1;
    flex-direction: column;

    ${themeHelpers.isSmallDevice(css`
        width: 95%;
    `)}
`

const TotalHitsLabel = styled.p`
    color: #55565A;
    font-size: 14px;
    line-height: 18px;
`

const Content = styled.div`
    margin: 20px 0;

    ${themeHelpers.isNotSmallDevice(css`
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 20px;
    `)}
`

const PaginationWrapper = styled.div`
    padding-top: 20px;
    margin: auto auto 20px auto;
`

export {
    Wrapper,
    InnerWrapper,
    TotalHitsLabel,
    Content,
    PaginationWrapper
}