import { AvailableApps } from '~/config/apps'

const getNoResultLogo = (appId: string) => {
    switch(appId) {
        case AvailableApps.MarketGuide:
            return `/icons/no-results.svg`

        case AvailableApps.Jobs:
            return `/icons/no-results.svg`

        case AvailableApps.Family:
            return `/icons/no-results.svg`

        default:
            return 'UNKNOWN'
    }
}

export default getNoResultLogo