import { themeHelpers } from "@gotamedia/fluffy/theme"
import Image from "@gotamedia/fluffy/Image"
import styled, { css } from "styled-components"


const HeroWrapper = styled.div`
    display: flex;
    max-width: 1600px;
    margin: 0 auto;
    ${themeHelpers.isNotLargeDevice(css`
        flex-direction: column;
    `)}
    `

const HeroBoxText = styled.div`
    width: 50%;
    align-items: center;
    display: flex;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 16px;
    @media screen and (max-width: 1300px) {
       padding-left: 0px;
    }
    ${themeHelpers.isNotLargeDevice(css`
        width: 100%;
        padding: 0px;
    `)}
`

const HeroBoxTextContent = styled.div`
    display: flex;
    width: 500px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    ${themeHelpers.isNotLargeDevice(css`
        width: 100%;
        gap: 0px;
    `)}
    @media screen and (max-width: 1300px) {
        width: 100%;
     }
`

const HeroBoxImgWrapper = styled.div`
    width: 50%;
    ${themeHelpers.isNotLargeDevice(css`
        width: 100%;
        flex-shrink: 0;
    `)}
    @media screen and (max-width: 1300px){
        width: 50%;
     }
`

const HeroBoxImg = styled(Image)`
     min-height: 100%;
     min-width: 100%;
     object-fit: cover;
`

const PreHeadlineContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${themeHelpers.isNotLargeDevice(css`
        padding: 16px;
    `)}

    @media screen and (max-width: 1300px) {
        padding: 16px 16px 0 16px;
     }
`

const PreHeadline = styled.span<{ brandColor: string}>`
    color: black;
    font-family: "Sanomat Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    &:after {
        display: block;
        content: '';
        width: 40px;
        height: 4px;
        margin-top: 8px;
        background: ${({brandColor }) => brandColor}; 
       }
`

const MainHeadline = styled.h1`
    color: black;
    font-family: Sanomat Extrabold Web;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    font-feature-settings: 'clig' off, 'liga' off;
    ${themeHelpers.isNotLargeDevice(css`
        font-size: 32px;
        line-height: 38px;
        padding: 16px;
    `)}

    @media screen and (max-width: 1300px) {
        font-size: 32px;
        line-height: 38px;
        padding: 0 16px;
     }
    
`
const HeroText = styled.p`
    line-height: 28px;
    font-size: 20px;
    font-family: "Sanomat Sans";
    font-feature-settings: 'clig' off, 'liga' off;
    color: black;
    font-style: normal;
    font-weight: 400;
    ${themeHelpers.isNotLargeDevice(css`   
        font-size: 16px;
        line-height: 24px;
        padding: 16px;
    `)}
    @media screen and (max-width: 1300px){
        font-size: 16px;
        line-height: 24px;
        padding: 0 16px;
     }
`

const HeroLink = styled.a<{ brandColor: string }>`
    display: flex;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background: ${({ brandColor }) => brandColor};
    color: white;
    margin: 16px 0px;
    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0.18) 100%), ${({ brandColor }) => brandColor};
        cursor: pointer;
    }
    ${themeHelpers.isNotLargeDevice(css`   
        margin: 16px;
    `)}
    @media screen and (max-width: 1300px) {
        margin: 16px;
     }
`
 

export { 
    HeroWrapper, 
    HeroBoxText, 
    HeroBoxTextContent,
    HeroBoxImgWrapper,
    HeroBoxImg,
    MainHeadline,
    PreHeadlineContainer,
    PreHeadline,
    HeroText,
    HeroLink
}