
import { IconSizes } from '@gotamedia/fluffy/Icon'
import React, {
    useCallback,
    useState
} from 'react'

import * as Styled from "./style"
import type * as Types from "./types"

const CollapsibleFaq: Types.CollapsibleFaq = ({questionHeading, sections}) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onClickHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        setIsOpen(!isOpen)
    }, [isOpen]) 

    return (
        <>
            <Styled.ToggleQuestion
                isItOpen={isOpen.valueOf()}
                onClick={onClickHandler}
                variant="text">
                <Styled.ToggleQuestionHeadline>{questionHeading}</Styled.ToggleQuestionHeadline>
                <Styled.Arrow 
                    size={IconSizes.Micro}
                    icon={isOpen ? "ChevronUp" : "ChevronDown"} 
                />
            </Styled.ToggleQuestion>
            <Styled.CollapsibleContainer 
                open={isOpen}
                isItOpen={isOpen.valueOf()}>
                <Styled.CollapsibleContentWrapper >
                    { sections?.map( (section, index) => {
                        return (
                            <>
                                <Styled.SectionHeading 
                                    key={section.sectionHeading}>
                                    {section.sectionHeading}
                                </Styled.SectionHeading>
                                { section.listContent && 
                                <Styled.List key={index}>
                                    {section.listContent.map((content, index) => {
                                        return (
                                            (Array.isArray(content)) ? (
                                                <ul>
                                                    {content.map((subContent, subIndex) => (
                                                        <li key={subIndex}>{subContent}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <li key={content}>{content}</li>   
                                            )
                                        )
                                    }) }
                                </Styled.List>
                                }
                                { section.textContent &&
                                    <Styled.Text key={section.textContent}>
                                        {section.textContent}
                                    </Styled.Text>
                                }
                                { section.howTo && 
                                <>
                                    <Styled.Text key={section.howTo.lineOne}>
                                        {section.howTo.lineOne}
                                        <Styled.Link 
                                            href={section.howTo.lineOneLink}>
                                            {section.howTo.lineOneLinkText}
                                        </Styled.Link>
                                        {section.howTo.lineOneContinued}
                                    </Styled.Text>
                                    <Styled.Text 
                                        key={section.howTo.lineTwo}>
                                        {section.howTo.lineTwo}
                                        <Styled.Link 
                                            href={"tel:"+section.howTo.lineTwoPhone}>
                                            {section.howTo.lineTwoPhoneText}
                                        </Styled.Link>
                                        {" " + section.howTo.lineTwoContinued}
                                        <Styled.Link 
                                            href={"mailto:"+section.howTo.lineTwoLink}>
                                            {section.howTo.lineTwoLinkText}
                                        </Styled.Link>
                                        {section.howTo.lineTwoEnd}
                                    </Styled.Text>
                                </>
                                }
                                { section.other && 
                                <>
                                    <Styled.Text 
                                        key={section.other.lineOne}>
                                        {section.other.lineOne}
                                        <Styled.Link 
                                            href={section.other.lineOneLink}>
                                            {section.other.lineOneLinkText}
                                        </Styled.Link>
                                        {section.other.lineOneContinued}
                                        <Styled.Link 
                                            href={section.other.lineOneLinkTwo}>
                                            {section.other.lineOneLinkTwoText}
                                        </Styled.Link>
                                        {section.other.lineOneEnd}
                                    </Styled.Text>
                                    
                                    <Styled.Text key={section.other.lineTwo}>
                                        {section.other.lineTwo}
                                        <Styled.Link 
                                            href={section.textContentLink}>
                                            {section.textContentLinkTitle}
                                        </Styled.Link>
                                    </Styled.Text> 
                                </>
                                }
                                { section.textContentLink && 
                                    <Styled.Link 
                                        key={index} 
                                        target={"_blank"} 
                                        href={section.textContentLink}>
                                        {section.textContentLinkTitle}
                                    </Styled.Link>
                                }
                            </> 
                        )})
                    }
                </Styled.CollapsibleContentWrapper>
            </Styled.CollapsibleContainer>
        </>
    )
}
    
export default CollapsibleFaq
