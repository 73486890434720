import styled from 'styled-components'

import FluffyIcon, {
    Icons,
    IconSizes,
    IconVariants
} from '@gotamedia/fluffy/Icon'

const Wrapper = styled.div`
    max-width: 545px;
    display: flex;
    margin-top: 12px;
    font-family: 'Sanomat Sans Text';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
`

const Text = styled.p`
    color: #8A8A8D;
`

const MoreInfo = styled.p`
    color: #0182C9;
    margin: 0 4px;
    display: flex;
    cursor: pointer;
`

const Icon = styled(FluffyIcon).attrs(() => {
    return {
        icon: Icons.ChevronDown,
        size: IconSizes.Micro,
        variant: IconVariants.Solid
    }
})`
    color: #0182C9;
    margin: auto 0;
`

export {
    Wrapper,
    Text,
    MoreInfo,
    Icon
}
