import useEmbeddedRect from '~/hooks/useEmbeddedRect'

import ChurchEventsView from '~/components/ChurchEvent/components/ChurchEventsView'

import * as Styled from './style'
import type * as Types from './types'

const ChurchEventStartPage: Types.ChurchEventStartPageComponent = () => {
    const { rectRefSetter } =  useEmbeddedRect()

    return (
        <Styled.Wrapper ref={rectRefSetter}>
            <Styled.InnerWrapper>
                <ChurchEventsView />
            </Styled.InnerWrapper>
        </Styled.Wrapper>
    )
}

export default ChurchEventStartPage