import { AvailableApps } from '~/config/apps'
import throwAppError from '~/utils/throwAppError'

import useRouteData from '~/hooks/useRouteData'

import { SkeletonCards } from '~/components/Card'
import MarketGuideSearchCards from './components/MarketGuideSearchCards'
import JobsSearchCards from './components/JobsSearchCards'
import ChurchEventSearchCards from './components/ChurchEventSearchCards'

import type * as Types from './types'
import type { RootLoaderData } from '~/types'

const SearchCards: Types.SearchCardsComponent = props => {
    const {
        loading,
        items
    } = props

    const { app } = useRouteData<RootLoaderData>('root')

    if (!app || !app.id) {
        throwAppError()
    }

    switch(app.id) {
        case AvailableApps.MarketGuide:
            return (
                <>
                    <MarketGuideSearchCards
                        loading={loading}
                        items={items as Types.MarketGuideItems}
                    />

                    {
                        items.length === 0 && loading ? (
                            <SkeletonCards
                                amount={4}
                                placeImageWitHeadline={true}
                            />
                        ) : (
                            null
                        )
                    }
                </>
            )

        case AvailableApps.Jobs:
            return (
                <>
                    <JobsSearchCards
                        loading={loading}
                        items={items as Types.JobItems}
                    />

                    {
                        items.length === 0 && loading ? (
                            <SkeletonCards amount={4} />
                        ) : (
                            null
                        )
                    }
                </>
            )

        case AvailableApps.ChurchEvent:
            return (
                <>
                    <ChurchEventSearchCards
                        loading={loading}
                        items={items["data_list"] as Types.ChurchEventItems}
                    />

                    {
                        items.length === 0 && loading ? (
                            <SkeletonCards amount={4} />
                        ) : (
                            null
                        )
                    }
                </>
            )

        default:
            return null
    }
}

export default SearchCards
