import { Link } from '@remix-run/react'

import useLazyRef from '@gotamedia/fluffy/useLazyRef'

import useViewSize from '~/hooks/useViewSize'

import * as Styled from './style'
import type * as Types from './types'

const FamilyCard: Types.FamilyCardComponent = (props) => {
    const {
        path,
        loading,
        image,
        name,
        overflow
    } = props

    const { isSmall } = useViewSize()

    const hasImage = image ? true : false

    const style = {
        width: isSmall() ? 163.5 : 185,
        height: isSmall() ? 237 : 268
    }

    return (
        <Link
            to={path}
            state={{ intirnalNavigation: true }}
        >
            <Styled.Card
                loading={loading}
                style={style}
            >
                <Styled.ImageWrapper $overflow={overflow}>
                    <Styled.CardImage
                        src={hasImage ? image : '/images/placeholder.png'}
                        alt={name}
                        $overflow={overflow}
                        width={style.width - 24}
                        height={style.height - 24}
                    />
                </Styled.ImageWrapper>
            </Styled.Card>
        </Link>
    )
}

const SkeletonCards = ({ amount = 6 }) => {
    const fakeCards = useLazyRef(() => new Array(amount).fill(0))

    return (
        <>
            {
                fakeCards.current!.map((i, idx) => (
                    <FamilyCard
                        key={idx}
                        path={''}
                        name={'x'}
                        image={'x'}
                        loading={true}
                    />
        
                ))
            }
        </>
    )
}

export {
    SkeletonCards
}

export default FamilyCard