import styled, { css } from 'styled-components'

import Select from '@gotamedia/fluffy/Select'
import { themeHelpers } from '@gotamedia/fluffy/theme'

const Wrapper = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    background-color: #F5F5F5;
`

const InnerWrapper = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: auto;
    display: flex;
    flex: 1;
    flex-direction: column;

    ${themeHelpers.isSmallDevice(css`
        width: 95%;
    `)}
`

const HeaderWrapper = styled.div`
    display: flex;
    margin-bottom: 16px;
`

const TotalHitsLabel = styled.p`
    color: #55565A;
    font-size: 14px;
    line-height: 18px;
    margin: auto auto auto 0;
`

const SelectWrapper = styled.div`
    display: flex;
    margin: auto 0 auto auto;
`

const SortLabel = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: auto 8px;
`

const SortSelect = styled(Select).attrs(() => {
    return {
        forceDirection: true,
        portalWhenMounted: true
    }
})`

`

const Content = styled.div`
    width: 100%;
    display: grid;
    gap: 30px;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 185px);

    ${themeHelpers.isSmallDevice(css`
        gap: 15px;
        grid-template-columns: repeat(auto-fill, 163.5px);
    `)}
`

const PaginationWrapper = styled.div`
    margin: 20px auto;
`

export {
    Wrapper,
    InnerWrapper,
    HeaderWrapper,
    TotalHitsLabel,
    SelectWrapper,
    SortLabel,
    SortSelect,
    Content,
    PaginationWrapper
}