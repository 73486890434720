import Hero from "~/components/Hero"
import Cards from "../components/cards"
import Faq from "../components/faq"
import type * as Types from "./types"

const AdPortalStartPage: Types.StartPageComponent = () => {
    return (
        <>
            <Hero />
            <Cards />
            <Faq />
        </>
    )
}

export default AdPortalStartPage