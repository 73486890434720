import Collapsible from "@gotamedia/fluffy/Collapsible"
import Icon from "@gotamedia/fluffy/Icon"
import themeHelpers from "@gotamedia/fluffy/utils/theme/helpers"
import styled, { css } from "styled-components"
import Button from "@gotamedia/fluffy/Button"

const ToggleQuestion = styled(Button)<{ isItOpen: boolean }>`
    width: 100%;
    color: black;
    justify-content: flex-start;
    border-bottom: ${({ isItOpen }) => isItOpen ?  '' : 'solid 1px #DADAD8'};
    border-radius: 0px;
    align-items: center;
    padding: 32px 0px;
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &:hover {
        background: var(--general-grey-5-f-5-f-5-f-5, #F5F5F5);
    }
    ${themeHelpers.isSmallDevice(css`
        padding: 30px 0px;
        h4 {
            max-width: 259px;
            white-space: normal;
            word-wrap: break-word;
            text-align: left;
        }
    `)}
`

const ToggleQuestionHeadline = styled.h4`
    color: #2E2A25;
    font-family: Sanomat Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`

const Arrow = styled(Icon)`
    &.fluffy-icon:last-of-type  {
        margin-left: auto;
        margin-top: 0;
        margin-bottom: 0;
        height: 12px;
        width: 12px;
    }
`

const SectionContent = styled.div`
`

const SectionHeading = styled.p`
    margin-top: 10px;
    font-weight: bold;
    font-family: Sanomat Sans Text;
    color: #2E2A25;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`
const List = styled.ul`
    li {
        text-align: left;
        font-family: Sanomat Sans Text;
        color: #2E2A25;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; 
    }
`

const Text = styled.p`
    font-family: Sanomat Sans Text;
    color: #2E2A25;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    margin-bottom: 10px;
    a {
        text-decoration: underline;
        color: #0182C9;
    }
`
const Link = styled.a`
    text-decoration: underline;
    color: #0182C9;
    &:hover {
        text-decoration: none;
    }
`

const CollapsibleContainer = styled(Collapsible)<{ isItOpen: boolean }>`
    border-bottom: ${({ isItOpen }) => isItOpen ?  'solid 1px #DADAD8' : ''};
    width: 100%;
`


const CollapsibleContentWrapper = styled.div`
    padding-bottom: 20px;
`

export { 
    ToggleQuestion,
    ToggleQuestionHeadline,
    Arrow,
    SectionContent, 
    SectionHeading,
    List,
    Text,
    Link,
    CollapsibleContainer,
    CollapsibleContentWrapper
}