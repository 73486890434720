import type { BannerSVG } from '../types'

const Banner: BannerSVG = (props) => {
    const {
        className,
        width = 126,
        height = 97,
        fillColor
    } = props

    return (
        <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1260_208713)">
                <path opacity="0.1" d="M63 96.4717C97.7939 96.4717 126 92.1128 126 86.7358C126 81.3589 97.7939 77 63 77C28.2061 77 0 81.3589 0 86.7358C0 92.1128 28.2061 96.4717 63 96.4717Z" fill={fillColor} />
                <path d="M62.2832 28.151V23.8712C62.2832 22.3502 62.8849 20.8915 63.956 19.816C65.0271 18.7404 66.4798 18.1362 67.9945 18.1362C69.5092 18.1362 70.9619 18.7404 72.033 19.816C73.1041 20.8915 73.7058 22.3502 73.7058 23.8712V28.151C73.7056 28.3553 73.6247 28.5511 73.4809 28.6955C73.337 28.8399 73.142 28.9212 72.9386 28.9214H63.0504C62.847 28.9212 62.652 28.8399 62.5082 28.6955C62.3643 28.5511 62.2834 28.3553 62.2832 28.151Z" fill="#2F2E41" />
                <path d="M76.4399 83.8481L74.4254 84.4079L71.3164 76.8723L74.2895 76.0461L76.4399 83.8481Z" fill="#FFB8B8" />
                <path d="M72.8269 84.2299L76.7118 83.1503L77.3888 85.6065L71.0578 87.3659C70.9689 87.0433 70.9442 86.7063 70.9849 86.3742C71.0257 86.042 71.1313 85.7212 71.2956 85.43C71.4598 85.1388 71.6796 84.8829 71.9424 84.677C72.2051 84.4711 72.5057 84.3192 72.8269 84.2299V84.2299Z" fill="#2F2E41" />
                <path d="M55.4381 85.4041H53.348L52.3535 77.3087H55.4385L55.4381 85.4041Z" fill="#FFB8B8" />
                <path d="M51.8554 84.8043H55.8864V87.3529H49.3174C49.3174 87.0182 49.383 86.6868 49.5106 86.3776C49.6381 86.0684 49.8251 85.7874 50.0607 85.5508C50.2964 85.3141 50.5762 85.1264 50.8841 84.9983C51.1921 84.8702 51.5221 84.8043 51.8554 84.8043V84.8043Z" fill="#2F2E41" />
                <path d="M52.9176 40.1617C53.0668 40.3639 53.2583 40.5308 53.4787 40.6507C53.6991 40.7705 53.9429 40.8404 54.1931 40.8554C54.4433 40.8703 54.6937 40.8301 54.9267 40.7373C55.1597 40.6446 55.3696 40.5017 55.5417 40.3187L61.0979 42.8281L60.5925 39.6899L55.3937 37.788C55.0684 37.5182 54.6558 37.3775 54.2341 37.3927C53.8124 37.4079 53.4109 37.5779 53.1057 37.8705C52.8005 38.1631 52.6128 38.5579 52.5781 38.9802C52.5434 39.4025 52.6642 39.8229 52.9176 40.1617H52.9176Z" fill="#FFB8B8" />
                <path d="M64.4626 59.0337C62.717 59.0337 60.9098 58.7726 59.3908 57.996C58.5845 57.5919 57.8699 57.025 57.2917 56.3308C56.7136 55.6367 56.2843 54.8302 56.0308 53.9618C55.2393 51.4454 56.2372 48.9732 57.2022 46.5825C57.8002 45.101 58.3651 43.7018 58.5116 42.3225L58.5628 41.8329C58.7911 39.6334 58.9882 37.7339 60.0796 37.0215C60.6454 36.6522 61.406 36.6311 62.4052 36.9569L71.7896 40.0181L71.4445 57.9061L71.3875 57.9253C71.3384 57.9418 68.0302 59.0337 64.4626 59.0337Z" fill="#2F2E41" />
                <path d="M62.2406 31.388C62.2406 31.388 66.8438 30.0185 70.424 30.532C70.424 30.532 68.3782 41.8308 69.0601 45.5971C69.7421 49.3633 57.2113 47.0522 59.7686 43.4572L60.621 39.1773C60.621 39.1773 58.9162 37.4654 60.4505 35.4111L62.2406 31.388Z" fill={fillColor} />
                <path d="M59.9769 58.6945L54.3037 57.6264L56.2844 42.3206C56.4176 41.8932 59.4858 32.1956 60.8793 31.671C61.891 31.3132 62.9258 31.0245 63.9764 30.807L64.1782 30.7664L63.0406 32.4799L58.5118 43.3771L59.9769 58.6945Z" fill="#2F2E41" />
                <path d="M57.0205 83.5284L49.6904 82.3017L53.8039 65.4351L59.925 42.369L59.9854 42.7786C59.9902 42.8091 60.562 45.8223 69.0469 44.4853L69.1215 44.4736L69.1421 44.5466L79.3972 80.9316L71.045 82.3294L63.4272 55.783L57.0205 83.5284Z" fill="#2F2E41" />
                <path d="M67.1807 61.5589L67.1852 61.4294C67.1903 61.2856 67.6946 46.9853 67.5262 41.2341C67.3572 35.4634 69.2178 30.4659 69.2366 30.4161L69.2517 30.376L69.2929 30.3641C71.7001 29.6736 73.7884 31.7346 73.8092 31.7555L73.8386 31.7849L73.153 37.4645L76.0881 58.1175L67.1807 61.5589Z" fill="#2F2E41" />
                <path d="M66.8211 28.8219C69.1337 28.8219 71.0084 26.9393 71.0084 24.6172C71.0084 22.295 69.1337 20.4125 66.8211 20.4125C64.5085 20.4125 62.6338 22.295 62.6338 24.6172C62.6338 26.9393 64.5085 28.8219 66.8211 28.8219Z" fill="#FFB8B8" />
                <path d="M60.9316 24.2136C60.933 23.0108 61.4094 21.8577 62.2564 21.0072C63.1034 20.1567 64.2517 19.6783 65.4495 19.6769H66.302C67.4998 19.6783 68.6482 20.1567 69.4951 21.0072C70.3421 21.8577 70.8185 23.0108 70.8199 24.2136V24.2992H69.0184L68.404 22.5715L68.2811 24.2992H67.3501L67.0401 23.4275L66.978 24.2992H60.9316V24.2136Z" fill="#2F2E41" />
                <path d="M66.4095 29.4027C66.325 29.2877 66.2745 29.1511 66.2638 29.0085C66.2532 28.8659 66.2829 28.7233 66.3495 28.5969C67.2528 26.8716 68.5176 23.6836 66.8388 21.7176L66.7181 21.5763H71.5919V28.9239L67.1644 29.7083C67.1196 29.7163 67.0742 29.7203 67.0287 29.7203C66.9078 29.7203 66.7887 29.6915 66.681 29.6363C66.5734 29.5811 66.4804 29.501 66.4095 29.4027Z" fill="#2F2E41" />
                <path opacity="0.3" d="M27.8691 32.6961C24.5606 29.8926 22.4231 25.9422 21.8809 21.6294C21.3388 17.3166 22.4316 12.9568 24.9426 9.41565C24.6128 9.74911 24.294 10.0983 23.9862 10.4633C22.5257 12.2014 21.4204 14.2114 20.7334 16.3784C20.0464 18.5454 19.7913 20.8271 19.9825 23.0932C20.1736 25.3592 20.8074 27.5653 21.8477 29.5853C22.8879 31.6054 24.3142 33.4 26.0451 34.8666C27.776 36.3332 29.7777 37.4431 31.9358 38.1329C34.0938 38.8227 36.3661 39.079 38.6228 38.887C40.8795 38.695 43.0764 38.0586 45.0882 37.014C47.0999 35.9695 48.8871 34.5373 50.3476 32.7992C50.6547 32.4337 50.9439 32.0594 51.2152 31.6763C48.1614 34.7574 44.0653 36.5727 39.7404 36.7616C35.4154 36.9505 31.1778 35.4993 27.8691 32.6961V32.6961Z" fill="black" />
                <path d="M68.165 47.5123C68.4085 47.572 68.662 47.5775 68.9078 47.5282C69.1536 47.4789 69.3856 47.3762 69.5876 47.2271C69.7895 47.078 69.9564 46.8863 70.0766 46.6655C70.1968 46.4446 70.2673 46.2 70.2831 45.9488L76.1535 44.3127L73.7672 42.2235L68.5585 44.0975C68.1365 44.0988 67.7296 44.2554 67.415 44.5378C67.1003 44.8201 66.8998 45.2085 66.8513 45.6294C66.8028 46.0503 66.9097 46.4745 67.1519 46.8215C67.394 47.1685 67.7545 47.4143 68.165 47.5123V47.5123Z" fill="#FFB8B8" />
                <path d="M70.6053 46.6463L70.248 43.2385L75.1517 40.5218L71.9546 36.6357L72.4763 32.2703L73.7754 31.7112L73.8158 31.7634C74.4283 32.5543 79.8007 39.5152 79.8007 40.3757C79.8007 41.2595 78.773 43.8549 77.3668 44.5609C76.0105 45.2419 70.9172 46.5654 70.701 46.6215L70.6053 46.6463Z" fill="#2F2E41" />
                <path d="M24.7944 29.2474C24.8052 29.226 24.7879 29.1954 24.7557 29.179C24.7236 29.1625 24.6887 29.1664 24.6778 29.1877C24.667 29.209 24.6843 29.2397 24.7165 29.2561C24.7486 29.2726 24.7835 29.2687 24.7944 29.2474Z" fill="#E6E8EC" />
                <path d="M50.0541 4.91085C46.9869 2.30649 43.2335 0.655351 39.2507 0.158367C35.2678 -0.338618 31.2273 0.339977 27.6208 2.11158C24.0142 3.88317 20.9972 6.67139 18.9367 10.1369C16.8763 13.6025 15.8613 17.596 16.0152 21.6314C16.1692 25.6668 17.4855 29.5702 19.8039 32.8666C22.1223 36.163 25.343 38.7103 29.0739 40.1984C32.8048 41.6866 36.8851 42.0515 40.8183 41.2487C44.7516 40.4459 48.3681 38.5101 51.2279 35.6768L76.6225 57.2385C77.0089 57.5672 77.5092 57.728 78.0134 57.6855C78.5176 57.643 78.9844 57.4007 79.3111 57.0119C79.6378 56.6231 79.7976 56.1196 79.7553 55.6123C79.7131 55.1049 79.4723 54.6352 79.0859 54.3065L79.0831 54.3041L53.6885 32.7424C56.6386 28.482 57.9012 23.2666 57.2289 18.1182C56.5566 12.9699 53.9979 8.25976 50.0541 4.91085V4.91085ZM48.4171 30.6662C46.4387 33.0254 43.8095 34.7413 40.862 35.597C37.9145 36.4526 34.781 36.4096 31.8578 35.4732C28.9346 34.5369 26.353 32.7494 24.4394 30.3366C22.5258 27.9239 21.3662 24.9944 21.1072 21.9185C20.8483 18.8426 21.5016 15.7585 22.9845 13.0563C24.4674 10.354 26.7134 8.15488 29.4384 6.73704C32.1634 5.3192 35.245 4.7463 38.2936 5.0908C41.3422 5.43529 44.2207 6.6817 46.5653 8.67242H46.5653C48.1221 9.9942 49.4049 11.6116 50.3404 13.4322C51.276 15.2528 51.846 17.2411 52.018 19.2834C52.1899 21.3257 51.9604 23.3821 51.3426 25.3351C50.7247 27.2882 49.7306 29.0997 48.4171 30.6662V30.6662Z" fill="#3F3D56" />
            </g>
            <defs>
                <clipPath id="clip0_1260_208713">
                    <rect width={width} height="96.4717" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Banner