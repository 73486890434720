import type { BannerSVG } from '../types'

const Banner: BannerSVG = (props) => {
    const {
        className,
        width = 576,
        height = 227,
        fillColor
    } = props

    return (
        <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" d="M287.5 227C446.282 227 575 214.24 575 198.5C575 182.76 446.282 170 287.5 170C128.718 170 0 182.76 0 198.5C0 214.24 128.718 227 287.5 227Z" fill={fillColor} />
            <path d="M196.822 190.016L200.721 190.015L202.576 175.028L196.821 175.029L196.822 190.016Z" fill="#FFB7B7" />
            <path d="M195.827 188.747L203.506 188.747H203.506C204.804 188.747 206.048 189.261 206.966 190.175C207.883 191.09 208.399 192.33 208.399 193.623V193.782L195.827 193.782L195.827 188.747Z" fill="#2F2E41" />
            <path d="M170.108 190.016L174.007 190.015L175.862 175.028L170.107 175.029L170.108 190.016Z" fill="#FFB7B7" />
            <path d="M169.113 188.747L176.792 188.747H176.792C178.09 188.747 179.334 189.261 180.252 190.175C181.17 191.09 181.685 192.33 181.685 193.623V193.782L169.114 193.782L169.113 188.747Z" fill="#2F2E41" />
            <path d="M301.927 118.588C297.555 112.757 289.553 111.35 285.577 114.311C281.601 117.272 280.688 125.318 285.06 131.149C289.106 136.546 296.438 138.018 300.476 135.996C300.463 136.059 300.463 136.124 300.474 136.187L300.702 137.309C300.719 137.394 300.758 137.474 300.815 137.54C300.872 137.607 300.945 137.657 301.027 137.687C301.109 137.718 301.198 137.727 301.284 137.713C301.371 137.7 301.452 137.664 301.521 137.611L303.449 136.11C303.518 136.056 303.572 135.986 303.606 135.905C303.64 135.825 303.653 135.737 303.644 135.65C303.634 135.564 303.602 135.481 303.552 135.41C303.501 135.339 303.433 135.282 303.354 135.244L302.317 134.753C302.28 134.736 302.242 134.724 302.203 134.717C305.315 131.437 305.983 123.996 301.927 118.588Z" fill="#DFEAE0" />
            <path d="M292.365 116.386C294.503 116.725 296.43 117.869 297.747 119.582C299.063 121.295 299.67 123.447 299.441 125.592C299.394 126.084 300.165 126.081 300.212 125.592C300.432 123.28 299.767 120.969 298.349 119.124C296.932 117.28 294.867 116.037 292.569 115.646C292.084 115.564 291.876 116.304 292.365 116.386Z" fill="white" />
            <path d="M309.054 17.801C312.876 11.5986 311.233 3.66845 307.003 1.07968C302.773 -1.50908 294.933 0.61681 291.111 6.8192C287.573 12.5603 288.908 19.8939 292.285 22.887C292.222 22.8982 292.161 22.9215 292.106 22.9556L291.144 23.5807C291.07 23.6284 291.011 23.6939 290.97 23.7711C290.929 23.8483 290.909 23.9346 290.911 24.0219C290.913 24.1091 290.937 24.1943 290.982 24.2694C291.026 24.3446 291.089 24.4072 291.165 24.4512L293.277 25.6823C293.352 25.7264 293.438 25.7504 293.525 25.7523C293.613 25.7541 293.7 25.7335 293.777 25.6926C293.854 25.6518 293.92 25.5918 293.967 25.5186C294.015 25.4453 294.043 25.3612 294.049 25.2741L294.124 24.1321C294.126 24.0923 294.123 24.0524 294.115 24.0133C298.323 25.6847 305.508 23.5545 309.054 17.801Z" fill="#DFEAE0" />
            <path d="M307.574 8.13195C308.049 10.2376 307.693 12.4443 306.582 14.2965C305.471 16.1488 303.689 17.5052 301.603 18.0859C301.128 18.2239 301.415 18.9364 301.888 18.7993C304.126 18.1492 306.035 16.6798 307.232 14.6859C308.428 12.692 308.824 10.3209 308.34 8.04819C308.238 7.56876 307.471 7.64897 307.574 8.13195Z" fill="white" />
            <path d="M241.316 47.2301C240.996 39.9583 235.178 34.3061 230.219 34.5226C225.26 34.7391 219.96 40.8766 220.279 48.1484C220.575 54.8792 225.803 60.2091 230.283 60.8036C230.237 60.8484 230.199 60.9017 230.173 60.9606L229.727 62.0154C229.694 62.0958 229.681 62.1835 229.69 62.2703C229.7 62.357 229.732 62.4398 229.782 62.5108C229.833 62.5819 229.901 62.6389 229.981 62.6763C230.06 62.7137 230.147 62.7304 230.234 62.7247L232.676 62.5658C232.763 62.5601 232.847 62.5322 232.921 62.4849C232.995 62.4375 233.055 62.3722 233.096 62.2952C233.137 62.2181 233.157 62.1319 233.156 62.0447C233.154 61.9574 233.13 61.8721 233.086 61.7968L232.506 60.8087C232.485 60.7747 232.46 60.7433 232.432 60.7152C236.857 59.751 241.612 53.9756 241.316 47.2301Z" fill={fillColor} />
            <path d="M234.656 40.0448C236.232 41.5244 237.178 43.5507 237.299 45.7053C237.419 47.86 236.705 49.9785 235.304 51.6237C234.987 52.0036 235.626 52.4333 235.94 52.0559C237.429 50.268 238.185 47.9852 238.055 45.6654C237.926 43.3457 236.921 41.1604 235.243 39.5478C234.889 39.2079 234.299 39.7023 234.656 40.0448Z" fill="white" />
            <path d="M230.771 63.1861C230.585 65.0858 229.951 66.9146 228.919 68.5225C227.887 70.1305 226.487 71.4718 224.834 72.4366C224.366 72.7068 223.849 73.0628 223.292 72.8456C223.078 72.7693 222.896 72.6229 222.776 72.4303C222.657 72.2377 222.606 72.0103 222.633 71.7853C222.724 71.2043 223.31 70.8473 223.861 70.7942C224.486 70.7339 224.96 71.1883 225.173 71.7365C225.692 73.0762 224.773 74.3785 224.039 75.4303C222.366 77.8268 220.837 80.4734 220.554 83.4368C220.292 86.1787 221.272 89.1619 223.611 90.7718C224.02 91.0535 224.429 90.4028 224.022 90.1225C221.703 88.5259 220.957 85.4329 221.415 82.7813C221.721 81.2023 222.295 79.6871 223.115 78.3014C223.521 77.5822 223.968 76.8865 224.436 76.2058C224.923 75.5595 225.351 74.8714 225.715 74.1497C226.261 72.944 226.277 71.3238 225.146 70.43C224.091 69.5961 222.267 70.1504 221.917 71.4729C221.849 71.7633 221.854 72.066 221.933 72.3538C222.011 72.6416 222.16 72.9055 222.366 73.1219C222.572 73.3382 222.828 73.5003 223.112 73.5936C223.397 73.6868 223.7 73.7083 223.994 73.6561C224.636 73.5449 225.277 73.0877 225.811 72.7352C227.42 71.676 228.77 70.2707 229.762 68.6226C230.753 66.9745 231.361 65.1257 231.541 63.2123C231.583 62.7196 230.813 62.6946 230.771 63.186L230.771 63.1861Z" fill="#CCCCCC" />
            <path d="M338.037 42.5757C340.146 41.5979 342.736 41.2773 344.882 42.1859C347.028 43.0945 348.491 45.4235 347.797 47.4846C347.482 48.419 346.766 49.3381 347.045 50.2818C347.26 51.0083 348.009 51.4879 348.745 51.8289C349.482 52.1699 350.287 52.4566 350.819 53.0318C351.352 53.607 351.463 54.5971 350.798 55.0432C350.579 55.1901 350.305 55.2598 350.092 55.4147C349.911 55.5613 349.781 55.7606 349.718 55.9847C349.656 56.2089 349.666 56.4467 349.746 56.6651C349.923 57.1046 350.216 57.4883 350.593 57.7759C351.348 58.4248 352.346 59.1675 352.112 60.0886C352.025 60.3348 351.88 60.5564 351.688 60.7347C351.497 60.913 351.265 61.0427 351.013 61.1127C350.489 61.2633 349.946 61.3348 349.4 61.3248L327.122 62.0243C326.386 62.0793 325.647 62.0382 324.922 61.9018C324.57 61.8378 324.235 61.702 323.937 61.503C323.64 61.304 323.387 61.0461 323.194 60.7453C322.768 59.9583 323.048 58.9889 323.575 58.254C324.102 57.5191 324.847 56.9394 325.463 56.2646C326.08 55.5898 326.584 54.7475 326.475 53.8753C326.388 53.1772 325.926 52.5738 325.594 51.9394C325.263 51.3051 325.07 50.5124 325.5 49.9288C326.105 49.1064 327.558 49.1808 328.247 48.4145C328.766 47.8363 328.666 47.0038 328.717 46.2581C328.842 44.4643 330.088 42.7703 331.862 41.9837C332.764 41.5978 333.745 41.4312 334.724 41.4977C335.704 41.5641 336.653 41.8617 337.494 42.366L338.037 42.5757Z" fill="#2F2E41" />
            <path d="M365.168 131.342V134.973L351.115 136.701L351.116 131.342L365.168 131.342Z" fill="#9E616A" />
            <path d="M368.7 130.416L368.7 142.124H368.551C367.338 142.124 366.175 141.644 365.318 140.789C364.46 139.934 363.979 138.775 363.979 137.567V137.567L363.979 130.416L368.7 130.416Z" fill="#2F2E41" />
            <path d="M347.796 137.519L346.901 141.038L332.853 139.275L334.173 134.08L347.796 137.519Z" fill="#9E616A" />
            <path d="M351.448 137.485L348.564 148.835L348.42 148.798C347.244 148.501 346.235 147.752 345.614 146.713C344.994 145.675 344.812 144.434 345.11 143.262L345.11 143.262L346.871 136.33L351.448 137.485Z" fill="#2F2E41" />
            <path d="M344.208 96.7998L344.967 97.5876C344.967 97.5876 349.918 101.457 346.255 111.649L340.262 128.854L360.348 129.634L359.918 137.496C359.918 137.496 330.789 139.111 331.044 134.326C331.298 129.542 332.373 111.828 332.373 111.828L326.321 106.985L327.78 98.793L344.208 96.7998Z" fill="#2F2E41" />
            <path d="M328.648 104.607L326.321 106.985L318.519 128.358C318.519 128.358 317.33 133.689 320.005 134.873C322.68 136.058 339.889 142.685 339.889 142.685L341.647 136.382L328.386 128.45L334.567 120.361L335.497 107.613L328.648 104.607Z" fill="#2F2E41" />
            <path d="M328.772 66.9041L348.575 62.958L349.006 73.7855L343.876 84.6814L344.967 97.5882L327.219 100.678L327.775 83.707C327.775 83.707 323.009 77.0062 325.816 70.2522L328.772 66.9041Z" fill="#E4E4E4" />
            <path d="M372.93 41.2186C373.029 41.6731 373.027 42.1437 372.924 42.5974C372.821 43.0511 372.619 43.4767 372.333 43.8443C372.047 44.2119 371.684 44.5126 371.269 44.7251C370.854 44.9377 370.397 45.0569 369.93 45.0744L350.94 67.0195L346.525 61.6796L366.58 41.7875C366.603 41.0047 366.912 40.2573 367.451 39.6869C367.989 39.1165 368.719 38.7627 369.501 38.6925C370.284 38.6224 371.065 38.8408 371.697 39.3064C372.329 39.772 372.767 40.4523 372.93 41.2186Z" fill="#9E616A" />
            <path d="M355.076 64.6251C355.08 64.8301 355.04 65.0336 354.959 65.2218C354.877 65.4099 354.756 65.5783 354.603 65.7155L349.263 70.5059C348.593 71.3144 347.628 71.825 346.581 71.9259C345.533 72.0267 344.488 71.7096 343.675 71.044C342.862 70.3783 342.347 69.4185 342.242 68.3749C342.138 67.3313 342.453 66.2891 343.119 65.4767L346.846 59.3285C346.952 59.153 347.095 59.0025 347.265 58.8871C347.435 58.7717 347.628 58.6942 347.831 58.6598C348.034 58.6255 348.242 58.6351 348.441 58.688C348.639 58.7409 348.824 58.8359 348.983 58.9665L354.556 63.5558C354.715 63.6863 354.844 63.8494 354.933 64.0339C355.023 64.2185 355.071 64.4201 355.076 64.6251Z" fill="#E4E4E4" />
            <path d="M297.721 92.7909C297.692 92.3267 297.764 91.8617 297.935 91.4286C298.105 90.9955 298.368 90.6048 298.706 90.2841C299.044 89.9634 299.449 89.7205 299.891 89.5724C300.334 89.4244 300.803 89.3748 301.267 89.4272L323.346 70.5703L326.907 76.5099L304.084 93.1779C303.944 93.9485 303.526 94.6412 302.908 95.1246C302.289 95.6081 301.515 95.8487 300.73 95.801C299.946 95.7533 299.207 95.4206 298.652 94.8658C298.097 94.311 297.766 93.5728 297.721 92.7909V92.7909Z" fill="#9E616A" />
            <path d="M318.898 72.3201C318.924 72.1168 318.994 71.9216 319.103 71.7477C319.212 71.5739 319.358 71.4256 319.53 71.3129L325.53 67.3752C326.315 66.6759 327.345 66.3152 328.396 66.372C329.447 66.4289 330.432 66.8987 331.136 67.6785C331.84 68.4583 332.205 69.4843 332.151 70.5318C332.097 71.5792 331.628 72.5626 330.848 73.2662L326.237 78.7875C326.105 78.945 325.941 79.0725 325.756 79.1611C325.57 79.2498 325.368 79.2976 325.162 79.3012C324.956 79.3048 324.752 79.2643 324.564 79.1822C324.375 79.1002 324.207 78.9786 324.07 78.8258L319.25 73.455C319.113 73.3023 319.01 73.1218 318.95 72.9259C318.889 72.7301 318.871 72.5234 318.898 72.3201Z" fill="#E4E4E4" />
            <path d="M337.213 61.8519C341.244 61.8519 344.512 58.5952 344.512 54.5778C344.512 50.5604 341.244 47.3037 337.213 47.3037C333.182 47.3037 329.914 50.5604 329.914 54.5778C329.914 58.5952 333.182 61.8519 337.213 61.8519Z" fill="#9E616A" />
            <path d="M344.68 53.5436C342.375 53.3591 340.461 51.0643 340.7 48.7721C340.727 49.7169 340.405 50.6386 339.795 51.362C339.184 52.0853 338.328 52.5597 337.389 52.695C336.332 52.8111 335.173 52.4923 334.253 53.0239C333.233 53.6127 332.907 55.0425 331.845 55.5511C330.819 56.0424 329.516 55.3723 329.009 54.3565C328.502 53.3408 328.626 52.1204 328.962 51.0365C329.442 49.4907 330.314 48.0948 331.495 46.9846C332.675 45.8743 334.124 45.0875 335.701 44.7006C337.277 44.3138 338.927 44.3401 340.49 44.777C342.053 45.2138 343.476 46.0464 344.621 47.1937C345.59 48.1653 346.36 49.4036 346.485 50.7679C346.609 52.1323 345.974 53.6127 344.745 54.2256L344.68 53.5436Z" fill="#2F2E41" />
            <path d="M217.092 81.9041C217.053 82.0751 217.028 82.2489 217.016 82.4239L203.355 90.2781L200.034 88.373L196.494 92.9915L202.044 96.9333C202.492 97.2518 203.032 97.4167 203.582 97.4033C204.133 97.39 204.664 97.1991 205.096 96.8592L219.184 85.7838C219.819 86.0183 220.512 86.0544 221.168 85.8872C221.825 85.72 222.415 85.3575 222.861 84.8479C223.306 84.3383 223.584 83.7058 223.66 83.0344C223.735 82.363 223.604 81.6846 223.283 81.0894C222.962 80.4943 222.467 80.0106 221.864 79.7026C221.261 79.3947 220.577 79.2771 219.905 79.3655C219.233 79.454 218.604 79.7442 218.102 80.1977C217.599 80.6512 217.247 81.2464 217.092 81.9041H217.092Z" fill="#FFB7B7" />
            <path d="M199.37 75.1894C198.037 73.4688 196.154 71.8767 196.035 69.7535C195.926 67.8061 197.383 65.8727 196.768 64.0096C196.296 62.5771 194.737 61.7128 193.214 61.2551C190.297 60.3914 187.177 60.5111 184.336 61.5957L184.49 61.4873C182.756 60.8718 180.729 61.5072 179.365 62.6842C178.001 63.8611 177.19 65.4924 176.561 67.1272C175.932 68.762 175.444 70.4565 174.57 71.9892C173.696 73.5218 172.358 74.9156 170.592 75.4453C171.183 76.6204 173.031 77.0236 174.115 76.2138C174.392 75.9897 174.641 75.7333 174.856 75.4501C175.579 74.5741 176.302 73.6981 177.025 72.8221C177.769 74.1405 176.658 75.9601 177.572 77.1806C178.043 77.81 178.904 78.049 179.704 78.2205C185.954 79.5496 192.438 79.3099 198.573 77.523C199.066 77.3782 199.61 77.1788 199.814 76.7341C200.05 76.2207 199.72 75.6419 199.37 75.1894Z" fill="#2F2E41" />
            <path d="M194.481 73.0707C194.887 69.3842 192.217 66.0677 188.518 65.663C184.819 65.2583 181.491 67.9187 181.085 71.6053C180.679 75.2918 183.348 78.6083 187.048 79.013C190.747 79.4177 194.075 76.7572 194.481 73.0707Z" fill="#FFB7B7" />
            <path d="M194.844 67.973C194.581 66.7859 193.729 65.8025 192.746 65.0822C191.127 63.8948 189.14 63.3112 187.133 63.4328C185.126 63.5544 183.226 64.3736 181.763 65.7478C180.945 66.4675 180.355 67.4091 180.066 68.4573C179.928 68.9813 179.921 69.5309 180.045 70.0583C180.169 70.5857 180.42 71.0749 180.777 71.4834L180.912 71.5675C180.79 71.0515 181.192 70.5359 181.672 70.3083C182.172 70.1255 182.693 70.009 183.224 69.9616C184.026 69.8106 184.786 69.4897 185.453 69.0206C186.12 68.5516 186.678 67.9454 187.09 67.2429C187.395 68.1075 187.882 68.897 188.519 69.5582C189.155 70.2195 189.927 70.7373 190.781 71.0767C191.672 71.4237 194.118 71.901 194.966 71.4603C196.047 70.8983 195.107 69.16 194.844 67.973Z" fill="#2F2E41" />
            <path d="M202.898 90.6499L198.483 96.3528C198.357 96.515 198.198 96.6484 198.016 96.7438C197.834 96.8392 197.633 96.8944 197.428 96.9056C197.222 96.9168 197.017 96.8838 196.826 96.8088C196.634 96.7337 196.461 96.6185 196.319 96.4709L191.32 91.2982C190.491 90.653 189.952 89.7062 189.822 88.6656C189.691 87.6249 189.981 86.5753 190.626 85.7469C191.271 84.9185 192.22 84.379 193.264 84.2466C194.308 84.1141 195.362 84.3997 196.194 85.0406L202.464 88.5341C202.643 88.6342 202.799 88.7716 202.92 88.9371C203.042 89.1025 203.126 89.2921 203.167 89.4929C203.208 89.6937 203.205 89.901 203.159 90.1007C203.113 90.3003 203.023 90.4876 202.898 90.6499Z" fill={fillColor} />
            <path d="M191.725 82.4043L195.695 84.3641C195.695 84.3641 200.13 92.0456 197.586 99.3351C196.351 102.874 199.829 114.156 199.829 114.156C199.829 114.156 205.218 165.257 205.235 181.028C198.54 182.054 192.18 183.322 191.861 180.153C191.543 176.983 188.062 139.51 188.062 139.51C188.062 139.51 183.275 181.104 179.459 181.737C175.642 182.371 168.01 181.421 168.01 181.421C168.01 181.421 174.069 120.494 178.204 116.691L178.84 113.522L178.823 85.0731L183.586 82.8078L191.725 82.4043Z" fill="#2F2E41" />
            <path d="M177.525 125.593C177.964 125.357 178.346 125.027 178.643 124.627C178.941 124.227 179.147 123.767 179.246 123.279C179.346 122.792 179.337 122.288 179.221 121.804C179.104 121.32 178.882 120.867 178.571 120.478L183.188 89.811L177.156 89.5225L173.558 120.063C172.936 120.627 172.544 121.399 172.455 122.232C172.366 123.065 172.587 123.902 173.076 124.584C173.565 125.266 174.288 125.745 175.108 125.931C175.928 126.117 176.788 125.997 177.525 125.593Z" fill="#FFB7B7" />
            <path d="M183.056 97.4948L175.843 97.0409C175.638 97.028 175.438 96.9712 175.256 96.8742C175.075 96.7773 174.917 96.6427 174.793 96.4794C174.669 96.3161 174.581 96.128 174.536 95.9279C174.491 95.7279 174.491 95.5206 174.533 95.3202L176.036 88.2968C176.109 87.2506 176.594 86.2756 177.387 85.5856C178.179 84.8956 179.214 84.5469 180.264 84.616C181.314 84.6852 182.293 85.1665 182.988 85.9544C183.682 86.7423 184.035 87.7725 183.968 88.8191L184.573 95.9518C184.59 96.156 184.563 96.3616 184.493 96.5544C184.423 96.7472 184.313 96.9229 184.169 97.0693C184.025 97.2157 183.851 97.3295 183.659 97.4029C183.467 97.4764 183.262 97.5077 183.056 97.4948H183.056Z" fill={fillColor} />
            <path d="M468.484 187.345L464.886 187.345L463.175 173.477L468.485 173.477L468.484 187.345Z" fill="#9E616A" />
            <path d="M469.402 190.83L457.801 190.83V190.683C457.801 189.487 458.277 188.339 459.123 187.493C459.97 186.646 461.119 186.171 462.316 186.171H462.316L469.402 186.171L469.402 190.83Z" fill="#2F2E41" />
            <path d="M455.572 187.345L451.974 187.345L450.263 173.477L455.573 173.477L455.572 187.345Z" fill="#9E616A" />
            <path d="M456.489 190.83L444.889 190.83V190.683C444.889 189.487 445.365 188.339 446.211 187.493C447.058 186.646 448.207 186.171 449.404 186.171H449.404L456.49 186.171L456.489 190.83Z" fill="#2F2E41" />
            <path d="M413.67 90.1685C413.957 90.5637 414.328 90.8911 414.756 91.1276C415.183 91.3641 415.658 91.504 416.146 91.5374C416.634 91.5708 417.123 91.4969 417.579 91.3209C418.035 91.145 418.447 90.8712 418.786 90.5189L425.905 93.2971L428.649 89.3855L418.541 85.6033C417.911 85.0737 417.108 84.7935 416.286 84.8157C415.463 84.8379 414.676 85.1609 414.076 85.7237C413.476 86.2864 413.103 87.0497 413.028 87.8689C412.953 88.6881 413.181 89.5064 413.67 90.1686V90.1685Z" fill="#9E616A" />
            <path d="M455.526 113.868L446.153 116.924L449.284 183.26L456.518 183.439L460.22 136.658L461.061 183.254L470.006 183.747L473.303 113.868H455.526Z" fill="#2F2E41" />
            <path d="M449.202 74.7635L473.259 69.54L475.934 90.2406L473.706 100.131L474.599 115.163C474.599 115.163 455.577 124.624 446.153 116.923C446.153 116.923 453.727 97.0427 449.337 87.7361L449.202 74.7635Z" fill="#E4E4E4" />
            <path d="M453.368 77.6909L449.202 74.7629C449.202 74.7629 448.956 74.4223 445.484 78.4784C442.012 82.5346 434.969 88.9865 434.969 88.9865L423.23 84.5875L421.092 92.4838L434.734 98.8372L454.049 85.9464L453.368 77.6909Z" fill="#E4E4E4" />
            <path d="M474.604 122.922C475.046 122.713 475.437 122.41 475.749 122.034C476.061 121.658 476.287 121.219 476.411 120.746C476.534 120.273 476.553 119.779 476.465 119.298C476.377 118.818 476.184 118.362 475.901 117.964L479.959 111.492L476.624 108.068L471.023 117.289C470.385 117.809 469.96 118.544 469.828 119.356C469.697 120.168 469.868 121.001 470.309 121.695C470.751 122.389 471.432 122.898 472.223 123.124C473.015 123.35 473.862 123.278 474.604 122.922Z" fill="#9E616A" />
            <path d="M469.507 73.618L473.259 69.5401C473.259 69.5401 477.44 68.0851 480.856 75.6034C484.271 83.1217 488.086 98.3718 488.086 98.3718L479.167 115.542L472.239 112.449L479.167 98.2506L471.836 87.7361L469.507 73.618Z" fill="#E4E4E4" />
            <path d="M463.144 67.8423C466.928 65.775 468.319 61.0335 466.25 57.2518C464.182 53.4701 459.437 52.0802 455.653 54.1475C451.868 56.2148 450.478 60.9563 452.546 64.738C454.615 68.5197 459.36 69.9096 463.144 67.8423Z" fill="#9E616A" />
            <path d="M465.984 54.0163C465.614 52.4667 464.322 51.2436 462.835 50.67C461.347 50.0965 459.691 50.102 458.12 50.3689C455.511 50.8118 453.076 51.936 450.676 53.0488L452.526 53.9333L449.973 54.0796L451.945 55.0991C451.419 55.4997 451.018 56.043 450.791 56.6643C450.564 57.2857 450.52 57.9592 450.664 58.6048C450.765 59.0273 451.037 59.4929 451.471 59.5075C451.997 59.5251 452.271 58.9133 452.639 58.538C453.128 58.0388 453.9 57.928 454.593 58.022C455.286 58.1161 455.943 58.3825 456.627 58.5298C457.491 58.7098 458.384 58.6999 459.244 58.5007C460.104 58.3016 460.911 57.918 461.608 57.3765L464.068 63.2959C464.044 63.0726 464.073 62.847 464.152 62.6367C464.23 62.4265 464.356 62.2371 464.52 62.0836C464.684 61.93 464.881 61.8163 465.096 61.7514C465.311 61.6865 465.538 61.6722 465.759 61.7095C465.981 61.7468 466.191 61.8348 466.372 61.9666C466.554 62.0983 466.703 62.2703 466.807 62.469C466.912 62.6678 466.969 62.8879 466.974 63.1123C466.979 63.3367 466.932 63.5592 466.837 63.7625C467.902 62.1574 468.758 60.3338 468.788 58.4082C468.817 56.4826 467.861 54.4541 466.112 53.6461" fill="#2F2E41" />
            <path d="M437.391 46.1856C437.391 33.8665 428.797 23.8799 418.196 23.8799C407.594 23.8799 399 33.8665 399 46.1856C399 57.3585 406.069 66.6125 415.294 68.2379V70.34C415.294 70.7371 415.452 71.1179 415.733 71.3987C416.014 71.6795 416.395 71.8372 416.792 71.8373H419.599C419.996 71.8372 420.377 71.6795 420.658 71.3987C420.939 71.1179 421.097 70.7371 421.097 70.34V68.2379C430.322 66.6125 437.391 57.3586 437.391 46.1856Z" fill={fillColor} />
            <path d="M414.832 70.037C415.433 69.4733 416.148 69.0462 416.93 68.785C417.711 68.5238 418.54 68.4347 419.359 68.5238C420.184 68.6194 420.976 68.9053 421.673 69.3589C422.369 69.8126 422.95 70.4215 423.37 71.1379C423.791 71.833 424.055 72.6112 424.144 73.4185C424.234 74.2258 424.147 75.0429 423.888 75.8131C423.384 77.3492 422.311 78.6275 421.212 79.7796C420.058 80.9883 418.804 82.103 417.719 83.3769C415.931 85.4899 414.682 88.0048 414.08 90.706C413.478 93.4072 413.541 96.2141 414.263 98.8858C415.214 102.272 417.179 105.287 419.893 107.526C420.238 107.807 420.593 108.076 420.958 108.33C421.381 108.626 421.781 107.933 421.362 107.64C418.415 105.557 416.237 102.561 415.165 99.1162C414.352 96.4318 414.248 93.5829 414.863 90.8464C415.478 88.11 416.791 85.5791 418.674 83.4998C419.818 82.2377 421.099 81.1078 422.243 79.8452C423.338 78.6357 424.337 77.2712 424.765 75.6732C424.981 74.8608 425.035 74.0136 424.923 73.1803C424.81 72.3471 424.534 71.5443 424.11 70.8181C423.688 70.0757 423.117 69.4285 422.433 68.917C421.749 68.4054 420.967 68.0406 420.135 67.8454C418.462 67.4819 416.712 67.7846 415.259 68.689C414.903 68.9161 414.57 69.1783 414.267 69.4718C413.895 69.8293 414.461 70.3941 414.832 70.037V70.037Z" fill="#3F3D56" />
            <path d="M483.644 37.6582L482.588 38.9826L478.852 36.7739L479.908 35.4492L483.644 37.6582Z" fill="#DFEAE0" />
            <path d="M487.542 43.3496L488.475 44.7412L484.55 46.6922L483.617 45.3007L487.542 43.3496Z" fill="#DFEAE0" />
            <path d="M491.605 37.2998L493.47 37.3656L493.285 41.2645L491.42 41.1987L491.605 37.2998Z" fill="#DFEAE0" />
            <path d="M451.917 18.209L450.861 19.5334L447.125 17.3247L448.181 16L451.917 18.209Z" fill="#DFEAE0" />
            <path d="M455.817 23.9004L456.75 25.292L452.825 27.243L451.892 25.8515L455.817 23.9004Z" fill="#DFEAE0" />
            <path d="M459.88 17.8506L461.745 17.9164L461.559 21.8153L459.694 21.7495L459.88 17.8506Z" fill="#DFEAE0" />
            <path d="M484.174 117.776L483.117 119.313L486.853 121.877L487.91 120.34L484.174 117.776Z" fill="#DFEAE0" />
            <path d="M487.883 129.21L488.816 130.825L492.741 128.561L491.808 126.945L487.883 129.21Z" fill="#DFEAE0" />
            <path d="M495.685 124.449L497.55 124.525L497.735 120L495.871 119.923L495.685 124.449Z" fill="#DFEAE0" />
            <path d="M546.051 193.044L550.362 193.044L552.413 176.426L546.05 176.426L546.051 193.044Z" fill="#FFB6B6" />
            <path d="M544.951 191.638L553.441 191.638H553.442C554.877 191.638 556.253 192.207 557.268 193.221C558.282 194.235 558.852 195.611 558.852 197.045V197.22L544.951 197.221L544.951 191.638Z" fill="#2F2E41" />
            <path d="M520.533 189.38L524.644 190.68L531.615 175.454L525.548 173.536L520.533 189.38Z" fill="#FFB6B6" />
            <path d="M519.909 187.708L528.004 190.267L528.005 190.267C529.373 190.699 530.513 191.657 531.174 192.93C531.836 194.203 531.964 195.686 531.531 197.053L531.478 197.221L518.225 193.031L519.909 187.708Z" fill="#2F2E41" />
            <path d="M530.241 117.148L527.848 123.891L527.781 160.294L520.996 185.218L529.324 187.54L538.524 164.04L541.475 143.398L545.363 164.16L544.828 188.837H553.566L554.226 150.809C554.411 140.13 552.541 129.514 548.718 119.539V119.539L530.241 117.148Z" fill="#2F2E41" />
            <path d="M527.392 89.834H541.516L547.417 95.7317L549.18 120.11L527.849 123.541L520.996 100.28L524.728 90.3946L527.392 89.834Z" fill="#E4E4E4" />
            <path d="M522.312 100.514L500.566 92.3372L500 88.9572L513.714 77.3604L518.279 80.7818L510.005 89.8349L524.727 90.3954L522.312 100.514Z" fill="#E4E4E4" />
            <path d="M524.507 83.0157L526.096 75.8552C526.374 74.5941 526.899 73.4002 527.639 72.3418C528.38 71.2834 529.322 70.3813 530.412 69.6869C531.501 68.9925 532.717 68.5196 533.99 68.2951C535.262 68.0706 536.567 68.099 537.828 68.3786C539.09 68.6582 540.284 69.1836 541.343 69.9247C542.401 70.6658 543.303 71.608 543.997 72.6976C544.69 73.7872 545.162 75.0028 545.386 76.2748C545.609 77.5468 545.58 78.8504 545.299 80.1109L543.709 87.2715C543.633 87.6131 543.425 87.9106 543.129 88.0986C542.834 88.2867 542.476 88.35 542.134 88.2746L525.51 84.5905C525.168 84.5143 524.871 84.3057 524.683 84.0105C524.494 83.7153 524.431 83.3575 524.507 83.0157Z" fill="#2F2E41" />
            <path d="M535.455 85.7156C539.28 84.608 541.482 80.6115 540.374 76.7891C539.265 72.9667 535.266 70.7659 531.441 71.8735C527.616 72.981 525.414 76.9776 526.522 80.8C527.631 84.6223 531.63 86.8231 535.455 85.7156Z" fill="#FFB6B6" />
            <path d="M523.698 75.9246C524.147 73.9128 525.376 72.161 527.115 71.0536C528.855 69.9463 530.963 69.5737 532.977 70.0177L534.41 70.3353C536.424 70.7839 538.176 72.0121 539.285 73.7506C540.393 75.4891 540.765 77.5959 540.321 79.6087L540.289 79.7519L537.261 79.0808L536.869 75.9613L536.021 78.806L534.456 78.4592L534.259 76.8853L533.831 78.3206L523.666 76.0678L523.698 75.9246Z" fill="#2F2E41" />
            <path d="M530.978 86.6473C530.879 86.4234 530.845 86.1759 530.88 85.9335C530.915 85.691 531.018 85.4633 531.176 85.2767C533.336 82.7267 536.645 77.8641 534.553 73.9493L534.403 73.668L542.596 75.4838L539.868 87.777L532.134 87.4399C532.056 87.4365 531.978 87.4263 531.901 87.4094C531.698 87.3644 531.508 87.2718 531.348 87.1393C531.187 87.0069 531.061 86.8382 530.978 86.6473Z" fill="#2F2E41" />
            <path d="M540.953 89.9824L563.956 93.251L565.242 96.4279L554.371 110.721L549.173 108.371L555.284 97.7398L540.791 100.384L540.953 89.9824Z" fill="#E4E4E4" />
            <path d="M575.392 43.643C575.392 28.9285 565.127 17 552.464 17C539.801 17 529.536 28.9285 529.536 43.643C529.536 56.9884 537.98 68.0418 548.998 69.9832V72.494C548.998 72.9683 549.187 73.4232 549.522 73.7586C549.858 74.094 550.313 74.2824 550.788 74.2824H554.14C554.615 74.2824 555.07 74.094 555.406 73.7586C555.741 73.4232 555.93 72.9683 555.93 72.494V69.9832C566.948 68.0418 575.392 56.9884 575.392 43.643Z" fill={fillColor} />
            <path d="M548.392 72.0757C549.12 71.392 549.988 70.8744 550.936 70.5589C551.884 70.2434 552.889 70.1375 553.881 70.2485C554.857 70.3633 555.793 70.6985 556.62 71.2286C557.446 71.7586 558.141 72.4697 558.652 73.308C559.172 74.1352 559.506 75.0652 559.632 76.034C559.758 77.0028 559.672 77.9873 559.381 78.9198C558.816 80.7503 557.574 82.2829 556.28 83.6566C554.899 85.1221 553.386 86.4622 552.066 87.9849C549.915 90.4841 548.402 93.4681 547.66 96.6805C546.917 99.8929 546.966 103.237 547.803 106.427C548.922 110.526 551.288 114.176 554.572 116.875C554.971 117.199 555.381 117.508 555.803 117.803C556.226 118.098 556.626 117.406 556.207 117.113C552.69 114.628 550.085 111.06 548.791 106.954C547.809 103.749 547.669 100.345 548.387 97.0712C549.104 93.7969 550.654 90.7628 552.886 88.2615C554.243 86.7478 555.772 85.3993 557.144 83.9002C558.458 82.4646 559.665 80.8528 560.205 78.9558C560.477 77.9977 560.556 76.9952 560.437 76.0064C560.319 75.0176 560.005 74.062 559.515 73.1951C559.027 72.3085 558.36 71.5323 557.558 70.9151C556.755 70.2979 555.834 69.8532 554.851 69.6086C552.854 69.1499 550.757 69.4975 549.015 70.5759C548.588 70.8459 548.189 71.1591 547.827 71.5105C547.456 71.8686 548.022 72.4334 548.392 72.0757Z" fill="#3F3D56" />
            <path d="M551.165 114.785C553.107 114.785 554.681 113.212 554.681 111.271C554.681 109.33 553.107 107.757 551.165 107.757C549.223 107.757 547.648 109.33 547.648 111.271C547.648 113.212 549.223 114.785 551.165 114.785Z" fill="#FFB6B6" />
            <path d="M516.516 82.0284C518.459 82.0284 520.033 80.455 520.033 78.5142C520.033 76.5734 518.459 75 516.516 75C514.574 75 513 76.5734 513 78.5142C513 80.455 514.574 82.0284 516.516 82.0284Z" fill="#FFB6B6" />
            <path d="M514.521 79.5755C514.336 79.8669 513.889 79.8246 513.638 79.6424C513.29 79.3894 513.403 78.9022 513.643 78.6144C514.405 77.6994 515.667 78.6654 515.837 79.574C515.9 80.0171 515.832 80.4689 515.64 80.8735C515.449 81.2781 515.143 81.6177 514.76 81.8502C513.89 82.3655 512.76 82.1071 512.012 81.4903C510.451 80.2038 510.876 77.6279 512.61 76.6972C513.716 76.1032 515.115 76.3639 516.204 76.8615C517.431 77.4216 518.486 78.2801 519.62 78.9957C522.11 80.5528 524.925 81.5137 527.848 81.8032C528.359 81.8553 528.356 81.0557 527.848 81.0039C526.228 80.8366 524.638 80.454 523.12 79.8664C521.618 79.2579 520.193 78.4759 518.872 77.5363C517.537 76.6214 516.141 75.7325 514.488 75.5813C513.821 75.5035 513.145 75.6035 512.529 75.8713C511.914 76.1391 511.38 76.5651 510.982 77.106C510.628 77.5899 510.391 78.1492 510.29 78.7399C510.189 79.3307 510.226 79.9368 510.398 80.5109C510.606 81.1112 510.969 81.6457 511.451 82.0601C511.932 82.4746 512.515 82.754 513.14 82.8702C513.742 82.9837 514.364 82.9069 514.92 82.6503C515.475 82.3937 515.937 81.9702 516.24 81.4388C516.849 80.3872 516.829 78.9746 515.91 78.1027C515.647 77.8351 515.317 77.6422 514.954 77.5437C514.592 77.4452 514.21 77.4446 513.847 77.542C513.513 77.6548 513.219 77.8625 513.001 78.1397C512.783 78.417 512.65 78.7516 512.62 79.1029C512.531 80.4679 514.47 81.1505 515.211 79.9789C515.487 79.5428 514.795 79.1414 514.521 79.5755Z" fill="#3F3D56" />
            <path d="M44.5915 40.9524C38.7181 27.4593 24.5436 20.6129 12.9319 25.6607C1.3201 30.7085 -3.33172 45.7389 2.54165 59.2321C7.86855 71.4698 20.0237 78.2397 30.9022 75.6278L31.9044 77.9302C32.0937 78.3651 32.4482 78.7071 32.8898 78.8808C33.3314 79.0546 33.8241 79.046 34.2593 78.8568L37.3336 77.5203C37.7688 77.3311 38.111 76.9769 38.2849 76.5355C38.4588 76.0942 38.4501 75.6019 38.2608 75.1669L37.2586 72.8646C46.5873 66.6921 49.9184 53.1901 44.5915 40.9524Z" fill={fillColor} />
            <path d="M31.1956 77.7486C31.5772 76.87 32.1392 76.0814 32.8453 75.4337C33.5514 74.7861 34.3857 74.2939 35.2943 73.989C36.1865 73.6888 37.1335 73.5871 38.0692 73.691C39.0048 73.7948 39.9064 74.1018 40.7109 74.5904C41.5397 75.0811 42.2542 75.7427 42.807 76.5311C43.3597 77.3195 43.738 78.2166 43.9164 79.1626C44.3264 81.3549 43.6612 83.588 42.9054 85.6291C42.1131 87.7688 41.1258 89.8468 40.5748 92.0688C39.8082 95.1916 39.7927 98.4516 40.5296 101.582C41.2666 104.712 42.735 107.623 44.8147 110.076C47.434 113.11 50.8836 115.311 54.739 116.41C55.212 116.543 55.6898 116.657 56.1714 116.755C56.6739 116.856 56.8889 116.086 56.384 115.984C52.3726 115.159 48.7169 113.108 45.9226 110.116C42.9604 106.915 41.1709 102.806 40.8462 98.4587C40.6723 96.256 40.8743 94.0399 41.4436 91.9048C42.0513 89.6519 43.08 87.5426 43.8556 85.3475C44.5956 83.2528 45.1201 81.0035 44.6551 78.7869C44.2276 76.8351 43.0425 75.1328 41.3601 74.0536C40.5537 73.5223 39.6498 73.1564 38.7007 72.9772C37.7516 72.798 36.7764 72.8091 35.8316 73.0099C33.9023 73.4466 32.212 74.6016 31.1045 76.2397C30.8352 76.6481 30.6073 77.0824 30.4243 77.5361C30.2277 78.0109 31.0012 78.2179 31.1956 77.7486L31.1956 77.7486Z" fill="#3F3D56" />
            <path d="M17.1147 133.418L16.0585 134.742L12.3223 132.534L13.3786 131.209L17.1147 133.418Z" fill="#DFEAE0" />
            <path d="M21.0138 139.108L21.9469 140.5L18.022 142.451L17.0889 141.059L21.0138 139.108Z" fill="#DFEAE0" />
            <path d="M25.0762 133.059L26.9408 133.124L26.7553 137.023L24.8906 136.958L25.0762 133.059Z" fill="#DFEAE0" />
            <path d="M61.105 27.9111L60.0487 29.2355L56.3125 27.0268L57.3688 25.7021L61.105 27.9111Z" fill="#DFEAE0" />
            <path d="M65.0031 33.6025L65.9362 34.9941L62.0112 36.9452L61.0781 35.5536L65.0031 33.6025Z" fill="#DFEAE0" />
            <path d="M69.0664 27.5527L70.931 27.6185L70.7455 31.5175L68.8809 31.4517L69.0664 27.5527Z" fill="#DFEAE0" />
            <path d="M86.0861 191.654L89.3161 191.155L87.1016 165.364L79.9033 166.696L86.0861 191.654Z" fill="#FFB6B6" />
            <path d="M85.0996 190.728L91.4609 189.744L91.4612 189.744C92.5363 189.578 93.6335 189.846 94.5112 190.488C95.389 191.13 95.9756 192.095 96.1419 193.169L96.1622 193.301L85.7469 194.91L85.0996 190.728Z" fill="#2F2E41" />
            <path d="M52.1377 191.335L55.4031 191.473L59.3749 168.562L52.1766 168.562L52.1377 191.335Z" fill="#FFB6B6" />
            <path d="M51.3501 190.233L57.7812 190.507H57.7815C58.8684 190.554 59.8924 191.029 60.6282 191.83C61.364 192.631 61.7514 193.691 61.7051 194.777L61.6995 194.911L51.1699 194.462L51.3501 190.233Z" fill="#2F2E41" />
            <path d="M58.567 116.686C58.4129 116.642 58.2551 116.613 58.0957 116.598L60.1315 99.9457L67.0389 85.3272C67.4735 84.4077 67.543 83.3576 67.2333 82.3889C66.9236 81.4201 66.2578 80.6048 65.3703 80.1073C64.4827 79.6098 63.4393 79.4672 62.4507 79.7082C61.4621 79.9493 60.6016 80.556 60.043 81.4061V81.4061C58.2326 84.1607 56.8632 87.1806 55.9841 90.357L53.082 100.843L55.2041 118.825C55.1227 119.332 55.1896 119.851 55.3967 120.321C55.6039 120.791 55.9424 121.191 56.3717 121.473C56.8009 121.755 57.3025 121.907 57.8162 121.91C58.3298 121.914 58.8336 121.769 59.2669 121.494C59.7002 121.218 60.0446 120.823 60.2586 120.357C60.4726 119.89 60.5471 119.371 60.4732 118.863C60.3992 118.355 60.18 117.88 59.8418 117.493C59.5036 117.107 59.0609 116.826 58.567 116.686H58.567Z" fill="#FFB6B6" />
            <path d="M62.7612 101.356L54.0342 91.3787L58.3575 81.2166C58.9236 79.8859 59.9877 78.829 61.3227 78.2714C62.6578 77.7138 64.158 77.6997 65.5033 78.2322C66.8486 78.7646 67.9323 79.8014 68.5233 81.1212C69.1143 82.4411 69.1657 83.9395 68.6666 85.2968L62.7612 101.356Z" fill="#2F2E41" />
            <path d="M77.2684 69.5498C79.368 65.7114 77.9564 60.8988 74.1155 58.8006C70.2746 56.7024 65.4589 58.113 63.3593 61.9514C61.2597 65.7898 62.6713 70.6023 66.5122 72.7006C70.353 74.7988 75.1688 73.3881 77.2684 69.5498Z" fill="#FFB6B6" />
            <path d="M71.6388 56.1279H66.5189C63.1624 56.1279 60.4414 59.1871 60.4414 62.9607L60.6783 72.1137C64.3051 72.5646 67.8524 73.5128 71.2201 74.9314L71.698 72.892L72.6406 75.5779C73.479 75.9838 74.3102 76.4277 75.1343 76.9095C74.1725 72.3932 73.7766 67.8874 74.437 64.3873H76.1711V62.4908L76.7597 64.3873H79.9035V64.3872C79.9035 63.3026 79.6898 62.2286 79.2744 61.2265C78.8591 60.2245 78.2503 59.314 77.4829 58.547C76.7154 57.7801 75.8043 57.1717 74.8016 56.7566C73.7989 56.3416 72.7241 56.1279 71.6388 56.1279Z" fill="#2F2E41" />
            <path d="M88.3332 166.765L87.5914 166.944L86.9689 155.373L82.4366 120.737L77.2378 107.549L79.7706 98.3571C79.7706 98.3571 82.7032 91.9628 75.3716 87.0339L69.5063 77.176C65.4548 74.5286 62.1213 76.2596 60.3444 80.7595C58.796 84.6802 57.5941 89.6405 58.3089 94.7603C59.9102 106.229 61.2416 107.549 61.2416 107.549C61.2416 107.549 51.2439 118.606 53.3767 132.727L52.1881 169.287L51.1006 169.243L50.9814 172.147L60.4822 172.536L60.6013 169.632L59.8133 169.6L63.2411 155.107L68.3066 135.924L77.1045 159.37L80.0638 168.756L79.0888 168.99L79.7698 171.816L89.0141 169.591L88.3332 166.765Z" fill="#2F2E41" />
            <path d="M94.8614 101.113C94.7537 101.231 94.657 101.359 94.5723 101.495L80.5746 92.2345L70.5742 79.5273C69.9452 78.7279 69.0362 78.1967 68.0306 78.0407C67.025 77.8847 65.9976 78.1157 65.1556 78.6869C64.3137 79.2582 63.7199 80.1272 63.4939 81.1188C63.268 82.1104 63.4267 83.1507 63.9381 84.03C65.5952 86.8793 67.6868 89.453 70.1375 91.6582L78.2279 98.9379L95.2739 105.075C95.6913 105.374 96.1863 105.547 96.6994 105.571C97.2124 105.596 97.7217 105.472 98.1659 105.214C98.6101 104.957 98.9703 104.576 99.2032 104.118C99.436 103.661 99.5317 103.146 99.4785 102.635C99.4253 102.125 99.2257 101.64 98.9035 101.241C98.5814 100.841 98.1505 100.542 97.6627 100.382C97.1749 100.221 96.651 100.204 96.154 100.334C95.6571 100.464 95.2082 100.734 94.8614 101.113H94.8614Z" fill="#FFB6B6" />
            <path d="M83.0121 90.5139L70.1813 93.8585L63.0157 85.4527C62.0774 84.352 61.6066 82.9284 61.704 81.4857C61.8013 80.043 62.459 78.6954 63.5368 77.7306C64.6145 76.7658 66.0269 76.2601 67.4725 76.3214C68.9181 76.3827 70.2825 77.0063 71.2745 78.059L83.0121 90.5139Z" fill="#2F2E41" />
        </svg>
    )
}

export default Banner