import styled from 'styled-components'

import FluffyCard from '@gotamedia/fluffy/Card'

const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 15px 0 0;
`

const DescriptionName = styled(FluffyCard.Paragraph)`
    font-weight: 700;
    margin-bottom: 0;
    color: #2E2A25;
`

const DescriptionValue = styled(FluffyCard.Paragraph)`
    -webkit-line-clamp: 1;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
`

export {
    DescriptionWrapper,
    DescriptionName,
    DescriptionValue
}