{
    "barometern": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.barometern.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.barometern.se/minnas"
        }
    },
    "blt": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.blt.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.blt.se/minnas"
        }
    },
    "bt": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.bt.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.bt.se/minnas"
        }
    },
    "kristianstadsbladet": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.kristianstadsbladet.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.kristianstadsbladet.se/minnas"
        }
    },
    "nsk": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.nsk.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.nsk.se/minnas"
        }
    },
    "smp": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.smp.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.smp.se/minnas"
        }
    },
    "sydostran": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.sydostran.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.sydostran.se/minnas"
        }
    },
    "trelleborgsallehanda": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.trelleborgsallehanda.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.trelleborgsallehanda.se/minnas"
        }
    },
    "ut": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.ut.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.ut.se/minnas"
        }
    },
    "ystadsallehanda": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.ystadsallehanda.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.ystadsallehanda.se/minnas"
        }
    },
    "olandsbladet": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.olandsbladet.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.olandsbladet.se/minnas"
        }
    },
    "meraosterlen": {
        "celebrate": {
            "title": "Fira",
            "text": "Uppvakta någon på födelsedagen, berätta att ni gift er, tacka för uppvaktning eller meddela nytillskottet i familjen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/",
            "linkText": "Se firaannonser",
            "link": "https://familj.ystadsallehanda.se/fira"
        },
        "market": {
            "title": "Köp och sälj",
            "text": "Sälj din gamla bil, sök en ny bostad eller efterlys ett bortsprunget husdjur. Du hittar köp- och säljannonserna i den tryckta tidningen.",
            "buttonText": "Boka annons från 49 kr",
            "buttonLink": "https://privat-gota.cargoselfservice.com/login/"
        },
        "memorial": {
            "title": "Döds- och minnesannonser",
            "text": "För att sätta in en minnesannons eller ett sorgtack, fyll i formuläret nedan. För att sätta in en dödsannons hänvisar vi dig till din begravningsbyrå.",
            "buttonFormText": "Fyll i formulär",
            "buttonFormLink": "https://docs.google.com/forms/d/e/1FAIpQLSfRKPmwk_adMxpkqZhNh1q4moaFYgOPYpz4Gkn1aiU0dd0MFQ/viewform",
            "linkText": "Se döds- och minnesannonser",
            "link": "https://familj.ystadsallehanda.se/minnas"
        }
    }
}
