import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy/theme'
import FluffyList from '@gotamedia/fluffy/List'

const CardContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    width: 100%;
  
    ${themeHelpers.isSmallDevice(css`
        grid-gap: 10px;
    `)}
`

const DateStamp = styled.h2`
    color: ${({ theme }) => theme.colors.grey[2]};
    font-family: 'Sanomat Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;;
    margin-left: 5px;
    border-bottom: 2px solid;
    margin-top: 16px;
    margin-bottom: 8px;
    border-color: ${({ theme }) => theme.colors.grey[4]};
`

const CardsWrapper = styled(FluffyList)`
    width: 100%;
    display: flex;
    flex-direction: column;
`
const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`

export {
    Content, CardsWrapper,
    DateStamp,
    CardContent,
}
