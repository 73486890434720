import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'

import {
    useLoaderData,
    useSearchParams,
    useTransition
} from '@remix-run/react'

import Pagination, { PaginationSizes } from '@gotamedia/fluffy/Pagination'
import useScroll from '@gotamedia/fluffy/useScroll'

import useHasMatch from '~/hooks/useHasMatch'
import useViewSize from '~/hooks/useViewSize'

import FamilyCard from '~/components/Family/components/FamilyCard'
import NoResults from '~/components/NoResults'

import type { SelectItem } from '@gotamedia/fluffy/Select'
import type { MouseEventHandler } from 'react'
import type { FamilyAdsPayload } from '~/modules/family/types'
import * as Styled from './style'
import type * as Types from './types'

const FamilySearchPage: Types.FamilySearchPageComponent = (props) => {
    const {
        page,
        sorting,
        onSortingChange,
        onPaginationChange
    } = props

    const mounted = useRef(false)

    const scroll = useScroll()

    const { isSmall, isMedium } = useViewSize()

    const [searchParams] = useSearchParams()

    const transition = useTransition()
    const submitting = transition.state === 'submitting'

    const isMatchingFiraRoute = useHasMatch('routes/fira/index')
    const isMatchingMinnasRoute = useHasMatch('routes/minnas/index')

    const data = useLoaderData<Types.SearchResponse>()

    const payload = useMemo(() => {
        return isMatchingFiraRoute ? (
            data.payload.family
        ) : (
            isMatchingMinnasRoute ? (
                data.payload.memorial
            ) : (
                {} as FamilyAdsPayload
            )
        )
    }, [
        isMatchingFiraRoute,
        isMatchingMinnasRoute,
        data.payload.family,
        data.payload.memorial
    ])

    const [query, setQuery] = useState(searchParams.get('query') || '')
    const [items, setItems] = useState<FamilyAdsPayload['ads']>(payload?.ads || [])

    const totalHits = payload?.total_count || 0

    const famlyType = isMatchingMinnasRoute ? (
        'minnas'
    ) : (
        isMatchingFiraRoute ? (
            'fira'
        ) : (
            'UNKNOWN'
        )
    )

    useEffect(() => {
        if (payload?.ads) {
            setItems(payload.ads)
            
        } else {
            setItems([])
        }
    }, [payload?.ads])

    useEffect(() => {
        if (mounted.current) {
            scroll({
                position: {
                    top: 1
                }
            })
        }

        mounted.current = true
    }, [
        items,
        scroll
    ])

    useEffect(() => {
        const searchQuery = searchParams.get('query') || ''

        if (searchQuery !== query) {
            setQuery(searchQuery)
        }
    }, [query, searchParams])

    const handleOnSortingChange = useCallback((items: SelectItem[]) => {
        const selectedSorting = items.find(i => i.selected)?.id || 'desc'
        
        onSortingChange(selectedSorting)
    }, [onSortingChange])

    const handleOnPaginationClick = useCallback<MouseEventHandler>((event) => {
        event.stopPropagation()
        event.preventDefault()
    }, [])
    
    const totalPages = Math.ceil(totalHits / 24) || 0

    return (
        <Styled.Wrapper>
            <Styled.InnerWrapper>
                {
                    totalHits > 0 ? (
                        <Styled.HeaderWrapper>
                            {/* Temporarily disabled due to a bug with Laidback API where they always response with 1000 total hits */}
                            {/* <Styled.TotalHitsLabel>
                                {`${totalHits} ${totalHits > 1 ? 'annonser' : 'annons'}`}
                            </Styled.TotalHitsLabel> */}

                            <Styled.SelectWrapper>
                                <Styled.SortLabel>
                                    {'Visa'}
                                </Styled.SortLabel>

                                <Styled.SortSelect
                                    placeholder={'Select sort'}
                                    onChange={handleOnSortingChange}
                                    items={[
                                        {
                                            id: 'desc',
                                            text: 'Nyast först',
                                            label: 'Nyast först',
                                            selected: sorting === 'desc'
                                        },
                                        {
                                            id: 'asc',
                                            text: 'Äldst först',
                                            label: 'Äldst först',
                                            selected: sorting === 'asc'
                                        }
                                    ]}
                                />
                            </Styled.SelectWrapper>
                        </Styled.HeaderWrapper>
                    ) : (
                        null
                    )
                }
                
                {
                    items.length > 0 ? (
                        <Styled.Content>
                            {
                                items.map((ad, idx) => {
                                    return (
                                        <FamilyCard
                                            key={`${ad.uuid}-${idx}`}
                                            overflow={isMatchingMinnasRoute ? true : false}
                                            image={ad.main_image}
                                            path={`/${famlyType}/${ad.uuid}`}
                                            loading={submitting}
                                        />
                                    )
                                })
                            }
                        </Styled.Content>
                    ) : (
                        <NoResults />
                    )
                }

                {
                    totalPages > 1 ? (
                        <Styled.PaginationWrapper onClick={handleOnPaginationClick}>
                            <Pagination
                                disabled={!totalHits}
                                activePage={page}
                                onChange={onPaginationChange}
                                size={PaginationSizes.Small}
                                totalPages={totalPages}
                                visiblePages={isSmall() ? 5 : (isMedium() ? 10 : 15)}
                            />
                        </Styled.PaginationWrapper>   
                    ) : (
                        null
                    )
                }
            </Styled.InnerWrapper>
        </Styled.Wrapper>
    )
}

export default FamilySearchPage