import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
`

const InnerWrapper = styled.div`
    width: 100%;
    flex: 1;
    flex-direction: column;
    display: flex;
`

export {
    Wrapper,
    InnerWrapper
}
