import {
    forwardRef,
    useMemo
} from 'react'

import { AvailableApps } from '~/config/apps'

import useRouteData from '~/hooks/useRouteData'
import useViewSize from '~/hooks/useViewSize'
import useHasMatch from '~/hooks/useHasMatch'

import Search from '~/components/Search'
import AdBlockerInfo from '~/components/AdBlockerInfo'

import * as Styled from './style'
import type * as Types from './types'
import type { RootLoaderData } from '~/types'

const Header: Types.HeaderComponent = forwardRef((props, ref) => {
    const {
        onSearchChange
    } = props

    const { app } = useRouteData<RootLoaderData>('root')

    const isMatchingFiraRoute = useHasMatch('routes/fira/index')
    const isMatchingMinnasRoute = useHasMatch('routes/minnas/index')

    const { isSmall } = useViewSize()

    const info = useMemo(() => {
        const value = {
            headline: '',
            description: ''
        }

        switch (app.id) {
            case AvailableApps.Family:
                if (isMatchingMinnasRoute) {
                    value.headline = app.info.minnas!.headline
                    value.description = app.info.minnas!.description
                } else if (isMatchingFiraRoute) {
                    value.headline = app.info.fira!.headline
                    value.description = app.info.fira!.description
                } else {
                    value.headline = ''
                    value.description = ''
                }

                break

            default:
                value.headline = app.info.headline
                value.description = app.info.description
        }

        return value
    }, [app, isMatchingMinnasRoute, isMatchingFiraRoute])

    const searchContent = (
        <>
            <Search
                ref={ref}
                onSearchChange={onSearchChange}
            />

            {
                isMatchingFiraRoute || isMatchingMinnasRoute ? (
                    <AdBlockerInfo />
                ) : (
                    null
                )
            }
        </>
    )

    return (
        <Styled.Wrapper>
            <Styled.Content>
                <Styled.InnerWrapper>
                    <Styled.Headline>
                        {info.headline}
                    </Styled.Headline>

                    <Styled.Description>
                        {info.description}
                    </Styled.Description>

                    {
                        !isSmall() ? (
                            searchContent
                        ) : (
                            null
                        )
                    }

                </Styled.InnerWrapper>

                <Styled.Banner />
            </Styled.Content>

            {
                isSmall() ? (
                    searchContent
                ) : (
                    null
                )
            }
        </Styled.Wrapper>
    )
})

Header.displayName = 'Header'

export default Header