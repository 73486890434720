import styled, { css } from 'styled-components'
import Icon from '@gotamedia/fluffy/Icon'

import FluffyButton from '@gotamedia/fluffy/Button'

import { themeHelpers } from '@gotamedia/fluffy/theme'
import FluffyList from '@gotamedia/fluffy/List'

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const Title = styled.h1`
    font-size: 35px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.brand};
    text-align: center;
    margin: 30px 0;
`

const StyledIcon = styled(Icon)`
    margin-right: 8px;
`

const Text = styled.span`
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey[0]};
    text-align: center;
    margin: 30px 0;
    display: flex;
    justify-content: center;
`

const Info = styled.span`
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grey[0]};
    text-align: center;
    margin: 15px 0;
    display: flex;
    justify-content: center;
`

const Content = styled.div`
    display: flex;
    height: 100%;
    min-height: 100%;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.grey[5]};
    padding-bottom: 32px;
`

const CardContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
  
    ${themeHelpers.isSmallDevice(css`
        grid-gap: 10px;
    `)}
`

const FilterContainer = styled.div`
    display: flex; 
    flex-direction: row;
`
const SearchBarContainer = styled.div`
    display: flex; 
    flex-grow: 1;
    flex-direction: row;
`
const DatePickerContainer = styled.div`
    display: flex; 
    flex-direction: row;
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const Container = styled.div`
    max-width:1320px;
    width: 90%;
    margin: 0 auto;

    ${themeHelpers.isSmallDevice(css`
        width: 95%;
    `)}
`

const DateStamp = styled.h2`
    color: ${({ theme }) => theme.colors.grey[2]};
    font-family: 'Sanomat Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;;
    margin-left: 5px;
    padding-top: 32px;
    margin-bottom: 12px;
`

const CardsWrapper = styled(FluffyList)`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const LoadMoreButton = styled(FluffyButton)`
    margin: 30px auto 0 auto;
`

const PaginationWrapper = styled.div`
    margin: 20px auto;
`

export {
    InfoContainer,
    FilterContainer,
    SearchBarContainer,
    DatePickerContainer,
    Info,
    DateStamp,
    Wrapper,
    CardContent,
    Title,
    Text,
    Content,
    CardsWrapper,
    LoadMoreButton,
    PaginationWrapper,
    Container,
    StyledIcon
}