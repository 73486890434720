[
    {
        "name": "Administration"
    },
    {
        "name": "Bygg/Anläggning"
    },
    {
        "name": "Data/IT"
    },
    {
        "name": "Drift/Underhåll"
    },
    {
        "name": "Ekonomi/Finans/Försäkring"
    },
    {
        "name": "Forskning/Vetenskap"
    },
    {
        "name": "Försäljning/Handel"
    },
    {
        "name": "Förvaltning/Handläggning"
    },
    {
        "name": "Hotell/Restaurang/Turism"
    },
    {
        "name": "HR/Personal"
    },
    {
        "name": "Hälsa/Skönhet"
    },
    {
        "name": "Ingenjör"
    },
    {
        "name": "Inköp"
    },
    {
        "name": "Juridik"
    },
    {
        "name": "Kultur/Media/Design"
    },
    {
        "name": "Kyrkor/Trosamfund"
    },
    {
        "name": "Lantbruk/Skog/Miljö"
    },
    {
        "name": "Ledning/Chefer"
    },
    {
        "name": "Logistik/Transport/Lager"
    },
    {
        "name": "Marknad/Kommunikation"
    },
    {
        "name": "Pedagogiskt arbete/Utbildning"
    },
    {
        "name": "Praktik/Sommarjobb"
    },
    {
        "name": "Projektledning"
    },
    {
        "name": "Sjukvård/Omsorg"
    },
    {
        "name": "Säkerhet/Skydd"
    },
    {
        "name": "Teknik"
    },
    {
        "name": "Trainee/Exjobb/Sommarjobb"
    },
    {
        "name": "Verkstad/Industri"
    },
    {
        "name": "Övrigt"
    }
]