import { AvailableApps } from '~/config/apps'

import { AppSubType } from '~/services/resolvers/types'

import type { BannerSVG } from '~/components/Banner/banners/types'

// NOTE: Switch cases might look DRY and the path's size can be shorten!
// This is important so Remix can lazy bundle/load the required components!
// DON'T CHANGE
const getHeaderBanner = (appId: string, size: 'large' | 'small', appType?: AppSubType): BannerSVG | undefined => {
    switch(appId) {
        case AvailableApps.MarketGuide: {
            switch (size) {
                case 'large':
                    return require(`~/components/Banner/banners/large/MarketsBanner`).default
                
                case 'small':
                    return require(`~/components/Banner/banners/small/MarketsBanner`).default
            }

            break
        }

        case AvailableApps.Jobs: {
            switch (size) {
                case 'large':
                    return require(`~/components/Banner/banners/large/JobsBanner`).default
                
                case 'small':
                    return require(`~/components/Banner/banners/small/JobsBanner`).default
            }

            break
        }

        case AvailableApps.Family:
            switch (appType) {
                case AppSubType.Fira: {
                    switch (size) {
                        case 'large':
                            return require(`~/components/Banner/banners/large/FamilyFiraBanner`).default
                        
                        case 'small':
                            return require(`~/components/Banner/banners/small/FamilyFiraBanner`).default
                    }

                    break
                }

                case AppSubType.Minnas: {
                    switch (size) {
                        case 'large':
                            return require(`~/components/Banner/banners/large/FamilyMinnasBanner`).default
                        
                        case 'small':
                            return require(`~/components/Banner/banners/small/FamilyMinnasBanner`).default
                    }

                    break
                }
            }
    }
}

export default getHeaderBanner