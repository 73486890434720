import type { BannerSVG } from '../types'

const Banner: BannerSVG = (props) => {
    const {
        className,
        width = 126,
        height = 114,
        fillColor
    } = props

    return (
        <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" d="M63 113.472C97.7939 113.472 126 109.113 126 103.736C126 98.3589 97.7939 94 63 94C28.2061 94 0 98.3589 0 103.736C0 109.113 28.2061 113.472 63 113.472Z" fill={fillColor} />
            <path d="M93.5533 8.13456C94.0366 4.53969 91.6251 1.24273 89.1245 0.901307C86.6239 0.55988 83.4289 3.09132 82.9456 6.68619C82.4983 10.0137 84.6429 13.0993 86.832 13.7951C86.8048 13.813 86.7814 13.8357 86.763 13.8622L86.4467 14.3393C86.4226 14.3756 86.4085 14.4175 86.4056 14.4609C86.4028 14.5043 86.4113 14.5478 86.4304 14.5872C86.4496 14.6266 86.4787 14.6607 86.5149 14.6862C86.5512 14.7117 86.5934 14.7277 86.6376 14.7328L87.8733 14.8753C87.9175 14.8804 87.9623 14.8743 88.0032 14.8577C88.0442 14.8411 88.0801 14.8146 88.1075 14.7805C88.1348 14.7464 88.1528 14.706 88.1596 14.663C88.1664 14.6201 88.1619 14.5761 88.1465 14.5352L87.9439 13.9983C87.9365 13.9798 87.9268 13.9621 87.9151 13.9457C90.2147 13.8724 93.105 11.4693 93.5533 8.13456Z" fill={fillColor} />
            <path d="M90.9259 4.23737C91.6858 5.08381 92.0465 6.12034 91.9337 7.133C91.8209 8.14566 91.2431 9.05715 90.3196 9.67932C90.1103 9.8237 90.4324 10.0878 90.6403 9.94439C91.6248 9.26477 92.2396 8.28123 92.3614 7.19102C92.4831 6.1008 92.1029 4.98452 91.2969 4.06603C91.1267 3.87234 90.7544 4.04225 90.9259 4.23737Z" fill="white" />
            <path d="M86.9701 15.1218C86.7113 16.0365 86.2393 16.8768 85.5926 17.5739C84.9459 18.2709 84.143 18.8049 83.2498 19.132C82.9969 19.2231 82.7128 19.352 82.4594 19.1964C82.3615 19.1401 82.2856 19.0523 82.2441 18.9474C82.2025 18.8424 82.1979 18.7265 82.2309 18.6186C82.3269 18.3419 82.6448 18.2186 82.919 18.2412C83.23 18.2668 83.4214 18.5312 83.477 18.8187C83.6127 19.5211 83.0481 20.0784 82.5962 20.5291C81.5666 21.5561 80.5851 22.7184 80.1847 24.1458C79.8143 25.4665 80.0296 27.015 81.0305 28.0103C81.2056 28.1844 81.4632 27.9016 81.289 27.7283C80.2963 26.7413 80.2049 25.1596 80.6632 23.9004C80.9524 23.1535 81.3675 22.4616 81.8904 21.8547C82.1527 21.5381 82.4328 21.2365 82.7219 20.9442C83.0169 20.6703 83.2869 20.3709 83.5288 20.0493C83.9024 19.5065 84.0535 18.7139 83.5795 18.176C83.1372 17.6743 82.1966 17.7851 81.9085 18.4024C81.8496 18.5387 81.8255 18.6876 81.8384 18.8355C81.8513 18.9835 81.9007 19.126 81.9822 19.2502C82.0638 19.3744 82.1749 19.4764 82.3056 19.5472C82.4363 19.6179 82.5825 19.6552 82.7312 19.6556C83.055 19.6577 83.4085 19.4901 83.7007 19.3644C84.581 18.9872 85.3653 18.4175 85.9959 17.6972C86.6264 16.977 87.0872 16.1245 87.3442 15.2026C87.408 14.9647 87.0338 14.8846 86.9701 15.1218L86.9701 15.1218Z" fill="#CCCCCC" />
            <path d="M59.9905 24.0279C61.4023 23.3734 63.1356 23.1588 64.5722 23.767C66.0087 24.3752 66.988 25.9341 66.5235 27.3138C66.3129 27.9393 65.8333 28.5545 66.0202 29.1862C66.164 29.6725 66.6658 29.9935 67.1585 30.2218C67.6513 30.4501 68.1904 30.642 68.5467 31.027C68.9031 31.412 68.9774 32.0748 68.5322 32.3733C68.3856 32.4717 68.2023 32.5183 68.06 32.622C67.9388 32.7201 67.8514 32.8535 67.8098 33.0036C67.7683 33.1536 67.7748 33.3128 67.8284 33.459C67.9469 33.7532 68.1429 34.01 68.3955 34.2026C68.9009 34.6369 69.5688 35.134 69.4119 35.7506C69.3537 35.9154 69.2564 36.0638 69.1284 36.1831C69.0004 36.3024 68.8453 36.3892 68.6765 36.4361C68.3258 36.5369 67.9619 36.5848 67.5969 36.5781L52.6841 37.0463C52.1918 37.0831 51.6968 37.0556 51.2117 36.9643C50.9759 36.9215 50.7515 36.8306 50.5525 36.6974C50.3536 36.5642 50.1843 36.3915 50.0553 36.1902C49.7698 35.6634 49.9573 35.0145 50.3099 34.5226C50.6626 34.0307 51.1617 33.6426 51.5742 33.1909C51.9866 32.7392 52.324 32.1754 52.2513 31.5916C52.1931 31.1243 51.8839 30.7204 51.6618 30.2958C51.4398 29.8711 51.3109 29.3405 51.5984 28.9499C52.0036 28.3994 52.9764 28.4492 53.4371 27.9363C53.7848 27.5492 53.7176 26.992 53.7522 26.4928C53.8353 25.2921 54.6695 24.1582 55.8569 23.6316C56.4607 23.3733 57.1175 23.2618 57.773 23.3063C58.4286 23.3508 59.0641 23.55 59.6272 23.8875L59.9905 24.0279Z" fill="#2F2E41" />
            <path d="M78.152 83.4461V85.8766L68.7451 87.033L68.7453 83.4457L78.152 83.4461Z" fill="#9E616A" />
            <path d="M80.516 82.8262L80.5157 90.6629H80.4162C79.6045 90.6629 78.826 90.3415 78.252 89.7695C77.678 89.1974 77.3555 88.4216 77.3555 87.6126V87.6124L77.3557 82.826L80.516 82.8262Z" fill="#2F2E41" />
            <path d="M66.5232 87.5803L65.9244 89.9362L56.5205 88.7559L57.4043 85.2787L66.5232 87.5803Z" fill="#9E616A" />
            <path d="M68.9673 87.558L67.0367 95.1549L66.9402 95.1306C66.1533 94.9319 65.4778 94.4299 65.0623 93.735C64.6468 93.0401 64.5253 92.2091 64.7245 91.4249L64.7245 91.4247L65.9037 86.7847L68.9673 87.558Z" fill="#2F2E41" />
            <path d="M64.1213 60.3242L64.6292 60.8515C64.6292 60.8515 67.9436 63.4416 65.4914 70.2642L61.4801 81.7803L74.9253 82.3027L74.6372 87.565C74.6372 87.565 55.1389 88.6459 55.3094 85.4436C55.48 82.2412 56.1994 70.3839 56.1994 70.3839L52.1484 67.1418L53.1252 61.6584L64.1213 60.3242Z" fill="#2F2E41" />
            <path d="M53.7062 65.5504L52.1486 67.1419L46.9262 81.4482C46.9262 81.4482 46.1305 85.0166 47.9208 85.8096C49.7112 86.6026 61.231 91.0384 61.231 91.0384L62.4073 86.8192L53.5311 81.5099L57.6683 76.0956L58.2909 67.5621L53.7062 65.5504Z" fill="#2F2E41" />
            <path d="M53.7894 40.3127L67.045 37.6713L67.333 44.9189L63.8994 52.2124L64.6294 60.8519L52.7494 62.9204L53.1215 51.5602C53.1215 51.5602 49.9312 47.0749 51.8105 42.5539L53.7894 40.3127Z" fill="#E4E4E4" />
            <path d="M83.3471 23.1195C83.4136 23.4238 83.4123 23.7388 83.3433 24.0425C83.2743 24.3461 83.1394 24.631 82.948 24.8771C82.7566 25.1232 82.5134 25.3244 82.2355 25.4667C81.9576 25.609 81.6518 25.6888 81.3396 25.7005L68.6282 40.39L65.6729 36.8156L79.097 23.5003C79.112 22.9764 79.3193 22.4761 79.6797 22.0942C80.0401 21.7124 80.5285 21.4756 81.0524 21.4286C81.5762 21.3817 82.0992 21.5279 82.5221 21.8396C82.9451 22.1512 83.2386 22.6066 83.3471 23.1195V23.1195Z" fill="#9E616A" />
            <path d="M71.3955 38.7873C71.3985 38.9245 71.3718 39.0607 71.3172 39.1867C71.2625 39.3126 71.1812 39.4253 71.0789 39.5171L67.5046 42.7237C67.0559 43.2649 66.4102 43.6067 65.7091 43.6742C65.0079 43.7417 64.3085 43.5294 63.7641 43.0839C63.2198 42.6383 62.8749 41.9959 62.8051 41.2973C62.7353 40.5987 62.9463 39.9011 63.3918 39.3573L65.8867 35.2418C65.9579 35.1244 66.0537 35.0236 66.1675 34.9464C66.2813 34.8691 66.4105 34.8173 66.5463 34.7943C66.682 34.7713 66.8211 34.7777 66.9542 34.8131C67.0872 34.8485 67.211 34.9121 67.3172 34.9995L71.0479 38.0715C71.1541 38.1589 71.2401 38.268 71.3001 38.3916C71.3601 38.5151 71.3927 38.6501 71.3955 38.7873V38.7873Z" fill="#E4E4E4" />
            <path d="M33.0043 57.6407C32.9845 57.33 33.0332 57.0187 33.1471 56.7288C33.261 56.4389 33.4373 56.1774 33.6636 55.9627C33.8899 55.7481 34.1606 55.5855 34.4568 55.4864C34.753 55.3873 35.0673 55.3541 35.3778 55.3891L50.157 42.7668L52.5407 46.7426L37.2636 57.8998C37.17 58.4156 36.8897 58.8792 36.4759 59.2028C36.0621 59.5265 35.5435 59.6876 35.0185 59.6556C34.4935 59.6237 33.9985 59.4009 33.6273 59.0296C33.256 58.6582 33.0344 58.1641 33.0043 57.6407V57.6407Z" fill="#9E616A" />
            <path d="M47.179 43.9381C47.1967 43.802 47.2436 43.6713 47.3166 43.555C47.3896 43.4386 47.4869 43.3393 47.6019 43.2639L51.6185 40.6281C52.1436 40.16 52.8335 39.9185 53.5369 39.9566C54.2404 39.9947 54.9 40.3091 55.3711 40.8311C55.8423 41.3531 56.0865 42.0399 56.0503 42.741C56.0141 43.4422 55.7004 44.1004 55.1779 44.5714L52.0916 48.2672C52.0035 48.3727 51.8936 48.458 51.7695 48.5173C51.6453 48.5766 51.5098 48.6086 51.3721 48.611C51.2344 48.6135 51.0979 48.5863 50.9717 48.5314C50.8455 48.4765 50.7326 48.3951 50.6409 48.2928L47.4149 44.6978C47.323 44.5955 47.2544 44.4747 47.2137 44.3436C47.173 44.2125 47.1611 44.0742 47.179 43.9381Z" fill="#E4E4E4" />
            <path d="M59.4386 36.931C62.137 36.931 64.3245 34.751 64.3245 32.0619C64.3245 29.3727 62.137 27.1927 59.4386 27.1927C56.7402 27.1927 54.5527 29.3727 54.5527 32.0619C54.5527 34.751 56.7402 36.931 59.4386 36.931Z" fill="#9E616A" />
            <path d="M64.6729 30.4606C62.9923 30.3298 61.597 28.7033 61.7717 27.0787C61.7914 27.7483 61.5564 28.4016 61.1113 28.9143C60.6662 29.427 60.0422 29.7632 59.3578 29.8592C58.5869 29.9414 57.7418 29.7155 57.0709 30.0923C56.3277 30.5096 56.0899 31.523 55.3156 31.8834C54.5676 32.2317 53.6175 31.7567 53.2479 31.0368C52.8783 30.3168 52.969 29.4519 53.214 28.6836C53.5632 27.588 54.1993 26.5986 55.0601 25.8117C55.921 25.0247 56.9775 24.4671 58.1267 24.1929C59.2759 23.9187 60.4789 23.9373 61.6185 24.247C62.7581 24.5566 63.7955 25.1467 64.63 25.9599C65.3366 26.6485 65.8977 27.5262 65.9887 28.4932C66.0797 29.4603 65.6167 30.5095 64.7203 30.944L64.6729 30.4606Z" fill="#2F2E41" />
        </svg>

    )
}

export default Banner