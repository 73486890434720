import styled, { css } from 'styled-components'

import { themeHelpers } from '@gotamedia/fluffy/theme'

import { Link as LinkComponent } from '@remix-run/react'

import FluffyCard from '@gotamedia/fluffy/Card'

const Link = styled(LinkComponent)`
    width: calc(50% - 10px);

    ${themeHelpers.isSmallDevice(css`
        width: unset;
    `)}
`

const Card = styled(FluffyCard)`
    width: 100%;
    min-height: 165px;
    border-radius: 0;

    ${themeHelpers.isSmallDevice(css`
        margin-bottom: 16px;
    `)}

    &:hover {
        cursor: pointer;
        background: #f2f2f2;
    }
`

const CardImage = styled(Card.Image)`

`

const CardBody = styled(FluffyCard.Body)`
    
`

const CardSubHeadline = styled(FluffyCard.SubHeadline)`
    font-family: "Sanomat Sans Text";
`

const CardHeadline = styled(FluffyCard.Headline)`
    
`

const Wrapper = styled.div`
    display: flex;
`

const HeaderWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-right: 10px;
`

const Content = styled.div`
    display: flex;
`

const ImageWrapper = styled.div`
    display: flex;
    margin: 0 0 auto auto;
`

export {
    Link,
    Card,
    CardImage,
    CardBody,
    CardSubHeadline,
    CardHeadline,
    Wrapper,
    HeaderWrapper,
    Content,
    ImageWrapper
}