import {
    useState,
    useCallback
} from 'react'

import * as Styled from './style'
import type * as Types from './types'

const AdBlockerInfo: Types.AdBlockerInfoComponent = () => {
    const [expanded, setExpanded] = useState(false)

    const handleOnClick = useCallback(() => {
        setExpanded(true)
    }, [])

    return (
        <Styled.Wrapper> 
            <Styled.Text>
                {
                    expanded ? (
                        'Om du inte ser några annonser kan det vara för att du använder en annonsblockerare. Pröva att stänga av den och ladda om sidan.'
                    ) : (
                        'Visas inga annonser?'
                    )
                }
            </Styled.Text>

            {
                !expanded ? (
                    <Styled.MoreInfo onClick={handleOnClick}>
                        {'Mer information'}

                        <Styled.Icon />
                    </Styled.MoreInfo>
                ) : (
                    null
                )
            }
        </Styled.Wrapper>
    )
}

export default AdBlockerInfo