[
    {
        "id": 1,
        "name": "Bil Motor",
        "children": [
            {
                "id": 18,
                "name": "Bensinstationer Bensinbolag"
            },
            {
                "id": 19,
                "name": "Bilar"
            },
            {
                "id": 29,
                "name": "Bilprovning"
            },
            {
                "id": 28,
                "name": "Bilstereo"
            },
            {
                "id": 20,
                "name": "Däck"
            },
            {
                "id": 21,
                "name": "Husvagnar Husbilar Släp"
            },
            {
                "id": 22,
                "name": "MC Moped"
            },
            {
                "id": 23,
                "name": "Plåt Lack"
            },
            {
                "id": 24,
                "name": "Reservdelar Tillbehör"
            },
            {
                "id": 25,
                "name": "Tunga fordon"
            },
            {
                "id": 26,
                "name": "Uthyrning"
            },
            {
                "id": 27,
                "name": "Verkstad Service Bärgning"
            }
        ]
    },
    {
        "id": 2,
        "name": "Bostäder Lokaler",
        "children": [
            {
                "id": 55,
                "name": "Arkitekter"
            },
            {
                "id": 49,
                "name": "Bostadsföretag"
            },
            {
                "id": 54,
                "name": "Elleverantörer"
            },
            {
                "id": 50,
                "name": "Fastighetsbolag"
            },
            {
                "id": 51,
                "name": "Förvaltning"
            },
            {
                "id": 53,
                "name": "Lager Magasin"
            },
            {
                "id": 52,
                "name": "Mäklare Värdering"
            }
        ]
    },
    {
        "id": 3,
        "name": "Bygg Renovering",
        "children": [
            {
                "id": 30,
                "name": "Anläggningsmaskiner"
            },
            {
                "id": 36,
                "name": "Badrum"
            },
            {
                "id": 31,
                "name": "Byggnation"
            },
            {
                "id": 32,
                "name": "Byggvaror",
                "children": [
                    {
                        "id": 35,
                        "name": "Färg Tapet"
                    },
                    {
                        "id": 34,
                        "name": "Kakel"
                    },
                    {
                        "id": 33,
                        "name": "Trä Sten"
                    },
                    {
                        "id": 214,
                        "name": "Verktyg"
                    }
                ]
            },
            {
                "id": 38,
                "name": "Byggvaror Tillverkning"
            },
            {
                "id": 39,
                "name": "Elinstallationer Larm Hiss Rulltrappa"
            },
            {
                "id": 40,
                "name": "Exploatering"
            },
            {
                "id": 41,
                "name": "Glas"
            },
            {
                "id": 42,
                "name": "Hustillverkare"
            },
            {
                "id": 37,
                "name": "Kök"
            },
            {
                "id": 43,
                "name": "Måleri"
            },
            {
                "id": 44,
                "name": "Rivning Markarbeten"
            },
            {
                "id": 45,
                "name": "Snickeri Golv Vägg"
            },
            {
                "id": 46,
                "name": "Sotning"
            },
            {
                "id": 47,
                "name": "Tak Fasad"
            },
            {
                "id": 48,
                "name": "Vatten Värme Ventilation VVS"
            }
        ]
    },
    {
        "id": 4,
        "name": "Båtar",
        "children": [
            {
                "id": 57,
                "name": "Båtar Tillbehör"
            },
            {
                "id": 60,
                "name": "Båtklubbar"
            },
            {
                "id": 59,
                "name": "Båtuthyrning"
            },
            {
                "id": 56,
                "name": "Båtvarv Service"
            },
            {
                "id": 58,
                "name": "Marinor Hamnar"
            }
        ]
    },
    {
        "id": 5,
        "name": "Ekonomi Juridik",
        "children": [
            {
                "id": 61,
                "name": "Advokat- & Juridiska byråer"
            },
            {
                "id": 62,
                "name": "Bank Finans"
            },
            {
                "id": 63,
                "name": "Domstolar"
            },
            {
                "id": 64,
                "name": "Försäkring"
            },
            {
                "id": 65,
                "name": "Kreditupplysning Inkasso"
            },
            {
                "id": 66,
                "name": "Leasing"
            },
            {
                "id": 67,
                "name": "Redovisning Revision"
            },
            {
                "id": 68,
                "name": "Skatter Tull- & Indrivning"
            },
            {
                "id": 69,
                "name": "Tillstånd- & Inspektion"
            }
        ]
    },
    {
        "id": 6,
        "name": "Hem Inredning",
        "children": [
            {
                "id": 70,
                "name": "Belysning"
            },
            {
                "id": 71,
                "name": "Färg Tapet Golv"
            },
            {
                "id": 72,
                "name": "Gardiner Textilier"
            },
            {
                "id": 73,
                "name": "Glas Porslin"
            },
            {
                "id": 74,
                "name": "Hushållsapparater Vitvaror Service"
            },
            {
                "id": 78,
                "name": "Inredningsarkitekter Konsulter"
            },
            {
                "id": 76,
                "name": "Möbler Mattor"
            },
            {
                "id": 79,
                "name": "Persienner Markiser"
            }
        ]
    },
    {
        "id": 7,
        "name": "Hotell Konferens Turism",
        "children": [
            {
                "id": 80,
                "name": "Buss Charter Sightseeing"
            },
            {
                "id": 81,
                "name": "Campingplatser Stugbyar"
            },
            {
                "id": 82,
                "name": "Hotell"
            },
            {
                "id": 83,
                "name": "Konferensanläggningar"
            },
            {
                "id": 84,
                "name": "Resebyråer Arrangemang"
            },
            {
                "id": 86,
                "name": "Turistinformation Turistinfo"
            },
            {
                "id": 85,
                "name": "Vandrarhem"
            }
        ]
    },
    {
        "id": 8,
        "name": "Industri- & Företagstjänster",
        "children": [
            {
                "id": 216,
                "name": "Affärsutveckling Näringsliv"
            },
            {
                "id": 87,
                "name": "Call-center"
            },
            {
                "id": 106,
                "name": "Catering Centralkök"
            },
            {
                "id": 89,
                "name": "Energi Vatten Gas Miljö"
            },
            {
                "id": 109,
                "name": "Kontorsmaterial"
            },
            {
                "id": 105,
                "name": "Lokalvård Sanering"
            },
            {
                "id": 107,
                "name": "Parkerings- & Bevakningstjänster"
            },
            {
                "id": 104,
                "name": "Teknisk Konsult Provning"
            },
            {
                "id": 90,
                "name": "Tillverkning Grossister",
                "children": [
                    {
                        "id": 98,
                        "name": "El-, Hushåll & Optikprodukter"
                    },
                    {
                        "id": 99,
                        "name": "Fordon"
                    },
                    {
                        "id": 91,
                        "name": "Framställning Beredning"
                    },
                    {
                        "id": 95,
                        "name": "Kemikalier Gummi Plast"
                    },
                    {
                        "id": 92,
                        "name": "Kläder Textil Läder"
                    },
                    {
                        "id": 96,
                        "name": "Maskiner Delar"
                    },
                    {
                        "id": 94,
                        "name": "Massa Papper"
                    },
                    {
                        "id": 97,
                        "name": "Metallvaror"
                    },
                    {
                        "id": 101,
                        "name": "Mineraliska produkter"
                    },
                    {
                        "id": 93,
                        "name": "Trävaror"
                    },
                    {
                        "id": 100,
                        "name": "Övrigt"
                    }
                ]
            },
            {
                "id": 88,
                "name": "Transport"
            },
            {
                "id": 108,
                "name": "Övriga Företagstjänster"
            }
        ]
    },
    {
        "id": 10,
        "name": "IT Tele",
        "children": [
            {
                "id": 123,
                "name": "Databastjänster"
            },
            {
                "id": 122,
                "name": "Databehandling Drift"
            },
            {
                "id": 125,
                "name": "Datorer Telefoner"
            },
            {
                "id": 126,
                "name": "Internet- & Telekomtjänster"
            },
            {
                "id": 124,
                "name": "Konsulter Programvara"
            },
            {
                "id": 127,
                "name": "Övrig IT-verksamhet"
            }
        ]
    },
    {
        "id": 11,
        "name": "Jobb Utbildning",
        "children": [
            {
                "id": 128,
                "name": "Arbetsförmedling Rekrytering"
            },
            {
                "id": 138,
                "name": "Fackförbund"
            },
            {
                "id": 129,
                "name": "Grund- & Förskola"
            },
            {
                "id": 130,
                "name": "Gymnasier"
            },
            {
                "id": 135,
                "name": "Komvux"
            },
            {
                "id": 134,
                "name": "Personalutbildning"
            },
            {
                "id": 140,
                "name": "Språkutbildning"
            },
            {
                "id": 131,
                "name": "Studieförbund Folkhögskolor"
            },
            {
                "id": 132,
                "name": "Trafik-, Båt-, Flygutbildningar"
            },
            {
                "id": 133,
                "name": "Universitet Högskolor Forskning"
            },
            {
                "id": 136,
                "name": "Övrig utbildning"
            }
        ]
    },
    {
        "id": 12,
        "name": "Kropp Hälsa Friskvård",
        "children": [
            {
                "id": 141,
                "name": "Apotek"
            },
            {
                "id": 143,
                "name": "Hår- & Hudvård"
            },
            {
                "id": 142,
                "name": "Hälsokost"
            },
            {
                "id": 144,
                "name": "Solarier Spa Gym Friskvård"
            }
        ]
    },
    {
        "id": 9,
        "name": "Reklam Media",
        "children": [
            {
                "id": 110,
                "name": "Annonsförsäljning"
            },
            {
                "id": 111,
                "name": "Direktreklam"
            },
            {
                "id": 112,
                "name": "Förlag Distribution"
            },
            {
                "id": 103,
                "name": "Grafisk formgivning"
            },
            {
                "id": 113,
                "name": "Marknads- & Reklambyråer"
            },
            {
                "id": 114,
                "name": "Marknadsundersökningar"
            },
            {
                "id": 115,
                "name": "Medieservice Journalistik Foto"
            },
            {
                "id": 116,
                "name": "Radio TV"
            },
            {
                "id": 117,
                "name": "Tidningar"
            },
            {
                "id": 118,
                "name": "Tryckerier"
            },
            {
                "id": 119,
                "name": "Utomhusreklam Monterbyggare"
            },
            {
                "id": 121,
                "name": "Webbyråer"
            },
            {
                "id": 120,
                "name": "Övrig Grafisk verksamhet"
            }
        ]
    },
    {
        "id": 14,
        "name": "Restauranger Nöjen Kultur",
        "children": [
            {
                "id": 177,
                "name": "Catering"
            },
            {
                "id": 181,
                "name": "Film Bio Filmuthyrning"
            },
            {
                "id": 178,
                "name": "Konst Hantverk Museer"
            },
            {
                "id": 179,
                "name": "Musik Teater"
            },
            {
                "id": 180,
                "name": "Restaurang Café Bar Pub"
            },
            {
                "id": 182,
                "name": "Övrigt - Djurparker Nöje Dans"
            }
        ]
    },
    {
        "id": 13,
        "name": "Samhälle Organisationer",
        "children": [
            {
                "id": 145,
                "name": "Ambulanstransport"
            },
            {
                "id": 146,
                "name": "Avfall Återvinning"
            },
            {
                "id": 148,
                "name": "Begravning"
            },
            {
                "id": 147,
                "name": "Bibliotek Arkiv Forskning"
            },
            {
                "id": 165,
                "name": "Brand- och Räddningstjänster"
            },
            {
                "id": 137,
                "name": "Bransch- & Arbetsgivarorganisationer"
            },
            {
                "id": 150,
                "name": "Försvar"
            },
            {
                "id": 173,
                "name": "Försäkringskassa A-kassa"
            },
            {
                "id": 149,
                "name": "Kommuner Landsting Myndigheter"
            },
            {
                "id": 151,
                "name": "Organisationer Kyrkor Samfund",
                "children": [
                    {
                        "id": 154,
                        "name": "Humanitära föreningar"
                    },
                    {
                        "id": 152,
                        "name": "Politiska organisationer"
                    },
                    {
                        "id": 153,
                        "name": "Religiösa samfund"
                    },
                    {
                        "id": 155,
                        "name": "Övriga intresseorganisationer"
                    }
                ]
            },
            {
                "id": 163,
                "name": "Polis Kriminalvård"
            },
            {
                "id": 164,
                "name": "Post Bud"
            },
            {
                "id": 156,
                "name": "Resor Transport",
                "children": [
                    {
                        "id": 157,
                        "name": "Flyg, reguljärt"
                    },
                    {
                        "id": 158,
                        "name": "Färjor"
                    },
                    {
                        "id": 159,
                        "name": "Kollektivtrafik"
                    },
                    {
                        "id": 160,
                        "name": "Linjebuss"
                    },
                    {
                        "id": 161,
                        "name": "Taxi"
                    },
                    {
                        "id": 162,
                        "name": "Tåg"
                    }
                ]
            },
            {
                "id": 174,
                "name": "Skatt Tull Kronofogde"
            },
            {
                "id": 172,
                "name": "Social omsorg",
                "children": [
                    {
                        "id": 215,
                        "name": "Daghem Fritidsgårdar Fritidshem"
                    }
                ]
            },
            {
                "id": 175,
                "name": "Tillstånd Kontroll"
            },
            {
                "id": 176,
                "name": "Utländska ambassader etc"
            },
            {
                "id": 166,
                "name": "Vård Omsorg",
                "children": [
                    {
                        "id": 168,
                        "name": "Sjukhus Lasarett Vårdhem"
                    },
                    {
                        "id": 167,
                        "name": "Tandvård"
                    },
                    {
                        "id": 170,
                        "name": "Äldreomsorg"
                    },
                    {
                        "id": 169,
                        "name": "Öppen vård"
                    },
                    {
                        "id": 171,
                        "name": "Övrig vård"
                    }
                ]
            },
            {
                "id": 139,
                "name": "Yrkesorganisationer"
            }
        ]
    },
    {
        "id": 15,
        "name": "Shopping Butiker Service",
        "children": [
            {
                "id": 183,
                "name": "Auktioner Begagnat"
            },
            {
                "id": 184,
                "name": "Barnartiklar Leksaker"
            },
            {
                "id": 185,
                "name": "Böcker Tidningar Papper"
            },
            {
                "id": 187,
                "name": "Hygien Kosmetika"
            },
            {
                "id": 189,
                "name": "Jourbutiker Kiosker"
            },
            {
                "id": 75,
                "name": "Kemtvätt Tvätterier"
            },
            {
                "id": 188,
                "name": "Kläder Skor Mode Tyg"
            },
            {
                "id": 190,
                "name": "Köpcentra Gallerior Varuhus"
            },
            {
                "id": 191,
                "name": "Livsmedel Drycker"
            },
            {
                "id": 192,
                "name": "Radio TV Tele Datorer Foto"
            },
            {
                "id": 77,
                "name": "Reparation - Skomakeri"
            },
            {
                "id": 193,
                "name": "Sport Fritid"
            },
            {
                "id": 186,
                "name": "Ur Guld Optik"
            },
            {
                "id": 194,
                "name": "Övriga Butiker"
            }
        ]
    },
    {
        "id": 16,
        "name": "Sport- & Fritidsaktiviteter",
        "children": [
            {
                "id": 204,
                "name": "Aktiviteter Äventyr"
            },
            {
                "id": 206,
                "name": "Bowling"
            },
            {
                "id": 198,
                "name": "Föreningar Klubbar"
            },
            {
                "id": 196,
                "name": "Golf"
            },
            {
                "id": 195,
                "name": "Husdjur"
            },
            {
                "id": 197,
                "name": "Hästar"
            },
            {
                "id": 199,
                "name": "Motorsport Banor"
            },
            {
                "id": 200,
                "name": "Musik Musikinstrument Noter"
            },
            {
                "id": 202,
                "name": "Spel Trav Galopp"
            },
            {
                "id": 201,
                "name": "Sportanläggningar Arrangemang"
            },
            {
                "id": 205,
                "name": "Uthyrning- &Vattensporter"
            },
            {
                "id": 203,
                "name": "Veterinär"
            },
            {
                "id": 207,
                "name": "Övrigt - Hobby Tjänster"
            }
        ]
    },
    {
        "id": 17,
        "name": "Trädgård Lantbruk Fiske",
        "children": [
            {
                "id": 213,
                "name": "Blomsterhandel Plantskolor Trädgård"
            },
            {
                "id": 102,
                "name": "Fiske"
            },
            {
                "id": 208,
                "name": "Jordbruk",
                "children": [
                    {
                        "id": 210,
                        "name": "Mjölk Kött Ägg Djur"
                    },
                    {
                        "id": 209,
                        "name": "Växtodling"
                    }
                ]
            },
            {
                "id": 211,
                "name": "Odlingar Köksväxter Frukt Bär"
            },
            {
                "id": 212,
                "name": "Skogsbruk"
            }
        ]
    }
]