import { IconVariants, Icons } from "@gotamedia/fluffy/Icon"
import { ImageLoadingEffects } from "@gotamedia/fluffy/components/Image/components/LazyImage/types"
import cardsConfig from '~/config/adcards.json'
import type { AdCard, AdCardItem } from "~/config/types"
import useScope from "~/hooks/useScope"
import * as Styled from "./style"
import type * as Types from "./types"

const Cards: Types.CardsComponent = () => {
    const scope = useScope()
    const cards: AdCardItem = cardsConfig[scope.id as keyof AdCard]
  
    return (
        <Styled.CardsSection>
            <Styled.CardsWrapper>
                <Styled.AdsCard>
                    <Styled.TextWrapper>
                        <Styled.CardHeadline>{cards.celebrate.title}</Styled.CardHeadline>
                        <Styled.CardText>{cards.celebrate.text}</Styled.CardText>
                    </Styled.TextWrapper>
                    <Styled.CardButton href={cards.celebrate.buttonLink}>{cards.celebrate.buttonText}</Styled.CardButton>
                    <Styled.LinkIconWrapper>
                        <Styled.CardLink href={cards.celebrate.link}>{cards.celebrate.linkText}</Styled.CardLink>
                        <Styled.CardIcon 
                            useNativeLazyLoading 
                            loadingEffect={ImageLoadingEffects.Blur} 
                            src={"icons/cake.svg"}
                            withPlaceholder
                            alt={"Cake illustration"} />
                    </Styled.LinkIconWrapper>
                </Styled.AdsCard>
                <Styled.AdsCard>
                    <Styled.TextWrapper>
                        <Styled.CardHeadline>{cards.market.title}</Styled.CardHeadline>
                        <Styled.CardText>{cards.market.text}</Styled.CardText>
                    </Styled.TextWrapper>
                    <Styled.CardButton href={cards.market.buttonLink}>{cards.market.buttonText}</Styled.CardButton>
                    <Styled.LinkIconWrapper>
                        <Styled.CardIcon useNativeLazyLoading
                            loadingEffect={ImageLoadingEffects.Blur}
                            src={"icons/bags.svg"}
                            withPlaceholder
                            alt={"Bags illustration"} />
                    </Styled.LinkIconWrapper>
                </Styled.AdsCard>
                <Styled.AdsCard>
                    <Styled.TextWrapper>
                        <Styled.CardHeadline>{cards.memorial.title}</Styled.CardHeadline>
                        <Styled.CardText>{cards.memorial.text}</Styled.CardText>
                    </Styled.TextWrapper>
                    <Styled.ButtonWrapper>
                        <Styled.CardButton target="_blank" href={cards.memorial.buttonFormLink}>
                            {cards.memorial.buttonFormText}
                        </Styled.CardButton>
                    </Styled.ButtonWrapper>
                    <Styled.LinkIconWrapper>
                        <Styled.CardLink href={cards.memorial.link}>{cards.memorial.linkText}</Styled.CardLink>
                        <Styled.CardIcon 
                            useNativeLazyLoading
                            loadingEffect={ImageLoadingEffects.Blur}
                            src={"icons/candle.svg"}
                            withPlaceholder
                            alt={"Candle illustration"} />
                    </Styled.LinkIconWrapper>
                </Styled.AdsCard>
            </Styled.CardsWrapper>
        </Styled.CardsSection>
    )
}

export default Cards