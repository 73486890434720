import slugify from 'slugify'

import * as dateUtils from '~/utils/date'

import Card from '~/components/Card'

import * as Styled from './style'
import type * as Types from './types'

const JobsSearchCards: Types.JobsSearchCardsComponent = props => {
    const {
        loading,
        items
    } = props

    return (
        <>
            {
                Array.isArray(items) && items?.map((item, idx) => {
                    const companyName = slugify(item.companyName, {
                        locale: 'sv'
                    })

                    const places = [
                        item.remoteAllowed ? 'Distans' : null,
                        ...item.placements
                    ].filter(i => i)

                    return (
                        <Card
                            key={`${item.uuid}-${idx}`}
                            path={`/${companyName}/${item.uuid}`}
                            image={item.logoUrl}
                            headline={item.title}
                            subHeadline={`Publicerat ${dateUtils.format(item.created)}`}
                            loading={loading}
                        >
                            <Styled.DescriptionWrapper>
                                <Styled.DescriptionName>
                                    {`Omfattning:`}
                                </Styled.DescriptionName>

                                <Styled.DescriptionValue>
                                    {item.employmentType || '-'}
                                </Styled.DescriptionValue>

                                <Styled.DescriptionName>
                                    {`Plats:`}
                                </Styled.DescriptionName>

                                <Styled.DescriptionValue>
                                    {places.join(', ') || '-'}
                                </Styled.DescriptionValue>
                            </Styled.DescriptionWrapper>
                        </Card>
                    )
                })
            }
        </>
    )
}

export default JobsSearchCards