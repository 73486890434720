import { themeHelpers } from '@gotamedia/fluffy'
import styled, { css } from 'styled-components'

import BannerComponent from '~/components/Banner'

const Wrapper = styled.div`
    max-width: 1320px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;

    padding: 80px 0;

    ${themeHelpers.isSmallDevice(css`
        width: 95%;
        padding: 16px 0;
    `)}
`

const Content = styled.div`
    display: flex;
`

const InnerWrapper = styled.div`
    width: 546px;
    min-width: 546px;
    margin-right: 80px;

    ${themeHelpers.isMediumDevice(css`
        width: 480px;
        min-width: 480px;
        margin-right: 80px;
    `)}

    ${themeHelpers.isSmallDevice(css`
        width: 224px;
        min-width: 224px;
        margin-right: 8px;
    `)}
`

const Headline = styled.h1`
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    color: #000000;
    margin: 0 0 16px 0;

    ${themeHelpers.isSmallDevice(css`
        font-size: 24px;
    `)}
`

const Description = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    line-height: 24px;

    ${themeHelpers.isSmallDevice(css`
        font-size: 16px;
    `)}
`

const Banner = styled(BannerComponent)`
    margin: auto auto auto 0;

    ${themeHelpers.isNotLargeDevice(css`
        margin: auto 0 auto auto;
    `)}
`

export {
    Wrapper,
    Content,
    InnerWrapper,
    Headline,
    Description,
    Banner
}