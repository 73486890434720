
import faqConfig from '~/config/faq.json'
import type { FAQ } from "~/config/types"
import useMeta from '~/hooks/useMeta'
import CollapsibleFaq from "./collapsiblefaq"
import * as Styled from "./style"
import type * as Types from "./types"

const Faq: Types.FaqComponent = () => {
    const faq: FAQ = faqConfig

    const {
        advertising, 
        payment, 
        images, 
        other,
        helpAndSupport
    } = faq

    const meta = useMeta()

    return (
        <Styled.FaqSection>
            <Styled.FaqInnerSection>
                <Styled.FaqContent>
                    <>
                        <Styled.MainTitle>
                            {faq.mainTitle}
                        </Styled.MainTitle>
                        <Styled.SubTitle>
                            {advertising.subTitle}
                        </Styled.SubTitle>
                        <Styled.FaqQuestionWrapper>
                            {advertising.questions.map((question) => {
                                return <CollapsibleFaq 
                                    key={question.questionHeading} 
                                    questionHeading={question.questionHeading} 
                                    sections={question.sections} 
                                />
                            })}
                        </Styled.FaqQuestionWrapper>

                        <Styled.SubTitle>
                            {payment.subTitle}
                        </Styled.SubTitle>
                        <Styled.FaqQuestionWrapper>
                            {payment.questions.map((question) => {
                                return <CollapsibleFaq 
                                    key={question.questionHeading}
                                    questionHeading={question.questionHeading}
                                    sections={question.sections}
                                />
                            })}
                        </Styled.FaqQuestionWrapper>

                        <Styled.SubTitle>
                            {images.subTitle}
                        </Styled.SubTitle>
                        <Styled.FaqQuestionWrapper>
                            {images.questions.map((question) => {
                                return <CollapsibleFaq 
                                    key={question.questionHeading}
                                    questionHeading={question.questionHeading}
                                    sections={question.sections} 
                                />
                            })}
                        </Styled.FaqQuestionWrapper>

                        <Styled.SubTitle>
                            {other.subTitle}
                        </Styled.SubTitle>
                        <Styled.FaqQuestionWrapper>
                            {other.questions.map((question) => {
                                return <CollapsibleFaq 
                                    key={question.questionHeading} 
                                    questionHeading={question.questionHeading} 
                                    sections={question.sections} />
                            })}
                        </Styled.FaqQuestionWrapper>
                    </>
                    <Styled.MainTitle>{faq.secondMainTitle}</Styled.MainTitle>
                    <Styled.TextMedium>{helpAndSupport.text}</Styled.TextMedium>
                    <Styled.ButtonWrapperHelpSupport>
                        <Styled.ContactButton 
                            target={"_blank"} 
                            href={meta.helpAndSupport?.contactUsLink}>
                            {helpAndSupport.buttonFormText}
                        </Styled.ContactButton>
                        <Styled.ContactButton 
                            href={helpAndSupport.buttonPhoneLink}>
                            <Styled.ContactButtonIcon src="icons/phone.svg"/>
                            {helpAndSupport.buttonPhoneText}
                        </Styled.ContactButton>
                    </Styled.ButtonWrapperHelpSupport>
                </Styled.FaqContent>
            </Styled.FaqInnerSection>
        </Styled.FaqSection>
    )
}

export default Faq
