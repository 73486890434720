import styled from 'styled-components'

import FluffyCard from '@gotamedia/fluffy/Card'

const Card = styled(FluffyCard)`
    border-radius: 0px;
    &:hover {
        cursor: pointer;
        background: #f2f2f2;
    }
`

const ImageWrapper = styled.div<{ $overflow?: boolean }>`
    padding: 12px;
    position: relative;
`

const CardImage = styled(Card.Image)<{ $overflow?: boolean }>`
    width: 100%;
    height: 100%;
    aspect-ratio: 2/3;
    position: relative;
`

export {
    Card, CardImage, ImageWrapper
}
