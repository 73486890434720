import styled from 'styled-components'

import { Form as FormComponent } from '@remix-run/react'

const Wrapper = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Form = styled(FormComponent)`
    flex: 1;
    display: flex;
`


export {
    Wrapper,
    Form
}