import Icon from "@gotamedia/fluffy/Icon"
import themeHelpers from "@gotamedia/fluffy/utils/theme/helpers"
import styled, { css } from "styled-components"


const FaqSection = styled.div`
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 40px;
    ${themeHelpers.isNotLargeDevice(css`
        padding-bottom: 16px;
    `)}
`

const FaqInnerSection = styled.div`
    display: flex;
    width: 1600px;
    padding: 64px 160px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0px 2px 2px 0px rgba(46, 42, 37, 0.12);
    ${themeHelpers.isMediumDevice(css`
        padding: 16px;
    `)}
    ${themeHelpers.isSmallDevice(css`
        padding: 8px;
    `)}
`

const FaqContent = styled.div`
    max-width: 700px;
    ${themeHelpers.isMediumDevice(css`
        padding-bottom: 24px;
    `)}
    ${themeHelpers.isSmallDevice(css`
        max-width: 375px;
        padding: 8px;
        @media screen and (orientation: landscape) {
            max-width: 500px;
        }
    `)}
`

const FaqContainer = styled.div`
    display: flex;
    width: 700px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
`

const MainTitle = styled.h2`
    color: #000;
    font-family: Sanomat Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 32px;
    margin-top: 24px;
`
const SubTitle = styled.h3`
    color: #000;
    font-family: Sanomat Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`
const TextContent = styled.div`
    gap: 10px;
    display: flex;
    flex-direction: column;
`

const Text = styled.p`
    color: #2E2A25; 
    text-align: left;
    font-family: Sanomat Sans Text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    a {
        text-decoration: underline;
    }
`

const TextMedium = styled.p`
    font-family: Sanomat Sans Text;
    color: #2E2A25;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`
const FaqQuestionWrapper = styled.div`
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 32px; 
`
const FaqIcon = styled(Icon)`
    margin-left: auto;
`

const Link = styled.a`
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
`


const ButtonWrapper = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 20px;
`
const ButtonWrapperHelpSupport = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 20px;
    margin-bottom: 16px;
    ${themeHelpers.isSmallDevice(css`
        flex-direction: column-reverse;
        align-items: flex-start;
    `)}
`

const ContactButton = styled.a`
    display: flex;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    border: 1px solid #55565A;
    background: #FFF;
    color: black;
    &:hover {
        background: rgba(0, 0, 0, 0.08);        
    }
`
const ContactButtonIcon = styled.img`
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
`

export {
    FaqSection,
    FaqInnerSection,
    FaqContainer,
    MainTitle,
    SubTitle,
    TextContent,
    Text,
    TextMedium,
    FaqIcon,
    FaqQuestionWrapper,
    Link,
    FaqContent,
    ButtonWrapper,
    ButtonWrapperHelpSupport,
    ContactButton,
    ContactButtonIcon
}
